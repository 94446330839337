import { useLayoutContext } from '@gravity-ui/uikit'
import ZiferblatContext from 'components/ZiferblatContext'
import { useCheckinsQuery } from 'queries'
import { createElement as $, useContext, useMemo } from 'react'
import Desktop from './Desktop'
import Mobile from './Mobile'
import './style.scss'
import { addHours } from 'date-fns/fp/addHours'

const Checkins = () => {
  const { activeMediaQuery } = useLayoutContext()
  const component = activeMediaQuery === 's' ? Mobile : Desktop
  const { ziferblatId } = useContext(ZiferblatContext)
  const now = useMemo(() => new Date(), []) // FIXME needs to update
  const startsAt = addHours(-2, now)
  const endsAt = addHours(8, now)
  const props = useCheckinsQuery({
    variables: { ziferblatId, startsAt, endsAt },
    pollInterval: 60000
  })
  return $(component, props)
}

export type CheckinsProps = ReturnType<typeof useCheckinsQuery>

export default Checkins