import PersonPlus from '@gravity-ui/icons/PersonPlus'
import MobileList from 'components/MobileList'
import CallToAction from 'components/MobileMenu/CallToAction'
import MobileMenuContainer from 'components/MobileMenuContainer'
import { createElement as $, FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { CheckinsProps } from '..'
import Content from './Content'
import Edit, { addPath } from './Edit'

const Mobile: FC<CheckinsProps> = (props) =>
  $(MobileMenuContainer, {
    fab: $(CheckinButton),
    label: 'header.button.search.checkins'
    },
    !props.loading && props.data && $(Edit, props.data),
    $(MobileList, null, $(Content, props)))

const CheckinButton = () => {
  const navigate = useNavigate()
  return $(CallToAction, {
    data: PersonPlus,
    onClick: () => navigate(`/checkins/${addPath}`)
  })
}

export default Mobile