import { ArrowRightFromSquare } from "@gravity-ui/icons"
import { Avatar, AvatarProps, Button, Icon, User } from "@gravity-ui/uikit"
import { StaffType } from "pages/Settings/StaffListItem"
import { useLogoutMutation, useMeQuery } from "queries"
import { createElement as $, FC, useState } from "react"
import { useNavigate } from "react-router-dom"
import useLocalStorageState from "use-local-storage-state"
// import KuznetskyLogo from '../logos/Kyznecky.svg'
import ZiferList from "components/ZIferIteratee/ZiferIteratee"
import Piter from '../logos/Piter.svg'
import './style.scss'

export const logos: Logos = {
  '23d251f0-707c-48c0-b0ee-2b4978b584e7': Piter,
  // '23d251f0-707c-48c0-b0ee-2b4978b584e7': KuznetskyLogo
}

interface Logos {
  [key: string]: string;
}

const UserContent: FC = () => {
  const { data } = useMeQuery({ fetchPolicy: 'cache-only' })
  console.log(data)
  const avatar = data?.me?.user?.avatarUrl
  const roles = data?.me?.user?.roles || []
  const [ currentRole, setCurrentRole ] = useLocalStorageState<StaffType['roles'][0]>('currentRole')
  const currentRoleId = (currentRole && currentRole.id) || roles[0].id
  const [selectedRole, setSelectedRole] = useState(currentRoleId)
  const [mutate, { loading }] = useLogoutMutation()
  const navigate = useNavigate()
  const handleZiferClick = (id: string) => {
    const matchingZifer = roles?.find((obj) => obj.id === id)
    setSelectedRole(id)
    setCurrentRole(matchingZifer)
  }

  return $('div', { className: 'mobile-navigation_popup' },
    $('div', { className: 'mobile-navigation_popup_user' },
      $('div', { onClick: () => navigate('/profile') }, 
        $(User, { 
          avatar: $<AvatarProps>(Avatar, {
              size: 'm', 
              imgUrl: avatar || '/favicon.svg',
              fallbackImgUrl: '/favicon.svg'
            }),
          name: data?.me?.user?.alias[2].value, 
          description: `@${data?.me?.user?.alias[1].value}`,
        })),
      $('div', { className: 'mobile-navigation_popup_user_logout' },
        $(Button, {
          view: 'flat',
          size: 's',
          loading: loading,
          onClick: () => mutate()
            .then(() => navigate(''))
            .then(() => window.location.reload())
        }, $(Icon, { data: ArrowRightFromSquare })))),
    $('div', { className: 'mobile-navigation_popup_buttons' },
      $(ZiferList, { 
        roles,
        updatedRole: selectedRole,
        handleZiferClick 
      })))
}

export default UserContent