import { Card, Flex, Text, spacing } from '@gravity-ui/uikit'
import { CulturalEventsQuery } from 'queries'
import { createElement as $, FC } from 'react'
import { FormattedDate, useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'

const CulturalEventListItemIteratee = (props: CulturalEventListItemType) =>
  $(CulturalEventListItem, {
    key: props.id,
    ...props
  })

const CulturalEventListItem: FC<CulturalEventListItemType> = ({
  id,
  title,
  startsAt
}) => {
  const navigate = useNavigate()
  const intl = useIntl()

  const date = !startsAt
    ? intl.formatMessage({ id: 'culturalEvent.noDate' })
    : $(FormattedDate, {
        value: startsAt,
        dateStyle: 'full',
        timeStyle: 'short'
        })

  const children = $(Flex, { direction: 'column' },
    $(Text, { variant: 'body-2' }, title || intl.formatMessage({ id: 'culturalEvent.noName' })),
    $(Text, { variant: 'caption-2', color: 'hint' }, date))

  return $(Card, {
    className: spacing({ p: 4 }),
    type: 'action',
    onClick: () => navigate(`/events/${id}`),
    children
    })
}

type CulturalEventListItemType = CulturalEventsQuery['culturalEvents'][number]

export default CulturalEventListItemIteratee