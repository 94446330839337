import { DelayedTextInput } from '@gravity-ui/components'
import { LogoTelegram } from '@gravity-ui/icons'
import { Flex, Link, Loader, Text, spacing } from '@gravity-ui/uikit'
import { SearchAliasQuery, StaffQuery, useSearchAliasQuery } from 'queries'
import { createElement as $, FC, useState } from 'react'
import { useIntl } from 'react-intl'
import { AddStaffRoleSelect } from './NewRoleSelect'
import { getAlias } from './StaffListItem'

const AddStaff: FC<StaffQuery> = () => {
  const intl = useIntl()
  const [value, setValue] = useState('')
  const { data, loading } = useSearchAliasQuery({ variables: { term: `%${value}%` }, fetchPolicy: 'cache-first' })

  return $(Flex, { direction: 'column' },
    $(DelayedTextInput, { 
      size: 'xl',
      value, 
      placeholder: intl.formatMessage({ id: 'alias.search'}).replace(':', ''),
      onUpdate: setValue,
      rightContent: $('div', { style: { width: 40 }},
        loading && $(Loader, { size: 's' }))
      }),
    data && $(Flex, { direction: 'column', gap: 4, className: spacing({ pt: 4 }) }, 
      data.authAlias.map(UserTermIterate)))
}

const UserTermIterate = (props: SearchResult) =>
  $(UserItem, {key: props.id, ...props })

const UserItem: FC<SearchResult> = (alias) => {
  const aliases = alias.user?.alias
  const name = getAlias('name', aliases) ?? ''
  const surname = getAlias('surname', aliases) ?? ''
  const telegramUsername = getAlias('telegram_username', aliases)

  return $(Flex, { 
    key: alias.user?.id,
    as: 'div', 
    direction: 'column', 
    justifyContent: 'space-between', 
    gap: 2,
  },
    $(Flex, { gap: 2, justifyContent: 'space-between' }, 
      $(Flex, { direction: 'column', gap: 1 }, 
        $(Text, { variant: 'subheader-1', className: 'settings_staff-item_name' }, 
          `${name} ${surname}`),
        $(Link, { 
          href: `https://t.me/${telegramUsername}`, 
          target: '__blank', 
          view: 'secondary', 
          style: { 
            display: 'flex',
            alignItems: 'center',
            gap: '4px',
            width: 'max-content' 
          }}, 
          $(LogoTelegram),
          telegramUsername)),
        $(AddStaffRoleSelect, { userId: alias.user?.id })))
}

type SearchResult = SearchAliasQuery['authAlias'][0]

export default AddStaff