import Empty from 'components/Empty'
import loadingElements from 'components/LoadingElements/LoadingElements'
import { SearchContext } from 'components/MobileMenu/SearchContext'
import { StaffQuery, useStaffQuery } from 'queries'
import { createElement as $, FC, useContext } from 'react'
import StaffListItem from './StaffListItem'

const Content: FC<StaffProps> = ({
  data,
  loading
}) => {
  if (!data?.users || data.users.length === 0) return $(Empty)
  if (loading && !data) return loadingElements
  return $(PreparedList, data)
}

const PreparedList: FC<StaffQuery> = ({ users }) => {
  const searchRegex = useContext(SearchContext)
  const preparedStaff = searchRegex
    ? users.filter(person => searchRegex.exec(getName(person)))
    : users
  return $('div', { style: StaffContainerStyles },
    preparedStaff.map(StaffListItem))
}

const getName = (person: StaffQuery['users'][0]) =>
  person.alias
    .filter(alias => (alias.type === 'name') || (alias.type === 'telegram_username'))
    .map(alias => alias.value)
    .toString() || ''

export type StaffProps = ReturnType<typeof useStaffQuery>

const StaffContainerStyles = {
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fill, minmax(16rem, 1fr))',
  gridAutoRows: '120px',
  gap: 8
}

export default Content