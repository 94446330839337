import { LogoTelegram } from "@gravity-ui/icons"
import { Flex, Text, spacing, useLayoutContext } from "@gravity-ui/uikit"
import { MessageIds } from "components/IntlProvider"
import useBack from "hooks/useBack"
import { getAlias } from "pages/Settings/StaffListItem"
import { MeQuery, useLogoutMutation, useMeQuery } from "queries"
import { createElement as $, FC } from 'react'
import { FormattedMessage } from "react-intl"
import Desktop from "./Desktop"
import Mobile from "./Mobile"
import './style.scss'

const Profile = () => {
  const { data } = useMeQuery({ fetchPolicy: 'cache-first' })
  const [logout, logoutOptions] = useLogoutMutation()
  const { activeMediaQuery } = useLayoutContext()
  const loading = logoutOptions.loading || logoutOptions.called
  const aliases = [...data?.me?.user?.alias || []].sort(sortAlias)
  const back = useBack()
  const name = getAlias('name', data?.me?.user?.alias) || ''
  const surname = getAlias('surname', data?.me?.user?.alias) || ''
  const onClick = async () => {
    await logout()
    window.location.reload()
  }
  const props = { data, aliases, loading, back, name, surname, onClick}
  const component = activeMediaQuery === 's' ? Mobile : Desktop
  
  return $(component, props)
}

const sortAlias = (left: Alias, right: Alias) =>
  right.type.localeCompare(left.type)

export const AliasIteratee = (props: Alias) =>
  $(AliasItem, { key: props.id, ...props })

const AliasItem: FC<Alias> = ({
  type,
  value
}) => {
  if (type === 'name' || type === 'surname') return null
  return $(Flex, { className: spacing({ mb: 2 }), gap: 1 },
    getAliasTypeElement(type),
    $(Text, null, value))
}

const getAliasTypeElement = (type: string) => {
  switch(type) {
    case 'telegram_username':
      return $(LogoTelegram)
    case 'telegram_id':
      return $(FormattedMessage, { id: `alias.${type}` as MessageIds})
    default:
      return null;
  }
};

type Alias = NonNullable<NonNullable<MeQuery['me']>['user']>['alias'][number]

export type ProfileProps = {
  data: MeQuery | undefined, 
  aliases: Alias[], 
  loading: boolean, 
  back: (_arg: {
    fallback?: string | undefined;
    }) => void
  name: string, 
  surname: string, 
  onClick: () => void
}

export default Profile