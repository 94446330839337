import { Button, Checkbox, Flex, TextInput } from '@gravity-ui/uikit'
import { CheckinsQuery, useAddAttendanceMutation, useCheckOutMutation, useRemoveAttendanceMutation } from 'queries'
import { createElement as $, FC, ReactElement, useState } from 'react'
import { useIntl } from 'react-intl'
import { Navigate, useParams } from 'react-router-dom'
import NameInput from './NameInput'
import { validate } from './validation'

const ViewCheckin: FC<CheckinsQuery> = (props) => {
  const params = useParams<'id'>()
  const id = params.id!
  const checkin = props.checkins?.find((checkin) => checkin.id === id)
  if (!checkin?.id)
    return $(Navigate, { to: '/checkins' })

  return $(CheckinContent, { checkin, ...props, })
} 

const CheckinContent: FC<CheckinContentProps> = ({
  checkin,
  checkins,
  upcomingCulturalEvents
}) => {
  const [name, setName] = useState(checkin.name || '')
  const validation = validate(checkins, { id: checkin.id, name })
  const [sum, setSum] = useState(checkin.sum || 0)
  const [mutate, { loading }] = useCheckOutMutation({
    variables: {
      id: checkin.id,
      sum: sum
    },
    update: (cache, { data }) =>
      cache.modify<{ checkins: CheckinsQuery['checkins'] }>({
        fields: {
          checkins(existingCheckins, { readField }) {
            const checkOut = data?.checkOut
            if (checkOut)
              return existingCheckins.filter(checkinItem => readField('id', checkinItem) !== checkOut.id)
            else return existingCheckins;
          },
        },
      })
  })
  const intl = useIntl()
  return $(Flex, {
    direction: 'column',
    gap: 4
    },
    $(NameInput, { setName, name, validation }),
    $(TextInput, {
      value: sum.toString(),
      rightContent: $('div', { style: { marginRight: '1rem' }}, '₽'),
      onUpdate(value) {
        const cleanedValue = parseInt(value)
        setSum(isNaN(cleanedValue) ? (checkin.sum || 0) : cleanedValue)
      }
    }),
    $(CulturalEvents, { checkin, upcomingCulturalEvents }),
    // $(Sum, checkin),
    $(Button, {
      loading,
      view: 'action',
      size: 'xl',
      width: 'max',
      onClick: () => mutate()
      },
      intl.formatMessage({ id: 'checkins.checkout' })))
}

const CulturalEvents: FC<Pick<CheckinContentProps, 'checkin' | 'upcomingCulturalEvents'>> = ({
  checkin,
  upcomingCulturalEvents
}) => {
  const result: ReactElement[] = []
  const [add, addMeta] = useAddAttendanceMutation()
  const [remove, removeMeta] = useRemoveAttendanceMutation()
  const culturalEvents = [...upcomingCulturalEvents, ...checkin.attendance.map(({ culturalEvent }) => culturalEvent)]
    .filter(checkIfUnique)
  for (const culturalEvent of culturalEvents) {
    const variables =  {
      checkinId: checkin.id,
      culturalEventId: culturalEvent.id
    }
    const loading = addMeta.loading || removeMeta.loading
    const checked = !!checkin.attendance.find((attendance) => culturalEvent.id === attendance.culturalEvent.id)
    const mutate = checked ? remove : add
    result.push(
      $('div', { key: culturalEvent.id },
        $(Checkbox, {
          size: 'l',
          onChange: () => mutate({ variables }),
          content: culturalEvent.title,
          disabled: loading,
          checked,
        })))
  }
  return result
}

const checkIfUnique = <T extends { id: string }>(value: T, index: number,array: T[])=>
  array.findIndex(next=> next.id === value.id) === index

type Checkin = CheckinsQuery['checkins'][number]

// const Sum: FC<Checkin> = ({ sum }) =>
//   $(Text, { variant: 'body-2'},
//     $(FormattedNumber, { value: sum!, style: 'currency', currency: 'rub' }))

type CheckinContentProps = CheckinsQuery & { checkin: Checkin }

export default ViewCheckin