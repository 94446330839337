import { LayoutProvider, MobileProvider, ThemeProvider } from '@gravity-ui/uikit'
import { createElement as $, FC, PropsWithChildren } from 'react'
import './theme.scss'

const ThemedApp: FC<PropsWithChildren> = ({ children }) =>
  $(ThemeProvider, null,
    $(LayoutProvider, null,
      $(MobileProvider, null,
        children)))

export default ThemedApp