import { MeQuery } from 'queries'

const useSortedRoles = (data: MeQuery, updatedRole: string) => {
	const roles = data.me?.user?.roles || []
	const sortFunction = (a: any, b: any) => {
		if (a?.id === updatedRole) return 1
		if (b?.id === updatedRole) return -1
		return 0
	}
	const newlySortedRoles = [...roles].sort(sortFunction)

	return newlySortedRoles
}

export default useSortedRoles
