import { dateTime } from '@gravity-ui/date-utils'
import { Card, Flex, Text, spacing } from '@gravity-ui/uikit'
import FormattedTimer from 'components/FormattedTimer'
import { differenceInSeconds } from 'date-fns'
import { CheckinFragment } from 'queries'
import { createElement as $, FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { useNavigate } from 'react-router-dom'
// import { CardFilled } from 'remadi/components/Card'
// import StateLayer from 'remadi/components/StateLayer'

const CheckinListItem: FC<CheckinFragment> = (props) => {
  const navigate = useNavigate()
  const name = getProps(props)
  const value = differenceInSeconds(new Date(), props.checkedInAt)
  
  const hint = value < 60
    ? $(FormattedMessage, { id: 'checkins.now' })
    : $(FormattedTimer, { from: dateTime({input: props.checkedInAt}) })

  const children = $(Flex, { direction: 'column' },
    $(Text, { 
      variant: 'body-2', 
      whiteSpace: 'break-spaces', 
      wordBreak: 'break-all' 
      }, name),
    $(Text, { variant: 'caption-2', color: 'hint' }, hint))

  return $(Card, {
    className: spacing({ p: 4 }),
    onClick: () => navigate(`/checkins/${props.id}`),
    view: 'filled',
    type: 'action',
    children
  })
}

const getProps = (props: CheckinFragment): string =>
  props.user?.id || props.name || ''

// const Media = styled.div({
//   borderRadius: '1.5rem',
//   height: '8rem',
//   display: 'flex',
//   alignItems: 'center',
//   justifyContent: 'center'
// }, ({ theme }) => ({
//   '& > svg': {
//     fill: hexFromArgb(theme.palette.main.onSurfaceVariant)
//   },
//   backgroundColor: hexFromArgb(theme.palette.main.surfaceVariant)
// }))

// const Content = styled.div({
//   padding: '1rem 2rem 2rem 2rem'
// })


// const Label = styled.div(({ theme }) => theme.typography.label.small)

const CheckinListItemIteratee = (props: CheckinFragment) =>
  $(CheckinListItem, { key: props.id, ...props })

export default CheckinListItemIteratee