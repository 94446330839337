import { Skeleton } from '@gravity-ui/uikit'
import { createElement as $ } from 'react'
import './style.scss'

const SkeletonCard = (value: number, index: number) =>
  $(Skeleton, {
    key: index,
    style: {
      borderRadius: 'var(--g-border-radius-m)',
      height: 'calc((var(--g-spacing-4) * 2) + var(--g-text-body-2-line-height) + var(--g-text-caption-2-line-height))'
      }})

const loadingElements = 
  $('div', { className: 'loading-elements' }, 
    new Array(10).fill(0).map(SkeletonCard))

export default loadingElements