import {
  ApolloClient,
  InMemoryCache,
  InMemoryCacheConfig,
  Reference
} from '@apollo/client'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { createClient } from 'graphql-ws'

export const TOKEN_KEY = 'auth:token'

const parseDate = (value: string | null) => (value ? new Date(value) : null)

const config: InMemoryCacheConfig = {
  typePolicies: {
    Query: {
      fields: {
        checkins: {
          merge(prev, next) {
            return next || prev
          },
        },
        tariff: {
          merge(prev: Reference[], next: Reference[], { readField }) {
            const set = new Set([...prev || [], ...next || []].map(({ __ref }) => __ref))
            const array = [...set].map((__ref) => ({ __ref }))
            const compareFn = (leftRef: Reference, rightRef: Reference) => {
              const leftActiveTo = readField('activeTo', leftRef)
              const rightActiveTo = readField('activeTo', rightRef)
              if (!leftActiveTo) return -1
              if (rightActiveTo) return leftActiveTo < rightActiveTo ? 1 : -1            
              return 1
            }
            return array.sort(compareFn)
          },
        },
      },
    },
    TariffTariff: {
      fields: {
        activeFrom: {
          read: parseDate,
        },
        activeTo: {
          read: parseDate,
        },
      },
    },
    AnonymousCheckin: {
      fields: {
        checkedInAt: {
          read: parseDate,
        },
        culturalEvents: {
          merge: (existing = [], next) => next || existing,
        },
      },
    },
    CulturalEvent: {
      fields: {
        startsAt: {
          read: parseDate,
        },
        endsAt: {
          read: parseDate,
        },
      },
    },
  },
}

const useCreateClient = (url: string) => {
  const wsClient = createClient({
    url,
    connectionParams() {
      const token = localStorage.getItem(TOKEN_KEY)     
      if (token) return { headers: { token } }
    },
    lazy: false,
    retryAttempts: Infinity,
    keepAlive: 60000,
    shouldRetry: () => true,
  })

  const link = new GraphQLWsLink(wsClient)

  const cache = new InMemoryCache(config)
  const client = new ApolloClient({
    link,
    cache,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
      },
    },
  })
  return client
}

export default useCreateClient
