// import useIsDesktop from 'hooks/useIsDesktop'
import { createElement as $, ReactElement } from 'react'
// import { ReactElement } from 'react-imask/dist/mixin'
// import { NavItemLabel } from 'remadi/components/NavItem'
import { Navigate, Route, Routes } from 'react-router-dom'
// import { NavItemLabel } from 'remadi/components/NavItem'

import { useLayoutContext } from '@gravity-ui/uikit'
import useNavItems from 'hooks/useNavItems'
import { MeQuery, useMeQuery } from 'queries'
import Desktop from './Desktop'
import Mobile from './Mobile'
import { pathEntries } from './paths'

const Main = () => {
  const { activeMediaQuery } = useLayoutContext()
  const component = activeMediaQuery === 's' ? Mobile : Desktop
  const { data } = useMeQuery({ fetchPolicy: 'cache-first' })
  const navItems = useNavItems()
  
  return $(component, { routes, navItems, data })
}

const routes = $(Routes, null,
  pathEntries.filter(([path, { disabled }]) => !disabled).map(([path, config]) =>
    $(Route, {
      key: path,
      path: `${path}/*`,
      element: config.element
    })),
  $(Route, { 
    path: '*',
    element: $(Navigate, { to: pathEntries[0][0] })
  }))

export type DeviceProps = {
  routes: ReactElement
  navItems: {
    label: string
    onClick: () => void
    selected: boolean
    icon: ReactElement
    disabled: boolean
    visible?: boolean
  }[]
  data: MeQuery | undefined
}

export default Main
