import { dateTime } from '@gravity-ui/date-utils/build/dateTime'
import PlusIcon from '@gravity-ui/icons/Plus'
import { Button, Card, Flex, Icon, spacing } from '@gravity-ui/uikit'
import Empty from 'components/Empty'
import ZiferblatContext from 'components/ZiferblatContext'
import useFab from 'hooks/useFab'
import { useUpsertCulturalEventMutation } from 'queries'
import { createElement as $, FC, Fragment, useContext } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import { CulturalEventsProps } from '..'
import Attendance from '../CulturalEvent/Attendance'
import CulturalEvent from '../CulturalEvent/Desktop'
import CulturalEventListItem from './CulturalEventListItem'

const Desktop: FC<CulturalEventsProps> = (props) => {
  const navigate = useNavigate()
  const { ziferblatId } = useContext(ZiferblatContext)
  const [mutate, { loading }] = useUpsertCulturalEventMutation({
    variables: { 
      input: {
        ziferblatId: ziferblatId,
        startsAt: dateTime().add(1, 'day').toDate()
      }
    },
    updateQueries: {
      CulturalEvents: (prev, next) => ({
        culturalEvents: [next.mutationResult.data?.upsertCulturalEvent, ...prev.culturalEvents]
      })
    }
  })

  const fab = $(Button, {
    size: 'xl',
    view: 'action',
    loading,
    onClick: () => mutate()
      .then(({ data }) => {
        console.log(data?.upsertCulturalEvent)
        data?.upsertCulturalEvent &&
          navigate(`/events/${data.upsertCulturalEvent.id}`)}),
    },
    $(Icon, { data: PlusIcon }))

  useFab(fab)

  return $(Fragment, null,
    $(Routes, null,
      $(Route, {
        path: ':id',
        element: $(CulturalEventContainer)
      }),
      $(Route, { 
        path: '',
        element: $(CulturalEvents, props)
      })))
}

const CulturalEventContainer = () =>
  $(Flex, { gap: 2, direction: 'column' },
    $(Card, {
      view: 'filled',
      style: { maxWidth: 480 },
      children: $(CulturalEvent),
      className: spacing({ p: 4 })
      }),
    $(Attendance))

const CulturalEvents: FC<CulturalEventsProps> = (props) => {

  if (!props.data) return $(Empty)

  return $(Flex, {
    direction: 'column',
    gap: 2,
    style: { maxWidth: 320 }
    },
    props.data?.culturalEvents.map(CulturalEventListItem))
}

export default Desktop