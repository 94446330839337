import { DateField, DateFieldState } from '@gravity-ui/date-components'
import { DateTime, dateTime } from '@gravity-ui/date-utils'
import { Button, Flex } from '@gravity-ui/uikit'
import ZiferblatContext from 'components/ZiferblatContext'
import { CheckinsDocument, CheckinsQuery, CulturalEventsQuery, useCheckInMutation } from 'queries'
import { createElement as $, FC, Fragment, useContext, useState } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import NameInput from './NameInput'
import { Validation, validate } from './validation'
import { ValueBase } from '@gravity-ui/date-components/dist/esm/components/types'

const AddCheckin: FC<CheckinsQuery> = ({
  checkins, 
  upcomingCulturalEvents
}) => {
  const [name, setName] = useState('')
  const [checkedInAt, setCheckedInAt] = useState<DateTime | null>(dateTime())
  const validation = validate(checkins || [], { id: '', name })
  const props = { name, validation }
  const navigate = useNavigate()

  return $(Flex, {
    direction: 'column',
    gap: 4
    },
    $(NameInput, { setName, ...props }),
    $(DateField, {
      format: 'HH:mm',
      size: 'xl',
      // gravity crutch
      value: checkedInAt as DateFieldState['value'],
      onUpdate: setCheckedInAt as ValueBase<any>['onUpdate']
    }),
    $(CulturalEvents, {
      checkedInAt,
      checkins,
      upcomingCulturalEvents,
      onClose: () => navigate('/checkins'),
      ...props
    }))
}

const CulturalEvents: FC<CulturalEventsProps> = ({
  name,
  checkedInAt,
  onClose,
  validation,
  checkins,
  upcomingCulturalEvents
}) => {
  const intl = useIntl()
  const { ziferblatId } = useContext(ZiferblatContext)
  const [mutate, { loading }] = useCheckInMutation()
  const disabled = name.length < 2 || validation === Validation.Exists

  const onClick = (culturalEventIds: string[]) => mutate({
    variables: {
      ziferblatId,
      name,
      checkedInAt: checkedInAt?.toDate(),
      attendance: culturalEventIds.map((culturalEventId) => ({
        culturalEventId
      }))
    },
    update: (cache, result) => {
      const nextCheckins = [...checkins, result.data?.addCheckin]
        .sort((left, right) => left!.checkedInAt > right!.checkedInAt ? 1 : -1)
      cache.modify({
        fields: {
          checkins: () => nextCheckins
        }
      })
    },
    refetchQueries: [{
      query: CheckinsDocument,
      variables: { ziferblatId }
    }]
  }).then(onClose)

  const culturalEventIteratee = (props: CulturalEventsQuery['culturalEvents'][number]) =>
    $(CulturalEventItem, {
      key: props.id,
      onClick: () => onClick([props.id]),
      loading,
      disabled,
      text: props.title! || intl.formatMessage({ id: 'culturalEvent.noName' })
      })

  return $(Fragment, null,
    [...upcomingCulturalEvents || []]
      .sort((left, right) => left.startsAt! > right.startsAt! ? 1 : -1)
      .map(culturalEventIteratee),
    $(CulturalEventItem, {
      onClick: () => onClick([]),
      loading,
      disabled,
      text: intl.formatMessage({ id: 'checkins.add' })
      }))
}

const CulturalEventItem: FC<CulturalEventItemProps> = ({
  onClick,
  loading,
  text,
  disabled
}) =>
  $(Button, {
    size: 'xl',
    view: 'flat',
    onClick,
    loading,
    disabled
    }, text)

type CulturalEventsProps = {
  onClose: () => void
  name: string
  checkedInAt: DateTime | null
  validation: Validation
} & CheckinsQuery

type CulturalEventItemProps = {
  onClick: () => void
  loading: boolean
  disabled: boolean
  text: string
}

export default AddCheckin