import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  smallint: { input: number; output: number; }
  timestamptz: { input: Date; output: Date; }
  uuid: { input: string; output: string; }
};

/** columns and relationships of "access.access" */
export type AccessAccess = {
  __typename?: 'AccessAccess';
  objectId?: Maybe<Scalars['uuid']['output']>;
  permission?: Maybe<Scalars['String']['output']>;
  subjectId?: Maybe<Scalars['uuid']['output']>;
};

/** aggregated selection of "access.access" */
export type AccessAccessAggregate = {
  __typename?: 'AccessAccessAggregate';
  aggregate?: Maybe<AccessAccessAggregateFields>;
  nodes: Array<AccessAccess>;
};

export type AccessAccessAggregateBoolExp = {
  count?: InputMaybe<AccessAccessAggregateBoolExpCount>;
};

/** aggregate fields of "access.access" */
export type AccessAccessAggregateFields = {
  __typename?: 'AccessAccessAggregateFields';
  count: Scalars['Int']['output'];
  max?: Maybe<AccessAccessMaxFields>;
  min?: Maybe<AccessAccessMinFields>;
};


/** aggregate fields of "access.access" */
export type AccessAccessAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AccessAccessSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "access.access" */
export type AccessAccessAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<AccessAccessMaxOrderBy>;
  min?: InputMaybe<AccessAccessMinOrderBy>;
};

/** input type for inserting array relation for remote table "access.access" */
export type AccessAccessArrRelInsertInput = {
  data: Array<AccessAccessInsertInput>;
};

/** Boolean expression to filter rows from the table "access.access". All fields are combined with a logical 'AND'. */
export type AccessAccessBoolExp = {
  _and?: InputMaybe<Array<AccessAccessBoolExp>>;
  _not?: InputMaybe<AccessAccessBoolExp>;
  _or?: InputMaybe<Array<AccessAccessBoolExp>>;
  objectId?: InputMaybe<UuidComparisonExp>;
  permission?: InputMaybe<StringComparisonExp>;
  subjectId?: InputMaybe<UuidComparisonExp>;
};

/** input type for inserting data into table "access.access" */
export type AccessAccessInsertInput = {
  objectId?: InputMaybe<Scalars['uuid']['input']>;
  permission?: InputMaybe<Scalars['String']['input']>;
  subjectId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type AccessAccessMaxFields = {
  __typename?: 'AccessAccessMaxFields';
  objectId?: Maybe<Scalars['uuid']['output']>;
  permission?: Maybe<Scalars['String']['output']>;
  subjectId?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "access.access" */
export type AccessAccessMaxOrderBy = {
  objectId?: InputMaybe<OrderBy>;
  permission?: InputMaybe<OrderBy>;
  subjectId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type AccessAccessMinFields = {
  __typename?: 'AccessAccessMinFields';
  objectId?: Maybe<Scalars['uuid']['output']>;
  permission?: Maybe<Scalars['String']['output']>;
  subjectId?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "access.access" */
export type AccessAccessMinOrderBy = {
  objectId?: InputMaybe<OrderBy>;
  permission?: InputMaybe<OrderBy>;
  subjectId?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "access.access". */
export type AccessAccessOrderBy = {
  objectId?: InputMaybe<OrderBy>;
  permission?: InputMaybe<OrderBy>;
  subjectId?: InputMaybe<OrderBy>;
};

/** select columns of table "access.access" */
export enum AccessAccessSelectColumn {
  /** column name */
  ObjectId = 'objectId',
  /** column name */
  Permission = 'permission',
  /** column name */
  SubjectId = 'subjectId'
}

/** Streaming cursor of the table "access_access" */
export type AccessAccessStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: AccessAccessStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AccessAccessStreamCursorValueInput = {
  objectId?: InputMaybe<Scalars['uuid']['input']>;
  permission?: InputMaybe<Scalars['String']['input']>;
  subjectId?: InputMaybe<Scalars['uuid']['input']>;
};

/** columns and relationships of "access.expire" */
export type AccessExpire = {
  __typename?: 'AccessExpire';
  createdAt: Scalars['timestamptz']['output'];
  /** An object relationship */
  expiredBy?: Maybe<AuthUser>;
  expiredById: Scalars['uuid']['output'];
  expiresAt: Scalars['timestamptz']['output'];
  /** An object relationship */
  grant?: Maybe<AccessGrant>;
  grantId: Scalars['uuid']['output'];
  id: Scalars['uuid']['output'];
};

/** aggregated selection of "access.expire" */
export type AccessExpireAggregate = {
  __typename?: 'AccessExpireAggregate';
  aggregate?: Maybe<AccessExpireAggregateFields>;
  nodes: Array<AccessExpire>;
};

export type AccessExpireAggregateBoolExp = {
  count?: InputMaybe<AccessExpireAggregateBoolExpCount>;
};

/** aggregate fields of "access.expire" */
export type AccessExpireAggregateFields = {
  __typename?: 'AccessExpireAggregateFields';
  count: Scalars['Int']['output'];
  max?: Maybe<AccessExpireMaxFields>;
  min?: Maybe<AccessExpireMinFields>;
};


/** aggregate fields of "access.expire" */
export type AccessExpireAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AccessExpireSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "access.expire" */
export type AccessExpireAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<AccessExpireMaxOrderBy>;
  min?: InputMaybe<AccessExpireMinOrderBy>;
};

/** input type for inserting array relation for remote table "access.expire" */
export type AccessExpireArrRelInsertInput = {
  data: Array<AccessExpireInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<AccessExpireOnConflict>;
};

/** Boolean expression to filter rows from the table "access.expire". All fields are combined with a logical 'AND'. */
export type AccessExpireBoolExp = {
  _and?: InputMaybe<Array<AccessExpireBoolExp>>;
  _not?: InputMaybe<AccessExpireBoolExp>;
  _or?: InputMaybe<Array<AccessExpireBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  expiredBy?: InputMaybe<AuthUserBoolExp>;
  expiredById?: InputMaybe<UuidComparisonExp>;
  expiresAt?: InputMaybe<TimestamptzComparisonExp>;
  grant?: InputMaybe<AccessGrantBoolExp>;
  grantId?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "access.expire" */
export enum AccessExpireConstraint {
  /** unique or primary key constraint on columns "id" */
  ExpirePkey = 'expire_pkey'
}

/** input type for inserting data into table "access.expire" */
export type AccessExpireInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  expiredBy?: InputMaybe<AuthUserObjRelInsertInput>;
  expiredById?: InputMaybe<Scalars['uuid']['input']>;
  expiresAt?: InputMaybe<Scalars['timestamptz']['input']>;
  grant?: InputMaybe<AccessGrantObjRelInsertInput>;
  grantId?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type AccessExpireMaxFields = {
  __typename?: 'AccessExpireMaxFields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  expiredById?: Maybe<Scalars['uuid']['output']>;
  expiresAt?: Maybe<Scalars['timestamptz']['output']>;
  grantId?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "access.expire" */
export type AccessExpireMaxOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  expiredById?: InputMaybe<OrderBy>;
  expiresAt?: InputMaybe<OrderBy>;
  grantId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type AccessExpireMinFields = {
  __typename?: 'AccessExpireMinFields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  expiredById?: Maybe<Scalars['uuid']['output']>;
  expiresAt?: Maybe<Scalars['timestamptz']['output']>;
  grantId?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "access.expire" */
export type AccessExpireMinOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  expiredById?: InputMaybe<OrderBy>;
  expiresAt?: InputMaybe<OrderBy>;
  grantId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "access.expire" */
export type AccessExpireMutationResponse = {
  __typename?: 'AccessExpireMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<AccessExpire>;
};

/** on_conflict condition type for table "access.expire" */
export type AccessExpireOnConflict = {
  constraint: AccessExpireConstraint;
  updateColumns?: Array<AccessExpireUpdateColumn>;
  where?: InputMaybe<AccessExpireBoolExp>;
};

/** Ordering options when selecting data from "access.expire". */
export type AccessExpireOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  expiredBy?: InputMaybe<AuthUserOrderBy>;
  expiredById?: InputMaybe<OrderBy>;
  expiresAt?: InputMaybe<OrderBy>;
  grant?: InputMaybe<AccessGrantOrderBy>;
  grantId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: access.expire */
export type AccessExpirePkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "access.expire" */
export enum AccessExpireSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  ExpiredById = 'expiredById',
  /** column name */
  ExpiresAt = 'expiresAt',
  /** column name */
  GrantId = 'grantId',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "access.expire" */
export type AccessExpireSetInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  expiredById?: InputMaybe<Scalars['uuid']['input']>;
  expiresAt?: InputMaybe<Scalars['timestamptz']['input']>;
  grantId?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "access_expire" */
export type AccessExpireStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: AccessExpireStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AccessExpireStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  expiredById?: InputMaybe<Scalars['uuid']['input']>;
  expiresAt?: InputMaybe<Scalars['timestamptz']['input']>;
  grantId?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "access.expire" */
export enum AccessExpireUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  ExpiredById = 'expiredById',
  /** column name */
  ExpiresAt = 'expiresAt',
  /** column name */
  GrantId = 'grantId',
  /** column name */
  Id = 'id'
}

export type AccessExpireUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AccessExpireSetInput>;
  /** filter the rows which have to be updated */
  where: AccessExpireBoolExp;
};

/** columns and relationships of "access.grant" */
export type AccessGrant = {
  __typename?: 'AccessGrant';
  /** An array relationship */
  access: Array<AccessAccess>;
  /** An aggregate relationship */
  accessAggregate: AccessAccessAggregate;
  /** An array relationship */
  expires: Array<AccessExpire>;
  /** An aggregate relationship */
  expiresAggregate: AccessExpireAggregate;
  grantedById: Scalars['uuid']['output'];
  id: Scalars['uuid']['output'];
  objectId: Scalars['uuid']['output'];
  /** An object relationship */
  role: AccessRole;
  roleId: Scalars['uuid']['output'];
  /** An object relationship */
  subject: AuthUser;
  subjectId: Scalars['uuid']['output'];
  /** An object relationship */
  ziferblat?: Maybe<ZiferblatZiferblat>;
};


/** columns and relationships of "access.grant" */
export type AccessGrantAccessArgs = {
  distinctOn?: InputMaybe<Array<AccessAccessSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AccessAccessOrderBy>>;
  where?: InputMaybe<AccessAccessBoolExp>;
};


/** columns and relationships of "access.grant" */
export type AccessGrantAccessAggregateArgs = {
  distinctOn?: InputMaybe<Array<AccessAccessSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AccessAccessOrderBy>>;
  where?: InputMaybe<AccessAccessBoolExp>;
};


/** columns and relationships of "access.grant" */
export type AccessGrantExpiresArgs = {
  distinctOn?: InputMaybe<Array<AccessExpireSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AccessExpireOrderBy>>;
  where?: InputMaybe<AccessExpireBoolExp>;
};


/** columns and relationships of "access.grant" */
export type AccessGrantExpiresAggregateArgs = {
  distinctOn?: InputMaybe<Array<AccessExpireSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AccessExpireOrderBy>>;
  where?: InputMaybe<AccessExpireBoolExp>;
};

/** aggregated selection of "access.grant" */
export type AccessGrantAggregate = {
  __typename?: 'AccessGrantAggregate';
  aggregate?: Maybe<AccessGrantAggregateFields>;
  nodes: Array<AccessGrant>;
};

export type AccessGrantAggregateBoolExp = {
  count?: InputMaybe<AccessGrantAggregateBoolExpCount>;
};

/** aggregate fields of "access.grant" */
export type AccessGrantAggregateFields = {
  __typename?: 'AccessGrantAggregateFields';
  count: Scalars['Int']['output'];
  max?: Maybe<AccessGrantMaxFields>;
  min?: Maybe<AccessGrantMinFields>;
};


/** aggregate fields of "access.grant" */
export type AccessGrantAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AccessGrantSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "access.grant" */
export type AccessGrantAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<AccessGrantMaxOrderBy>;
  min?: InputMaybe<AccessGrantMinOrderBy>;
};

/** input type for inserting array relation for remote table "access.grant" */
export type AccessGrantArrRelInsertInput = {
  data: Array<AccessGrantInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<AccessGrantOnConflict>;
};

/** Boolean expression to filter rows from the table "access.grant". All fields are combined with a logical 'AND'. */
export type AccessGrantBoolExp = {
  _and?: InputMaybe<Array<AccessGrantBoolExp>>;
  _not?: InputMaybe<AccessGrantBoolExp>;
  _or?: InputMaybe<Array<AccessGrantBoolExp>>;
  access?: InputMaybe<AccessAccessBoolExp>;
  accessAggregate?: InputMaybe<AccessAccessAggregateBoolExp>;
  expires?: InputMaybe<AccessExpireBoolExp>;
  expiresAggregate?: InputMaybe<AccessExpireAggregateBoolExp>;
  grantedById?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  objectId?: InputMaybe<UuidComparisonExp>;
  role?: InputMaybe<AccessRoleBoolExp>;
  roleId?: InputMaybe<UuidComparisonExp>;
  subject?: InputMaybe<AuthUserBoolExp>;
  subjectId?: InputMaybe<UuidComparisonExp>;
  ziferblat?: InputMaybe<ZiferblatZiferblatBoolExp>;
};

/** unique or primary key constraints on table "access.grant" */
export enum AccessGrantConstraint {
  /** unique or primary key constraint on columns "id" */
  GrantPkey = 'grant_pkey'
}

/** input type for inserting data into table "access.grant" */
export type AccessGrantInsertInput = {
  access?: InputMaybe<AccessAccessArrRelInsertInput>;
  expires?: InputMaybe<AccessExpireArrRelInsertInput>;
  grantedById?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  objectId?: InputMaybe<Scalars['uuid']['input']>;
  role?: InputMaybe<AccessRoleObjRelInsertInput>;
  roleId?: InputMaybe<Scalars['uuid']['input']>;
  subject?: InputMaybe<AuthUserObjRelInsertInput>;
  subjectId?: InputMaybe<Scalars['uuid']['input']>;
  ziferblat?: InputMaybe<ZiferblatZiferblatObjRelInsertInput>;
};

/** aggregate max on columns */
export type AccessGrantMaxFields = {
  __typename?: 'AccessGrantMaxFields';
  grantedById?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  objectId?: Maybe<Scalars['uuid']['output']>;
  roleId?: Maybe<Scalars['uuid']['output']>;
  subjectId?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "access.grant" */
export type AccessGrantMaxOrderBy = {
  grantedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  objectId?: InputMaybe<OrderBy>;
  roleId?: InputMaybe<OrderBy>;
  subjectId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type AccessGrantMinFields = {
  __typename?: 'AccessGrantMinFields';
  grantedById?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  objectId?: Maybe<Scalars['uuid']['output']>;
  roleId?: Maybe<Scalars['uuid']['output']>;
  subjectId?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "access.grant" */
export type AccessGrantMinOrderBy = {
  grantedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  objectId?: InputMaybe<OrderBy>;
  roleId?: InputMaybe<OrderBy>;
  subjectId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "access.grant" */
export type AccessGrantMutationResponse = {
  __typename?: 'AccessGrantMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<AccessGrant>;
};

/** input type for inserting object relation for remote table "access.grant" */
export type AccessGrantObjRelInsertInput = {
  data: AccessGrantInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<AccessGrantOnConflict>;
};

/** on_conflict condition type for table "access.grant" */
export type AccessGrantOnConflict = {
  constraint: AccessGrantConstraint;
  updateColumns?: Array<AccessGrantUpdateColumn>;
  where?: InputMaybe<AccessGrantBoolExp>;
};

/** Ordering options when selecting data from "access.grant". */
export type AccessGrantOrderBy = {
  accessAggregate?: InputMaybe<AccessAccessAggregateOrderBy>;
  expiresAggregate?: InputMaybe<AccessExpireAggregateOrderBy>;
  grantedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  objectId?: InputMaybe<OrderBy>;
  role?: InputMaybe<AccessRoleOrderBy>;
  roleId?: InputMaybe<OrderBy>;
  subject?: InputMaybe<AuthUserOrderBy>;
  subjectId?: InputMaybe<OrderBy>;
  ziferblat?: InputMaybe<ZiferblatZiferblatOrderBy>;
};

/** primary key columns input for table: access.grant */
export type AccessGrantPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "access.grant" */
export enum AccessGrantSelectColumn {
  /** column name */
  GrantedById = 'grantedById',
  /** column name */
  Id = 'id',
  /** column name */
  ObjectId = 'objectId',
  /** column name */
  RoleId = 'roleId',
  /** column name */
  SubjectId = 'subjectId'
}

/** input type for updating data in table "access.grant" */
export type AccessGrantSetInput = {
  grantedById?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  objectId?: InputMaybe<Scalars['uuid']['input']>;
  roleId?: InputMaybe<Scalars['uuid']['input']>;
  subjectId?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "access_grant" */
export type AccessGrantStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: AccessGrantStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AccessGrantStreamCursorValueInput = {
  grantedById?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  objectId?: InputMaybe<Scalars['uuid']['input']>;
  roleId?: InputMaybe<Scalars['uuid']['input']>;
  subjectId?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "access.grant" */
export enum AccessGrantUpdateColumn {
  /** column name */
  GrantedById = 'grantedById',
  /** column name */
  Id = 'id',
  /** column name */
  ObjectId = 'objectId',
  /** column name */
  RoleId = 'roleId',
  /** column name */
  SubjectId = 'subjectId'
}

export type AccessGrantUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AccessGrantSetInput>;
  /** filter the rows which have to be updated */
  where: AccessGrantBoolExp;
};

/** columns and relationships of "access.permission" */
export type AccessPermission = {
  __typename?: 'AccessPermission';
  createdAt: Scalars['timestamptz']['output'];
  description: Scalars['String']['output'];
  id: Scalars['uuid']['output'];
  roleId: Scalars['uuid']['output'];
};

/** aggregated selection of "access.permission" */
export type AccessPermissionAggregate = {
  __typename?: 'AccessPermissionAggregate';
  aggregate?: Maybe<AccessPermissionAggregateFields>;
  nodes: Array<AccessPermission>;
};

/** aggregate fields of "access.permission" */
export type AccessPermissionAggregateFields = {
  __typename?: 'AccessPermissionAggregateFields';
  count: Scalars['Int']['output'];
  max?: Maybe<AccessPermissionMaxFields>;
  min?: Maybe<AccessPermissionMinFields>;
};


/** aggregate fields of "access.permission" */
export type AccessPermissionAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AccessPermissionSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "access.permission". All fields are combined with a logical 'AND'. */
export type AccessPermissionBoolExp = {
  _and?: InputMaybe<Array<AccessPermissionBoolExp>>;
  _not?: InputMaybe<AccessPermissionBoolExp>;
  _or?: InputMaybe<Array<AccessPermissionBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  roleId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "access.permission" */
export enum AccessPermissionConstraint {
  /** unique or primary key constraint on columns "id" */
  PermissionPkey = 'permission_pkey'
}

/** input type for inserting data into table "access.permission" */
export type AccessPermissionInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  roleId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type AccessPermissionMaxFields = {
  __typename?: 'AccessPermissionMaxFields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  roleId?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type AccessPermissionMinFields = {
  __typename?: 'AccessPermissionMinFields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  roleId?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "access.permission" */
export type AccessPermissionMutationResponse = {
  __typename?: 'AccessPermissionMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<AccessPermission>;
};

/** on_conflict condition type for table "access.permission" */
export type AccessPermissionOnConflict = {
  constraint: AccessPermissionConstraint;
  updateColumns?: Array<AccessPermissionUpdateColumn>;
  where?: InputMaybe<AccessPermissionBoolExp>;
};

/** Ordering options when selecting data from "access.permission". */
export type AccessPermissionOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  roleId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: access.permission */
export type AccessPermissionPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "access.permission" */
export enum AccessPermissionSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  RoleId = 'roleId'
}

/** input type for updating data in table "access.permission" */
export type AccessPermissionSetInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  roleId?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "access_permission" */
export type AccessPermissionStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: AccessPermissionStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AccessPermissionStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  roleId?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "access.permission" */
export enum AccessPermissionUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  RoleId = 'roleId'
}

export type AccessPermissionUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AccessPermissionSetInput>;
  /** filter the rows which have to be updated */
  where: AccessPermissionBoolExp;
};

/** columns and relationships of "access.role" */
export type AccessRole = {
  __typename?: 'AccessRole';
  createdAt: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  name: Scalars['String']['output'];
};

/** aggregated selection of "access.role" */
export type AccessRoleAggregate = {
  __typename?: 'AccessRoleAggregate';
  aggregate?: Maybe<AccessRoleAggregateFields>;
  nodes: Array<AccessRole>;
};

/** aggregate fields of "access.role" */
export type AccessRoleAggregateFields = {
  __typename?: 'AccessRoleAggregateFields';
  count: Scalars['Int']['output'];
  max?: Maybe<AccessRoleMaxFields>;
  min?: Maybe<AccessRoleMinFields>;
};


/** aggregate fields of "access.role" */
export type AccessRoleAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AccessRoleSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "access.role". All fields are combined with a logical 'AND'. */
export type AccessRoleBoolExp = {
  _and?: InputMaybe<Array<AccessRoleBoolExp>>;
  _not?: InputMaybe<AccessRoleBoolExp>;
  _or?: InputMaybe<Array<AccessRoleBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "access.role" */
export enum AccessRoleConstraint {
  /** unique or primary key constraint on columns "id" */
  RolePkey = 'role_pkey'
}

/** input type for inserting data into table "access.role" */
export type AccessRoleInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type AccessRoleMaxFields = {
  __typename?: 'AccessRoleMaxFields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type AccessRoleMinFields = {
  __typename?: 'AccessRoleMinFields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "access.role" */
export type AccessRoleMutationResponse = {
  __typename?: 'AccessRoleMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<AccessRole>;
};

/** input type for inserting object relation for remote table "access.role" */
export type AccessRoleObjRelInsertInput = {
  data: AccessRoleInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<AccessRoleOnConflict>;
};

/** on_conflict condition type for table "access.role" */
export type AccessRoleOnConflict = {
  constraint: AccessRoleConstraint;
  updateColumns?: Array<AccessRoleUpdateColumn>;
  where?: InputMaybe<AccessRoleBoolExp>;
};

/** Ordering options when selecting data from "access.role". */
export type AccessRoleOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: access.role */
export type AccessRolePkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "access.role" */
export enum AccessRoleSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "access.role" */
export type AccessRoleSetInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "access_role" */
export type AccessRoleStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: AccessRoleStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AccessRoleStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "access.role" */
export enum AccessRoleUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

export type AccessRoleUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AccessRoleSetInput>;
  /** filter the rows which have to be updated */
  where: AccessRoleBoolExp;
};

/** columns and relationships of "auth.alias" */
export type AuthAlias = {
  __typename?: 'AuthAlias';
  createdAt: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  type: Scalars['String']['output'];
  /** An object relationship */
  user?: Maybe<AuthUser>;
  userId: Scalars['uuid']['output'];
  value: Scalars['String']['output'];
};

/** aggregated selection of "auth.alias" */
export type AuthAliasAggregate = {
  __typename?: 'AuthAliasAggregate';
  aggregate?: Maybe<AuthAliasAggregateFields>;
  nodes: Array<AuthAlias>;
};

export type AuthAliasAggregateBoolExp = {
  count?: InputMaybe<AuthAliasAggregateBoolExpCount>;
};

/** aggregate fields of "auth.alias" */
export type AuthAliasAggregateFields = {
  __typename?: 'AuthAliasAggregateFields';
  count: Scalars['Int']['output'];
  max?: Maybe<AuthAliasMaxFields>;
  min?: Maybe<AuthAliasMinFields>;
};


/** aggregate fields of "auth.alias" */
export type AuthAliasAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AuthAliasSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "auth.alias" */
export type AuthAliasAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<AuthAliasMaxOrderBy>;
  min?: InputMaybe<AuthAliasMinOrderBy>;
};

/** input type for inserting array relation for remote table "auth.alias" */
export type AuthAliasArrRelInsertInput = {
  data: Array<AuthAliasInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<AuthAliasOnConflict>;
};

/** Boolean expression to filter rows from the table "auth.alias". All fields are combined with a logical 'AND'. */
export type AuthAliasBoolExp = {
  _and?: InputMaybe<Array<AuthAliasBoolExp>>;
  _not?: InputMaybe<AuthAliasBoolExp>;
  _or?: InputMaybe<Array<AuthAliasBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  type?: InputMaybe<StringComparisonExp>;
  user?: InputMaybe<AuthUserBoolExp>;
  userId?: InputMaybe<UuidComparisonExp>;
  value?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "auth.alias" */
export enum AuthAliasConstraint {
  /** unique or primary key constraint on columns "id" */
  AliasPkey = 'alias_pkey',
  /** unique or primary key constraint on columns "user_id", "type" */
  ByVlalue = 'by_vlalue'
}

/** input type for inserting data into table "auth.alias" */
export type AuthAliasInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<AuthUserObjRelInsertInput>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type AuthAliasMaxFields = {
  __typename?: 'AuthAliasMaxFields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['uuid']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "auth.alias" */
export type AuthAliasMaxOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type AuthAliasMinFields = {
  __typename?: 'AuthAliasMinFields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['uuid']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "auth.alias" */
export type AuthAliasMinOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "auth.alias" */
export type AuthAliasMutationResponse = {
  __typename?: 'AuthAliasMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<AuthAlias>;
};

/** on_conflict condition type for table "auth.alias" */
export type AuthAliasOnConflict = {
  constraint: AuthAliasConstraint;
  updateColumns?: Array<AuthAliasUpdateColumn>;
  where?: InputMaybe<AuthAliasBoolExp>;
};

/** Ordering options when selecting data from "auth.alias". */
export type AuthAliasOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
  user?: InputMaybe<AuthUserOrderBy>;
  userId?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: auth.alias */
export type AuthAliasPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "auth.alias" */
export enum AuthAliasSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'userId',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "auth.alias" */
export type AuthAliasSetInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "auth_alias" */
export type AuthAliasStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: AuthAliasStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AuthAliasStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "auth.alias" */
export enum AuthAliasUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'userId',
  /** column name */
  Value = 'value'
}

export type AuthAliasUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AuthAliasSetInput>;
  /** filter the rows which have to be updated */
  where: AuthAliasBoolExp;
};

/** columns and relationships of "auth.logout" */
export type AuthLogout = {
  __typename?: 'AuthLogout';
  createdAt: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  /** An object relationship */
  session?: Maybe<AuthSession>;
  sessionId: Scalars['uuid']['output'];
};

/** aggregated selection of "auth.logout" */
export type AuthLogoutAggregate = {
  __typename?: 'AuthLogoutAggregate';
  aggregate?: Maybe<AuthLogoutAggregateFields>;
  nodes: Array<AuthLogout>;
};

/** aggregate fields of "auth.logout" */
export type AuthLogoutAggregateFields = {
  __typename?: 'AuthLogoutAggregateFields';
  count: Scalars['Int']['output'];
  max?: Maybe<AuthLogoutMaxFields>;
  min?: Maybe<AuthLogoutMinFields>;
};


/** aggregate fields of "auth.logout" */
export type AuthLogoutAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AuthLogoutSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "auth.logout". All fields are combined with a logical 'AND'. */
export type AuthLogoutBoolExp = {
  _and?: InputMaybe<Array<AuthLogoutBoolExp>>;
  _not?: InputMaybe<AuthLogoutBoolExp>;
  _or?: InputMaybe<Array<AuthLogoutBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  session?: InputMaybe<AuthSessionBoolExp>;
  sessionId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "auth.logout" */
export enum AuthLogoutConstraint {
  /** unique or primary key constraint on columns "id" */
  LogoutPkey = 'logout_pkey'
}

/** input type for inserting data into table "auth.logout" */
export type AuthLogoutInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  session?: InputMaybe<AuthSessionObjRelInsertInput>;
  sessionId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type AuthLogoutMaxFields = {
  __typename?: 'AuthLogoutMaxFields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  sessionId?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type AuthLogoutMinFields = {
  __typename?: 'AuthLogoutMinFields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  sessionId?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "auth.logout" */
export type AuthLogoutMutationResponse = {
  __typename?: 'AuthLogoutMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<AuthLogout>;
};

/** on_conflict condition type for table "auth.logout" */
export type AuthLogoutOnConflict = {
  constraint: AuthLogoutConstraint;
  updateColumns?: Array<AuthLogoutUpdateColumn>;
  where?: InputMaybe<AuthLogoutBoolExp>;
};

/** Ordering options when selecting data from "auth.logout". */
export type AuthLogoutOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  session?: InputMaybe<AuthSessionOrderBy>;
  sessionId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: auth.logout */
export type AuthLogoutPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "auth.logout" */
export enum AuthLogoutSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  SessionId = 'sessionId'
}

/** input type for updating data in table "auth.logout" */
export type AuthLogoutSetInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  sessionId?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "auth_logout" */
export type AuthLogoutStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: AuthLogoutStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AuthLogoutStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  sessionId?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "auth.logout" */
export enum AuthLogoutUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  SessionId = 'sessionId'
}

export type AuthLogoutUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AuthLogoutSetInput>;
  /** filter the rows which have to be updated */
  where: AuthLogoutBoolExp;
};

/** columns and relationships of "auth.session" */
export type AuthSession = {
  __typename?: 'AuthSession';
  createdAt: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  token: Scalars['uuid']['output'];
  /** An object relationship */
  user?: Maybe<AuthUser>;
  userId?: Maybe<Scalars['uuid']['output']>;
};

export type AuthSessionAggregate = {
  __typename?: 'AuthSessionAggregate';
  aggregate?: Maybe<AuthSessionAggregateFields>;
  nodes: Array<AuthSession>;
};

/** aggregate fields of "auth.session" */
export type AuthSessionAggregateFields = {
  __typename?: 'AuthSessionAggregateFields';
  count: Scalars['Int']['output'];
  max?: Maybe<AuthSessionMaxFields>;
  min?: Maybe<AuthSessionMinFields>;
};


/** aggregate fields of "auth.session" */
export type AuthSessionAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AuthSessionSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "auth.session". All fields are combined with a logical 'AND'. */
export type AuthSessionBoolExp = {
  _and?: InputMaybe<Array<AuthSessionBoolExp>>;
  _not?: InputMaybe<AuthSessionBoolExp>;
  _or?: InputMaybe<Array<AuthSessionBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  token?: InputMaybe<UuidComparisonExp>;
  user?: InputMaybe<AuthUserBoolExp>;
  userId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "auth.session" */
export enum AuthSessionConstraint {
  /** unique or primary key constraint on columns "token" */
  ByToken = 'by_token',
  /** unique or primary key constraint on columns "id" */
  SessionPkey = 'session_pkey'
}

/** input type for inserting data into table "auth.session" */
export type AuthSessionInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  token?: InputMaybe<Scalars['uuid']['input']>;
  user?: InputMaybe<AuthUserObjRelInsertInput>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type AuthSessionMaxFields = {
  __typename?: 'AuthSessionMaxFields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  token?: Maybe<Scalars['uuid']['output']>;
  userId?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type AuthSessionMinFields = {
  __typename?: 'AuthSessionMinFields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  token?: Maybe<Scalars['uuid']['output']>;
  userId?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "auth.session" */
export type AuthSessionMutationResponse = {
  __typename?: 'AuthSessionMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<AuthSession>;
};

/** input type for inserting object relation for remote table "auth.session" */
export type AuthSessionObjRelInsertInput = {
  data: AuthSessionInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<AuthSessionOnConflict>;
};

/** on_conflict condition type for table "auth.session" */
export type AuthSessionOnConflict = {
  constraint: AuthSessionConstraint;
  updateColumns?: Array<AuthSessionUpdateColumn>;
  where?: InputMaybe<AuthSessionBoolExp>;
};

/** Ordering options when selecting data from "auth.session". */
export type AuthSessionOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  token?: InputMaybe<OrderBy>;
  user?: InputMaybe<AuthUserOrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: auth.session */
export type AuthSessionPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "auth.session" */
export enum AuthSessionSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Token = 'token',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "auth.session" */
export type AuthSessionSetInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  token?: InputMaybe<Scalars['uuid']['input']>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "auth_session" */
export type AuthSessionStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: AuthSessionStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AuthSessionStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  token?: InputMaybe<Scalars['uuid']['input']>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "auth.session" */
export enum AuthSessionUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Token = 'token',
  /** column name */
  UserId = 'userId'
}

export type AuthSessionUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AuthSessionSetInput>;
  /** filter the rows which have to be updated */
  where: AuthSessionBoolExp;
};

/** columns and relationships of "auth.telegram_bot" */
export type AuthTelegramBot = {
  __typename?: 'AuthTelegramBot';
  botId: Scalars['Int']['output'];
  createdAt: Scalars['timestamptz']['output'];
  domain: Scalars['String']['output'];
  id: Scalars['uuid']['output'];
  name: Scalars['String']['output'];
};

/** aggregated selection of "auth.telegram_bot" */
export type AuthTelegramBotAggregate = {
  __typename?: 'AuthTelegramBotAggregate';
  aggregate?: Maybe<AuthTelegramBotAggregateFields>;
  nodes: Array<AuthTelegramBot>;
};

/** aggregate fields of "auth.telegram_bot" */
export type AuthTelegramBotAggregateFields = {
  __typename?: 'AuthTelegramBotAggregateFields';
  avg?: Maybe<AuthTelegramBotAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<AuthTelegramBotMaxFields>;
  min?: Maybe<AuthTelegramBotMinFields>;
  stddev?: Maybe<AuthTelegramBotStddevFields>;
  stddevPop?: Maybe<AuthTelegramBotStddevPopFields>;
  stddevSamp?: Maybe<AuthTelegramBotStddevSampFields>;
  sum?: Maybe<AuthTelegramBotSumFields>;
  varPop?: Maybe<AuthTelegramBotVarPopFields>;
  varSamp?: Maybe<AuthTelegramBotVarSampFields>;
  variance?: Maybe<AuthTelegramBotVarianceFields>;
};


/** aggregate fields of "auth.telegram_bot" */
export type AuthTelegramBotAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AuthTelegramBotSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type AuthTelegramBotAvgFields = {
  __typename?: 'AuthTelegramBotAvgFields';
  botId?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "auth.telegram_bot". All fields are combined with a logical 'AND'. */
export type AuthTelegramBotBoolExp = {
  _and?: InputMaybe<Array<AuthTelegramBotBoolExp>>;
  _not?: InputMaybe<AuthTelegramBotBoolExp>;
  _or?: InputMaybe<Array<AuthTelegramBotBoolExp>>;
  botId?: InputMaybe<IntComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  domain?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "auth.telegram_bot" */
export enum AuthTelegramBotConstraint {
  /** unique or primary key constraint on columns "id" */
  TelegramBotPkey = 'telegram_bot_pkey'
}

/** input type for incrementing numeric columns in table "auth.telegram_bot" */
export type AuthTelegramBotIncInput = {
  botId?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "auth.telegram_bot" */
export type AuthTelegramBotInsertInput = {
  botId?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  domain?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type AuthTelegramBotMaxFields = {
  __typename?: 'AuthTelegramBotMaxFields';
  botId?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  domain?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type AuthTelegramBotMinFields = {
  __typename?: 'AuthTelegramBotMinFields';
  botId?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  domain?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "auth.telegram_bot" */
export type AuthTelegramBotMutationResponse = {
  __typename?: 'AuthTelegramBotMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<AuthTelegramBot>;
};

/** on_conflict condition type for table "auth.telegram_bot" */
export type AuthTelegramBotOnConflict = {
  constraint: AuthTelegramBotConstraint;
  updateColumns?: Array<AuthTelegramBotUpdateColumn>;
  where?: InputMaybe<AuthTelegramBotBoolExp>;
};

/** Ordering options when selecting data from "auth.telegram_bot". */
export type AuthTelegramBotOrderBy = {
  botId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  domain?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: auth.telegram_bot */
export type AuthTelegramBotPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "auth.telegram_bot" */
export enum AuthTelegramBotSelectColumn {
  /** column name */
  BotId = 'botId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Domain = 'domain',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "auth.telegram_bot" */
export type AuthTelegramBotSetInput = {
  botId?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  domain?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type AuthTelegramBotStddevFields = {
  __typename?: 'AuthTelegramBotStddevFields';
  botId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddevPop on columns */
export type AuthTelegramBotStddevPopFields = {
  __typename?: 'AuthTelegramBotStddevPopFields';
  botId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddevSamp on columns */
export type AuthTelegramBotStddevSampFields = {
  __typename?: 'AuthTelegramBotStddevSampFields';
  botId?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "auth_telegram_bot" */
export type AuthTelegramBotStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: AuthTelegramBotStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AuthTelegramBotStreamCursorValueInput = {
  botId?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  domain?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type AuthTelegramBotSumFields = {
  __typename?: 'AuthTelegramBotSumFields';
  botId?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "auth.telegram_bot" */
export enum AuthTelegramBotUpdateColumn {
  /** column name */
  BotId = 'botId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Domain = 'domain',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

export type AuthTelegramBotUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<AuthTelegramBotIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AuthTelegramBotSetInput>;
  /** filter the rows which have to be updated */
  where: AuthTelegramBotBoolExp;
};

/** aggregate varPop on columns */
export type AuthTelegramBotVarPopFields = {
  __typename?: 'AuthTelegramBotVarPopFields';
  botId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate varSamp on columns */
export type AuthTelegramBotVarSampFields = {
  __typename?: 'AuthTelegramBotVarSampFields';
  botId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type AuthTelegramBotVarianceFields = {
  __typename?: 'AuthTelegramBotVarianceFields';
  botId?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "auth.telegram_input" */
export type AuthTelegramInput = {
  __typename?: 'AuthTelegramInput';
  authDate: Scalars['Int']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  hash: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  photoUrl?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  session: AuthSession;
  sessionId: Scalars['uuid']['output'];
  username: Scalars['String']['output'];
};

/** aggregated selection of "auth.telegram_input" */
export type AuthTelegramInputAggregate = {
  __typename?: 'AuthTelegramInputAggregate';
  aggregate?: Maybe<AuthTelegramInputAggregateFields>;
  nodes: Array<AuthTelegramInput>;
};

/** aggregate fields of "auth.telegram_input" */
export type AuthTelegramInputAggregateFields = {
  __typename?: 'AuthTelegramInputAggregateFields';
  avg?: Maybe<AuthTelegramInputAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<AuthTelegramInputMaxFields>;
  min?: Maybe<AuthTelegramInputMinFields>;
  stddev?: Maybe<AuthTelegramInputStddevFields>;
  stddevPop?: Maybe<AuthTelegramInputStddevPopFields>;
  stddevSamp?: Maybe<AuthTelegramInputStddevSampFields>;
  sum?: Maybe<AuthTelegramInputSumFields>;
  varPop?: Maybe<AuthTelegramInputVarPopFields>;
  varSamp?: Maybe<AuthTelegramInputVarSampFields>;
  variance?: Maybe<AuthTelegramInputVarianceFields>;
};


/** aggregate fields of "auth.telegram_input" */
export type AuthTelegramInputAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AuthTelegramInputSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type AuthTelegramInputAvgFields = {
  __typename?: 'AuthTelegramInputAvgFields';
  authDate?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "auth.telegram_input". All fields are combined with a logical 'AND'. */
export type AuthTelegramInputBoolExp = {
  _and?: InputMaybe<Array<AuthTelegramInputBoolExp>>;
  _not?: InputMaybe<AuthTelegramInputBoolExp>;
  _or?: InputMaybe<Array<AuthTelegramInputBoolExp>>;
  authDate?: InputMaybe<IntComparisonExp>;
  firstName?: InputMaybe<StringComparisonExp>;
  hash?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  lastName?: InputMaybe<StringComparisonExp>;
  photoUrl?: InputMaybe<StringComparisonExp>;
  session?: InputMaybe<AuthSessionBoolExp>;
  sessionId?: InputMaybe<UuidComparisonExp>;
  username?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "auth.telegram_input" */
export enum AuthTelegramInputConstraint {
  /** unique or primary key constraint on columns "hash" */
  TelegramInputPkey = 'telegram_input_pkey'
}

/** input type for incrementing numeric columns in table "auth.telegram_input" */
export type AuthTelegramInputIncInput = {
  authDate?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "auth.telegram_input" */
export type AuthTelegramInputInsertInput = {
  authDate?: InputMaybe<Scalars['Int']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  hash?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  photoUrl?: InputMaybe<Scalars['String']['input']>;
  session?: InputMaybe<AuthSessionObjRelInsertInput>;
  sessionId?: InputMaybe<Scalars['uuid']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type AuthTelegramInputMaxFields = {
  __typename?: 'AuthTelegramInputMaxFields';
  authDate?: Maybe<Scalars['Int']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  hash?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  photoUrl?: Maybe<Scalars['String']['output']>;
  sessionId?: Maybe<Scalars['uuid']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type AuthTelegramInputMinFields = {
  __typename?: 'AuthTelegramInputMinFields';
  authDate?: Maybe<Scalars['Int']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  hash?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  photoUrl?: Maybe<Scalars['String']['output']>;
  sessionId?: Maybe<Scalars['uuid']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "auth.telegram_input" */
export type AuthTelegramInputMutationResponse = {
  __typename?: 'AuthTelegramInputMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<AuthTelegramInput>;
};

/** on_conflict condition type for table "auth.telegram_input" */
export type AuthTelegramInputOnConflict = {
  constraint: AuthTelegramInputConstraint;
  updateColumns?: Array<AuthTelegramInputUpdateColumn>;
  where?: InputMaybe<AuthTelegramInputBoolExp>;
};

/** Ordering options when selecting data from "auth.telegram_input". */
export type AuthTelegramInputOrderBy = {
  authDate?: InputMaybe<OrderBy>;
  firstName?: InputMaybe<OrderBy>;
  hash?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lastName?: InputMaybe<OrderBy>;
  photoUrl?: InputMaybe<OrderBy>;
  session?: InputMaybe<AuthSessionOrderBy>;
  sessionId?: InputMaybe<OrderBy>;
  username?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: auth.telegram_input */
export type AuthTelegramInputPkColumnsInput = {
  hash: Scalars['String']['input'];
};

/** select columns of table "auth.telegram_input" */
export enum AuthTelegramInputSelectColumn {
  /** column name */
  AuthDate = 'authDate',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  Hash = 'hash',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'lastName',
  /** column name */
  PhotoUrl = 'photoUrl',
  /** column name */
  SessionId = 'sessionId',
  /** column name */
  Username = 'username'
}

/** input type for updating data in table "auth.telegram_input" */
export type AuthTelegramInputSetInput = {
  authDate?: InputMaybe<Scalars['Int']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  hash?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  photoUrl?: InputMaybe<Scalars['String']['input']>;
  sessionId?: InputMaybe<Scalars['uuid']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type AuthTelegramInputStddevFields = {
  __typename?: 'AuthTelegramInputStddevFields';
  authDate?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddevPop on columns */
export type AuthTelegramInputStddevPopFields = {
  __typename?: 'AuthTelegramInputStddevPopFields';
  authDate?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddevSamp on columns */
export type AuthTelegramInputStddevSampFields = {
  __typename?: 'AuthTelegramInputStddevSampFields';
  authDate?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "auth_telegram_input" */
export type AuthTelegramInputStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: AuthTelegramInputStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AuthTelegramInputStreamCursorValueInput = {
  authDate?: InputMaybe<Scalars['Int']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  hash?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  photoUrl?: InputMaybe<Scalars['String']['input']>;
  sessionId?: InputMaybe<Scalars['uuid']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type AuthTelegramInputSumFields = {
  __typename?: 'AuthTelegramInputSumFields';
  authDate?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "auth.telegram_input" */
export enum AuthTelegramInputUpdateColumn {
  /** column name */
  AuthDate = 'authDate',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  Hash = 'hash',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'lastName',
  /** column name */
  PhotoUrl = 'photoUrl',
  /** column name */
  SessionId = 'sessionId',
  /** column name */
  Username = 'username'
}

export type AuthTelegramInputUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<AuthTelegramInputIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AuthTelegramInputSetInput>;
  /** filter the rows which have to be updated */
  where: AuthTelegramInputBoolExp;
};

/** aggregate varPop on columns */
export type AuthTelegramInputVarPopFields = {
  __typename?: 'AuthTelegramInputVarPopFields';
  authDate?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate varSamp on columns */
export type AuthTelegramInputVarSampFields = {
  __typename?: 'AuthTelegramInputVarSampFields';
  authDate?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type AuthTelegramInputVarianceFields = {
  __typename?: 'AuthTelegramInputVarianceFields';
  authDate?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "auth.user" */
export type AuthUser = {
  __typename?: 'AuthUser';
  /** An array relationship */
  alias: Array<AuthAlias>;
  /** An aggregate relationship */
  aliasAggregate: AuthAliasAggregate;
  avatarUrl?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['timestamptz']['output'];
  createdById: Scalars['uuid']['output'];
  /** An array relationship */
  grants: Array<AccessGrant>;
  /** An aggregate relationship */
  grantsAggregate: AccessGrantAggregate;
  id: Scalars['uuid']['output'];
};


/** columns and relationships of "auth.user" */
export type AuthUserAliasArgs = {
  distinctOn?: InputMaybe<Array<AuthAliasSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AuthAliasOrderBy>>;
  where?: InputMaybe<AuthAliasBoolExp>;
};


/** columns and relationships of "auth.user" */
export type AuthUserAliasAggregateArgs = {
  distinctOn?: InputMaybe<Array<AuthAliasSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AuthAliasOrderBy>>;
  where?: InputMaybe<AuthAliasBoolExp>;
};


/** columns and relationships of "auth.user" */
export type AuthUserGrantsArgs = {
  distinctOn?: InputMaybe<Array<AccessGrantSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AccessGrantOrderBy>>;
  where?: InputMaybe<AccessGrantBoolExp>;
};


/** columns and relationships of "auth.user" */
export type AuthUserGrantsAggregateArgs = {
  distinctOn?: InputMaybe<Array<AccessGrantSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AccessGrantOrderBy>>;
  where?: InputMaybe<AccessGrantBoolExp>;
};

/** aggregated selection of "auth.user" */
export type AuthUserAggregate = {
  __typename?: 'AuthUserAggregate';
  aggregate?: Maybe<AuthUserAggregateFields>;
  nodes: Array<AuthUser>;
};

/** aggregate fields of "auth.user" */
export type AuthUserAggregateFields = {
  __typename?: 'AuthUserAggregateFields';
  count: Scalars['Int']['output'];
  max?: Maybe<AuthUserMaxFields>;
  min?: Maybe<AuthUserMinFields>;
};


/** aggregate fields of "auth.user" */
export type AuthUserAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AuthUserSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "auth.user". All fields are combined with a logical 'AND'. */
export type AuthUserBoolExp = {
  _and?: InputMaybe<Array<AuthUserBoolExp>>;
  _not?: InputMaybe<AuthUserBoolExp>;
  _or?: InputMaybe<Array<AuthUserBoolExp>>;
  alias?: InputMaybe<AuthAliasBoolExp>;
  aliasAggregate?: InputMaybe<AuthAliasAggregateBoolExp>;
  avatarUrl?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdById?: InputMaybe<UuidComparisonExp>;
  grants?: InputMaybe<AccessGrantBoolExp>;
  grantsAggregate?: InputMaybe<AccessGrantAggregateBoolExp>;
  id?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "auth.user" */
export enum AuthUserConstraint {
  /** unique or primary key constraint on columns "id" */
  UserPkey = 'user_pkey'
}

/** input type for inserting data into table "auth.user" */
export type AuthUserInsertInput = {
  alias?: InputMaybe<AuthAliasArrRelInsertInput>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  createdById?: InputMaybe<Scalars['uuid']['input']>;
  grants?: InputMaybe<AccessGrantArrRelInsertInput>;
  id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type AuthUserMaxFields = {
  __typename?: 'AuthUserMaxFields';
  avatarUrl?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  createdById?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type AuthUserMinFields = {
  __typename?: 'AuthUserMinFields';
  avatarUrl?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  createdById?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "auth.user" */
export type AuthUserMutationResponse = {
  __typename?: 'AuthUserMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<AuthUser>;
};

/** input type for inserting object relation for remote table "auth.user" */
export type AuthUserObjRelInsertInput = {
  data: AuthUserInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<AuthUserOnConflict>;
};

/** on_conflict condition type for table "auth.user" */
export type AuthUserOnConflict = {
  constraint: AuthUserConstraint;
  updateColumns?: Array<AuthUserUpdateColumn>;
  where?: InputMaybe<AuthUserBoolExp>;
};

/** Ordering options when selecting data from "auth.user". */
export type AuthUserOrderBy = {
  aliasAggregate?: InputMaybe<AuthAliasAggregateOrderBy>;
  avatarUrl?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  grantsAggregate?: InputMaybe<AccessGrantAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: auth.user */
export type AuthUserPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "auth.user" */
export enum AuthUserSelectColumn {
  /** column name */
  AvatarUrl = 'avatarUrl',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CreatedById = 'createdById',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "auth.user" */
export type AuthUserSetInput = {
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  createdById?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "auth_user" */
export type AuthUserStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: AuthUserStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AuthUserStreamCursorValueInput = {
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  createdById?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "auth.user" */
export enum AuthUserUpdateColumn {
  /** column name */
  AvatarUrl = 'avatarUrl',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CreatedById = 'createdById',
  /** column name */
  Id = 'id'
}

export type AuthUserUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AuthUserSetInput>;
  /** filter the rows which have to be updated */
  where: AuthUserBoolExp;
};

/** columns and relationships of "checkin" */
export type Checkin = {
  __typename?: 'Checkin';
  /** An array relationship */
  access: Array<AccessAccess>;
  /** An aggregate relationship */
  accessAggregate: AccessAccessAggregate;
  /** An array relationship */
  attendance: Array<CulturalEventAttendance>;
  /** An aggregate relationship */
  attendanceAggregate: CulturalEventAttendanceAggregate;
  checkedInAt: Scalars['timestamptz']['output'];
  checkedInById: Scalars['uuid']['output'];
  id: Scalars['uuid']['output'];
  name?: Maybe<Scalars['String']['output']>;
  /** A computed field, executes function "calculate_checkin_tariff" */
  sum?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  user?: Maybe<AuthUser>;
  userId?: Maybe<Scalars['uuid']['output']>;
  ziferblatId: Scalars['uuid']['output'];
};


/** columns and relationships of "checkin" */
export type CheckinAccessArgs = {
  distinctOn?: InputMaybe<Array<AccessAccessSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AccessAccessOrderBy>>;
  where?: InputMaybe<AccessAccessBoolExp>;
};


/** columns and relationships of "checkin" */
export type CheckinAccessAggregateArgs = {
  distinctOn?: InputMaybe<Array<AccessAccessSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AccessAccessOrderBy>>;
  where?: InputMaybe<AccessAccessBoolExp>;
};


/** columns and relationships of "checkin" */
export type CheckinAttendanceArgs = {
  distinctOn?: InputMaybe<Array<CulturalEventAttendanceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CulturalEventAttendanceOrderBy>>;
  where?: InputMaybe<CulturalEventAttendanceBoolExp>;
};


/** columns and relationships of "checkin" */
export type CheckinAttendanceAggregateArgs = {
  distinctOn?: InputMaybe<Array<CulturalEventAttendanceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CulturalEventAttendanceOrderBy>>;
  where?: InputMaybe<CulturalEventAttendanceBoolExp>;
};

/** aggregated selection of "checkin" */
export type CheckinAggregate = {
  __typename?: 'CheckinAggregate';
  aggregate?: Maybe<CheckinAggregateFields>;
  nodes: Array<Checkin>;
};

/** aggregate fields of "checkin" */
export type CheckinAggregateFields = {
  __typename?: 'CheckinAggregateFields';
  avg?: Maybe<CheckinAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<CheckinMaxFields>;
  min?: Maybe<CheckinMinFields>;
  stddev?: Maybe<CheckinStddevFields>;
  stddevPop?: Maybe<CheckinStddevPopFields>;
  stddevSamp?: Maybe<CheckinStddevSampFields>;
  sum?: Maybe<CheckinSumFields>;
  varPop?: Maybe<CheckinVarPopFields>;
  varSamp?: Maybe<CheckinVarSampFields>;
  variance?: Maybe<CheckinVarianceFields>;
};


/** aggregate fields of "checkin" */
export type CheckinAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CheckinSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type CheckinAvgFields = {
  __typename?: 'CheckinAvgFields';
  /** A computed field, executes function "calculate_checkin_tariff" */
  sum?: Maybe<Scalars['Int']['output']>;
};

/** Boolean expression to filter rows from the table "checkin". All fields are combined with a logical 'AND'. */
export type CheckinBoolExp = {
  _and?: InputMaybe<Array<CheckinBoolExp>>;
  _not?: InputMaybe<CheckinBoolExp>;
  _or?: InputMaybe<Array<CheckinBoolExp>>;
  access?: InputMaybe<AccessAccessBoolExp>;
  accessAggregate?: InputMaybe<AccessAccessAggregateBoolExp>;
  attendance?: InputMaybe<CulturalEventAttendanceBoolExp>;
  attendanceAggregate?: InputMaybe<CulturalEventAttendanceAggregateBoolExp>;
  checkedInAt?: InputMaybe<TimestamptzComparisonExp>;
  checkedInById?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  sum?: InputMaybe<IntComparisonExp>;
  user?: InputMaybe<AuthUserBoolExp>;
  userId?: InputMaybe<UuidComparisonExp>;
  ziferblatId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "checkin" */
export enum CheckinConstraint {
  /** unique or primary key constraint on columns "id" */
  CheckinPkey = 'checkin_pkey'
}

/** input type for inserting data into table "checkin" */
export type CheckinInsertInput = {
  access?: InputMaybe<AccessAccessArrRelInsertInput>;
  attendance?: InputMaybe<CulturalEventAttendanceArrRelInsertInput>;
  checkedInAt?: InputMaybe<Scalars['timestamptz']['input']>;
  checkedInById?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<AuthUserObjRelInsertInput>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
  ziferblatId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type CheckinMaxFields = {
  __typename?: 'CheckinMaxFields';
  checkedInAt?: Maybe<Scalars['timestamptz']['output']>;
  checkedInById?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  /** A computed field, executes function "calculate_checkin_tariff" */
  sum?: Maybe<Scalars['Int']['output']>;
  userId?: Maybe<Scalars['uuid']['output']>;
  ziferblatId?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type CheckinMinFields = {
  __typename?: 'CheckinMinFields';
  checkedInAt?: Maybe<Scalars['timestamptz']['output']>;
  checkedInById?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  /** A computed field, executes function "calculate_checkin_tariff" */
  sum?: Maybe<Scalars['Int']['output']>;
  userId?: Maybe<Scalars['uuid']['output']>;
  ziferblatId?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "checkin" */
export type CheckinMutationResponse = {
  __typename?: 'CheckinMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Checkin>;
};

/** input type for inserting object relation for remote table "checkin" */
export type CheckinObjRelInsertInput = {
  data: CheckinInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<CheckinOnConflict>;
};

/** on_conflict condition type for table "checkin" */
export type CheckinOnConflict = {
  constraint: CheckinConstraint;
  updateColumns?: Array<CheckinUpdateColumn>;
  where?: InputMaybe<CheckinBoolExp>;
};

/** Ordering options when selecting data from "checkin". */
export type CheckinOrderBy = {
  accessAggregate?: InputMaybe<AccessAccessAggregateOrderBy>;
  attendanceAggregate?: InputMaybe<CulturalEventAttendanceAggregateOrderBy>;
  checkedInAt?: InputMaybe<OrderBy>;
  checkedInById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  sum?: InputMaybe<OrderBy>;
  user?: InputMaybe<AuthUserOrderBy>;
  userId?: InputMaybe<OrderBy>;
  ziferblatId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: checkin */
export type CheckinPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "checkin" */
export enum CheckinSelectColumn {
  /** column name */
  CheckedInAt = 'checkedInAt',
  /** column name */
  CheckedInById = 'checkedInById',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UserId = 'userId',
  /** column name */
  ZiferblatId = 'ziferblatId'
}

/** input type for updating data in table "checkin" */
export type CheckinSetInput = {
  checkedInAt?: InputMaybe<Scalars['timestamptz']['input']>;
  checkedInById?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
  ziferblatId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate stddev on columns */
export type CheckinStddevFields = {
  __typename?: 'CheckinStddevFields';
  /** A computed field, executes function "calculate_checkin_tariff" */
  sum?: Maybe<Scalars['Int']['output']>;
};

/** aggregate stddevPop on columns */
export type CheckinStddevPopFields = {
  __typename?: 'CheckinStddevPopFields';
  /** A computed field, executes function "calculate_checkin_tariff" */
  sum?: Maybe<Scalars['Int']['output']>;
};

/** aggregate stddevSamp on columns */
export type CheckinStddevSampFields = {
  __typename?: 'CheckinStddevSampFields';
  /** A computed field, executes function "calculate_checkin_tariff" */
  sum?: Maybe<Scalars['Int']['output']>;
};

/** Streaming cursor of the table "checkin" */
export type CheckinStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: CheckinStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CheckinStreamCursorValueInput = {
  checkedInAt?: InputMaybe<Scalars['timestamptz']['input']>;
  checkedInById?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
  ziferblatId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate sum on columns */
export type CheckinSumFields = {
  __typename?: 'CheckinSumFields';
  /** A computed field, executes function "calculate_checkin_tariff" */
  sum?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "checkin" */
export enum CheckinUpdateColumn {
  /** column name */
  CheckedInAt = 'checkedInAt',
  /** column name */
  CheckedInById = 'checkedInById',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UserId = 'userId',
  /** column name */
  ZiferblatId = 'ziferblatId'
}

export type CheckinUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CheckinSetInput>;
  /** filter the rows which have to be updated */
  where: CheckinBoolExp;
};

/** aggregate varPop on columns */
export type CheckinVarPopFields = {
  __typename?: 'CheckinVarPopFields';
  /** A computed field, executes function "calculate_checkin_tariff" */
  sum?: Maybe<Scalars['Int']['output']>;
};

/** aggregate varSamp on columns */
export type CheckinVarSampFields = {
  __typename?: 'CheckinVarSampFields';
  /** A computed field, executes function "calculate_checkin_tariff" */
  sum?: Maybe<Scalars['Int']['output']>;
};

/** aggregate variance on columns */
export type CheckinVarianceFields = {
  __typename?: 'CheckinVarianceFields';
  /** A computed field, executes function "calculate_checkin_tariff" */
  sum?: Maybe<Scalars['Int']['output']>;
};

/** columns and relationships of "cultural_event.compensation_currency" */
export type CompensationCurrency = {
  __typename?: 'CompensationCurrency';
  description: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

/** aggregated selection of "cultural_event.compensation_currency" */
export type CompensationCurrencyAggregate = {
  __typename?: 'CompensationCurrencyAggregate';
  aggregate?: Maybe<CompensationCurrencyAggregateFields>;
  nodes: Array<CompensationCurrency>;
};

/** aggregate fields of "cultural_event.compensation_currency" */
export type CompensationCurrencyAggregateFields = {
  __typename?: 'CompensationCurrencyAggregateFields';
  count: Scalars['Int']['output'];
  max?: Maybe<CompensationCurrencyMaxFields>;
  min?: Maybe<CompensationCurrencyMinFields>;
};


/** aggregate fields of "cultural_event.compensation_currency" */
export type CompensationCurrencyAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CompensationCurrencySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "cultural_event.compensation_currency". All fields are combined with a logical 'AND'. */
export type CompensationCurrencyBoolExp = {
  _and?: InputMaybe<Array<CompensationCurrencyBoolExp>>;
  _not?: InputMaybe<CompensationCurrencyBoolExp>;
  _or?: InputMaybe<Array<CompensationCurrencyBoolExp>>;
  description?: InputMaybe<StringComparisonExp>;
  value?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "cultural_event.compensation_currency" */
export enum CompensationCurrencyConstraint {
  /** unique or primary key constraint on columns "value" */
  CompensationCurrencyPkey = 'compensation_currency_pkey'
}

export enum CompensationCurrencyEnum {
  /** Compensation with minutes in Ziferblat */
  Minutes = 'MINUTES',
  /** Compensation with actual money */
  Money = 'MONEY'
}

/** Boolean expression to compare columns of type "CompensationCurrencyEnum". All fields are combined with logical 'AND'. */
export type CompensationCurrencyEnumComparisonExp = {
  _eq?: InputMaybe<CompensationCurrencyEnum>;
  _in?: InputMaybe<Array<CompensationCurrencyEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<CompensationCurrencyEnum>;
  _nin?: InputMaybe<Array<CompensationCurrencyEnum>>;
};

/** input type for inserting data into table "cultural_event.compensation_currency" */
export type CompensationCurrencyInsertInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type CompensationCurrencyMaxFields = {
  __typename?: 'CompensationCurrencyMaxFields';
  description?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type CompensationCurrencyMinFields = {
  __typename?: 'CompensationCurrencyMinFields';
  description?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "cultural_event.compensation_currency" */
export type CompensationCurrencyMutationResponse = {
  __typename?: 'CompensationCurrencyMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<CompensationCurrency>;
};

/** on_conflict condition type for table "cultural_event.compensation_currency" */
export type CompensationCurrencyOnConflict = {
  constraint: CompensationCurrencyConstraint;
  updateColumns?: Array<CompensationCurrencyUpdateColumn>;
  where?: InputMaybe<CompensationCurrencyBoolExp>;
};

/** Ordering options when selecting data from "cultural_event.compensation_currency". */
export type CompensationCurrencyOrderBy = {
  description?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: cultural_event.compensation_currency */
export type CompensationCurrencyPkColumnsInput = {
  value: Scalars['String']['input'];
};

/** select columns of table "cultural_event.compensation_currency" */
export enum CompensationCurrencySelectColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "cultural_event.compensation_currency" */
export type CompensationCurrencySetInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "CompensationCurrency" */
export type CompensationCurrencyStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: CompensationCurrencyStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CompensationCurrencyStreamCursorValueInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "cultural_event.compensation_currency" */
export enum CompensationCurrencyUpdateColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

export type CompensationCurrencyUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CompensationCurrencySetInput>;
  /** filter the rows which have to be updated */
  where: CompensationCurrencyBoolExp;
};

/** columns and relationships of "cultural_event.compensation_unit" */
export type CompensationUnit = {
  __typename?: 'CompensationUnit';
  description: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

/** aggregated selection of "cultural_event.compensation_unit" */
export type CompensationUnitAggregate = {
  __typename?: 'CompensationUnitAggregate';
  aggregate?: Maybe<CompensationUnitAggregateFields>;
  nodes: Array<CompensationUnit>;
};

/** aggregate fields of "cultural_event.compensation_unit" */
export type CompensationUnitAggregateFields = {
  __typename?: 'CompensationUnitAggregateFields';
  count: Scalars['Int']['output'];
  max?: Maybe<CompensationUnitMaxFields>;
  min?: Maybe<CompensationUnitMinFields>;
};


/** aggregate fields of "cultural_event.compensation_unit" */
export type CompensationUnitAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CompensationUnitSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "cultural_event.compensation_unit". All fields are combined with a logical 'AND'. */
export type CompensationUnitBoolExp = {
  _and?: InputMaybe<Array<CompensationUnitBoolExp>>;
  _not?: InputMaybe<CompensationUnitBoolExp>;
  _or?: InputMaybe<Array<CompensationUnitBoolExp>>;
  description?: InputMaybe<StringComparisonExp>;
  value?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "cultural_event.compensation_unit" */
export enum CompensationUnitConstraint {
  /** unique or primary key constraint on columns "value" */
  CompensationUnitPkey = 'compensation_unit_pkey'
}

export enum CompensationUnitEnum {
  /** Fixed compensation */
  Fixed = 'FIXED',
  /** Compensation by percentage from overall sum */
  Percentage = 'PERCENTAGE'
}

/** Boolean expression to compare columns of type "CompensationUnitEnum". All fields are combined with logical 'AND'. */
export type CompensationUnitEnumComparisonExp = {
  _eq?: InputMaybe<CompensationUnitEnum>;
  _in?: InputMaybe<Array<CompensationUnitEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<CompensationUnitEnum>;
  _nin?: InputMaybe<Array<CompensationUnitEnum>>;
};

/** input type for inserting data into table "cultural_event.compensation_unit" */
export type CompensationUnitInsertInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type CompensationUnitMaxFields = {
  __typename?: 'CompensationUnitMaxFields';
  description?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type CompensationUnitMinFields = {
  __typename?: 'CompensationUnitMinFields';
  description?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "cultural_event.compensation_unit" */
export type CompensationUnitMutationResponse = {
  __typename?: 'CompensationUnitMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<CompensationUnit>;
};

/** on_conflict condition type for table "cultural_event.compensation_unit" */
export type CompensationUnitOnConflict = {
  constraint: CompensationUnitConstraint;
  updateColumns?: Array<CompensationUnitUpdateColumn>;
  where?: InputMaybe<CompensationUnitBoolExp>;
};

/** Ordering options when selecting data from "cultural_event.compensation_unit". */
export type CompensationUnitOrderBy = {
  description?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: cultural_event.compensation_unit */
export type CompensationUnitPkColumnsInput = {
  value: Scalars['String']['input'];
};

/** select columns of table "cultural_event.compensation_unit" */
export enum CompensationUnitSelectColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "cultural_event.compensation_unit" */
export type CompensationUnitSetInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "CompensationUnit" */
export type CompensationUnitStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: CompensationUnitStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CompensationUnitStreamCursorValueInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "cultural_event.compensation_unit" */
export enum CompensationUnitUpdateColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

export type CompensationUnitUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CompensationUnitSetInput>;
  /** filter the rows which have to be updated */
  where: CompensationUnitBoolExp;
};

/** columns and relationships of "cultural_event.cultural_event" */
export type CulturalEvent = {
  __typename?: 'CulturalEvent';
  /** An array relationship */
  access: Array<AccessAccess>;
  /** An aggregate relationship */
  accessAggregate: AccessAccessAggregate;
  /** An array relationship */
  attendees: Array<Attendees>;
  /** An aggregate relationship */
  attendeesAggregate: AttendeesAggregate;
  /** An object relationship */
  compensation?: Maybe<CulturalEventCompensation>;
  createdAt: Scalars['timestamptz']['output'];
  endsAt: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  internalComment: Scalars['String']['output'];
  publicDescription: Scalars['String']['output'];
  startsAt: Scalars['timestamptz']['output'];
  status: CulturalEventStatusEnum;
  title: Scalars['String']['output'];
  ziferblatId: Scalars['uuid']['output'];
};


/** columns and relationships of "cultural_event.cultural_event" */
export type CulturalEventAccessArgs = {
  distinctOn?: InputMaybe<Array<AccessAccessSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AccessAccessOrderBy>>;
  where?: InputMaybe<AccessAccessBoolExp>;
};


/** columns and relationships of "cultural_event.cultural_event" */
export type CulturalEventAccessAggregateArgs = {
  distinctOn?: InputMaybe<Array<AccessAccessSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AccessAccessOrderBy>>;
  where?: InputMaybe<AccessAccessBoolExp>;
};


/** columns and relationships of "cultural_event.cultural_event" */
export type CulturalEventAttendeesArgs = {
  distinctOn?: InputMaybe<Array<AttendeesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AttendeesOrderBy>>;
  where?: InputMaybe<AttendeesBoolExp>;
};


/** columns and relationships of "cultural_event.cultural_event" */
export type CulturalEventAttendeesAggregateArgs = {
  distinctOn?: InputMaybe<Array<AttendeesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AttendeesOrderBy>>;
  where?: InputMaybe<AttendeesBoolExp>;
};

/** aggregated selection of "cultural_event.cultural_event" */
export type CulturalEventAggregate = {
  __typename?: 'CulturalEventAggregate';
  aggregate?: Maybe<CulturalEventAggregateFields>;
  nodes: Array<CulturalEvent>;
};

/** aggregate fields of "cultural_event.cultural_event" */
export type CulturalEventAggregateFields = {
  __typename?: 'CulturalEventAggregateFields';
  count: Scalars['Int']['output'];
  max?: Maybe<CulturalEventMaxFields>;
  min?: Maybe<CulturalEventMinFields>;
};


/** aggregate fields of "cultural_event.cultural_event" */
export type CulturalEventAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CulturalEventSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** columns and relationships of "cultural_event.attendance" */
export type CulturalEventAttendance = {
  __typename?: 'CulturalEventAttendance';
  addedById: Scalars['uuid']['output'];
  /** An object relationship */
  checkin?: Maybe<Checkin>;
  createdAt: Scalars['timestamptz']['output'];
  /** An object relationship */
  culturalEvent: CulturalEvent;
  culturalEventId: Scalars['uuid']['output'];
  id: Scalars['uuid']['output'];
  visitId: Scalars['uuid']['output'];
};

/** aggregated selection of "cultural_event.attendance" */
export type CulturalEventAttendanceAggregate = {
  __typename?: 'CulturalEventAttendanceAggregate';
  aggregate?: Maybe<CulturalEventAttendanceAggregateFields>;
  nodes: Array<CulturalEventAttendance>;
};

export type CulturalEventAttendanceAggregateBoolExp = {
  count?: InputMaybe<CulturalEventAttendanceAggregateBoolExpCount>;
};

/** aggregate fields of "cultural_event.attendance" */
export type CulturalEventAttendanceAggregateFields = {
  __typename?: 'CulturalEventAttendanceAggregateFields';
  count: Scalars['Int']['output'];
  max?: Maybe<CulturalEventAttendanceMaxFields>;
  min?: Maybe<CulturalEventAttendanceMinFields>;
};


/** aggregate fields of "cultural_event.attendance" */
export type CulturalEventAttendanceAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CulturalEventAttendanceSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "cultural_event.attendance" */
export type CulturalEventAttendanceAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<CulturalEventAttendanceMaxOrderBy>;
  min?: InputMaybe<CulturalEventAttendanceMinOrderBy>;
};

/** input type for inserting array relation for remote table "cultural_event.attendance" */
export type CulturalEventAttendanceArrRelInsertInput = {
  data: Array<CulturalEventAttendanceInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<CulturalEventAttendanceOnConflict>;
};

/** Boolean expression to filter rows from the table "cultural_event.attendance". All fields are combined with a logical 'AND'. */
export type CulturalEventAttendanceBoolExp = {
  _and?: InputMaybe<Array<CulturalEventAttendanceBoolExp>>;
  _not?: InputMaybe<CulturalEventAttendanceBoolExp>;
  _or?: InputMaybe<Array<CulturalEventAttendanceBoolExp>>;
  addedById?: InputMaybe<UuidComparisonExp>;
  checkin?: InputMaybe<CheckinBoolExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  culturalEvent?: InputMaybe<CulturalEventBoolExp>;
  culturalEventId?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  visitId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "cultural_event.attendance" */
export enum CulturalEventAttendanceConstraint {
  /** unique or primary key constraint on columns "id" */
  AttendancePkey = 'attendance_pkey'
}

/** input type for inserting data into table "cultural_event.attendance" */
export type CulturalEventAttendanceInsertInput = {
  addedById?: InputMaybe<Scalars['uuid']['input']>;
  checkin?: InputMaybe<CheckinObjRelInsertInput>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  culturalEvent?: InputMaybe<CulturalEventObjRelInsertInput>;
  culturalEventId?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  visitId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type CulturalEventAttendanceMaxFields = {
  __typename?: 'CulturalEventAttendanceMaxFields';
  addedById?: Maybe<Scalars['uuid']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  culturalEventId?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  visitId?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "cultural_event.attendance" */
export type CulturalEventAttendanceMaxOrderBy = {
  addedById?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  culturalEventId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type CulturalEventAttendanceMinFields = {
  __typename?: 'CulturalEventAttendanceMinFields';
  addedById?: Maybe<Scalars['uuid']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  culturalEventId?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  visitId?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "cultural_event.attendance" */
export type CulturalEventAttendanceMinOrderBy = {
  addedById?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  culturalEventId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "cultural_event.attendance" */
export type CulturalEventAttendanceMutationResponse = {
  __typename?: 'CulturalEventAttendanceMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<CulturalEventAttendance>;
};

/** on_conflict condition type for table "cultural_event.attendance" */
export type CulturalEventAttendanceOnConflict = {
  constraint: CulturalEventAttendanceConstraint;
  updateColumns?: Array<CulturalEventAttendanceUpdateColumn>;
  where?: InputMaybe<CulturalEventAttendanceBoolExp>;
};

/** Ordering options when selecting data from "cultural_event.attendance". */
export type CulturalEventAttendanceOrderBy = {
  addedById?: InputMaybe<OrderBy>;
  checkin?: InputMaybe<CheckinOrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  culturalEvent?: InputMaybe<CulturalEventOrderBy>;
  culturalEventId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: cultural_event.attendance */
export type CulturalEventAttendancePkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "cultural_event.attendance" */
export enum CulturalEventAttendanceSelectColumn {
  /** column name */
  AddedById = 'addedById',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CulturalEventId = 'culturalEventId',
  /** column name */
  Id = 'id',
  /** column name */
  VisitId = 'visitId'
}

/** input type for updating data in table "cultural_event.attendance" */
export type CulturalEventAttendanceSetInput = {
  addedById?: InputMaybe<Scalars['uuid']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  culturalEventId?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  visitId?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "cultural_event_attendance" */
export type CulturalEventAttendanceStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: CulturalEventAttendanceStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CulturalEventAttendanceStreamCursorValueInput = {
  addedById?: InputMaybe<Scalars['uuid']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  culturalEventId?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  visitId?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "cultural_event.attendance" */
export enum CulturalEventAttendanceUpdateColumn {
  /** column name */
  AddedById = 'addedById',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CulturalEventId = 'culturalEventId',
  /** column name */
  Id = 'id',
  /** column name */
  VisitId = 'visitId'
}

export type CulturalEventAttendanceUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CulturalEventAttendanceSetInput>;
  /** filter the rows which have to be updated */
  where: CulturalEventAttendanceBoolExp;
};

/** Boolean expression to filter rows from the table "cultural_event.cultural_event". All fields are combined with a logical 'AND'. */
export type CulturalEventBoolExp = {
  _and?: InputMaybe<Array<CulturalEventBoolExp>>;
  _not?: InputMaybe<CulturalEventBoolExp>;
  _or?: InputMaybe<Array<CulturalEventBoolExp>>;
  access?: InputMaybe<AccessAccessBoolExp>;
  accessAggregate?: InputMaybe<AccessAccessAggregateBoolExp>;
  attendees?: InputMaybe<AttendeesBoolExp>;
  attendeesAggregate?: InputMaybe<AttendeesAggregateBoolExp>;
  compensation?: InputMaybe<CulturalEventCompensationBoolExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  endsAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  internalComment?: InputMaybe<StringComparisonExp>;
  publicDescription?: InputMaybe<StringComparisonExp>;
  startsAt?: InputMaybe<TimestamptzComparisonExp>;
  status?: InputMaybe<CulturalEventStatusEnumComparisonExp>;
  title?: InputMaybe<StringComparisonExp>;
  ziferblatId?: InputMaybe<UuidComparisonExp>;
};

/** columns and relationships of "cultural_event.compensation" */
export type CulturalEventCompensation = {
  __typename?: 'CulturalEventCompensation';
  addedById: Scalars['uuid']['output'];
  amount: Scalars['smallint']['output'];
  createdAt: Scalars['timestamptz']['output'];
  /** An object relationship */
  culturalEvent?: Maybe<CulturalEvent>;
  culturalEventId: Scalars['uuid']['output'];
  currency: CompensationCurrencyEnum;
  id: Scalars['uuid']['output'];
  unit: CompensationUnitEnum;
};

/** aggregated selection of "cultural_event.compensation" */
export type CulturalEventCompensationAggregate = {
  __typename?: 'CulturalEventCompensationAggregate';
  aggregate?: Maybe<CulturalEventCompensationAggregateFields>;
  nodes: Array<CulturalEventCompensation>;
};

/** aggregate fields of "cultural_event.compensation" */
export type CulturalEventCompensationAggregateFields = {
  __typename?: 'CulturalEventCompensationAggregateFields';
  avg?: Maybe<CulturalEventCompensationAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<CulturalEventCompensationMaxFields>;
  min?: Maybe<CulturalEventCompensationMinFields>;
  stddev?: Maybe<CulturalEventCompensationStddevFields>;
  stddevPop?: Maybe<CulturalEventCompensationStddevPopFields>;
  stddevSamp?: Maybe<CulturalEventCompensationStddevSampFields>;
  sum?: Maybe<CulturalEventCompensationSumFields>;
  varPop?: Maybe<CulturalEventCompensationVarPopFields>;
  varSamp?: Maybe<CulturalEventCompensationVarSampFields>;
  variance?: Maybe<CulturalEventCompensationVarianceFields>;
};


/** aggregate fields of "cultural_event.compensation" */
export type CulturalEventCompensationAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CulturalEventCompensationSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type CulturalEventCompensationAvgFields = {
  __typename?: 'CulturalEventCompensationAvgFields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "cultural_event.compensation". All fields are combined with a logical 'AND'. */
export type CulturalEventCompensationBoolExp = {
  _and?: InputMaybe<Array<CulturalEventCompensationBoolExp>>;
  _not?: InputMaybe<CulturalEventCompensationBoolExp>;
  _or?: InputMaybe<Array<CulturalEventCompensationBoolExp>>;
  addedById?: InputMaybe<UuidComparisonExp>;
  amount?: InputMaybe<SmallintComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  culturalEvent?: InputMaybe<CulturalEventBoolExp>;
  culturalEventId?: InputMaybe<UuidComparisonExp>;
  currency?: InputMaybe<CompensationCurrencyEnumComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  unit?: InputMaybe<CompensationUnitEnumComparisonExp>;
};

/** unique or primary key constraints on table "cultural_event.compensation" */
export enum CulturalEventCompensationConstraint {
  /** unique or primary key constraint on columns "id" */
  CompensationPkey = 'compensation_pkey'
}

/** input type for incrementing numeric columns in table "cultural_event.compensation" */
export type CulturalEventCompensationIncInput = {
  amount?: InputMaybe<Scalars['smallint']['input']>;
};

/** input type for inserting data into table "cultural_event.compensation" */
export type CulturalEventCompensationInsertInput = {
  addedById?: InputMaybe<Scalars['uuid']['input']>;
  amount?: InputMaybe<Scalars['smallint']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  culturalEvent?: InputMaybe<CulturalEventObjRelInsertInput>;
  culturalEventId?: InputMaybe<Scalars['uuid']['input']>;
  currency?: InputMaybe<CompensationCurrencyEnum>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  unit?: InputMaybe<CompensationUnitEnum>;
};

/** aggregate max on columns */
export type CulturalEventCompensationMaxFields = {
  __typename?: 'CulturalEventCompensationMaxFields';
  addedById?: Maybe<Scalars['uuid']['output']>;
  amount?: Maybe<Scalars['smallint']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  culturalEventId?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type CulturalEventCompensationMinFields = {
  __typename?: 'CulturalEventCompensationMinFields';
  addedById?: Maybe<Scalars['uuid']['output']>;
  amount?: Maybe<Scalars['smallint']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  culturalEventId?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "cultural_event.compensation" */
export type CulturalEventCompensationMutationResponse = {
  __typename?: 'CulturalEventCompensationMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<CulturalEventCompensation>;
};

/** input type for inserting object relation for remote table "cultural_event.compensation" */
export type CulturalEventCompensationObjRelInsertInput = {
  data: CulturalEventCompensationInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<CulturalEventCompensationOnConflict>;
};

/** on_conflict condition type for table "cultural_event.compensation" */
export type CulturalEventCompensationOnConflict = {
  constraint: CulturalEventCompensationConstraint;
  updateColumns?: Array<CulturalEventCompensationUpdateColumn>;
  where?: InputMaybe<CulturalEventCompensationBoolExp>;
};

/** Ordering options when selecting data from "cultural_event.compensation". */
export type CulturalEventCompensationOrderBy = {
  addedById?: InputMaybe<OrderBy>;
  amount?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  culturalEvent?: InputMaybe<CulturalEventOrderBy>;
  culturalEventId?: InputMaybe<OrderBy>;
  currency?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  unit?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: cultural_event.compensation */
export type CulturalEventCompensationPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "cultural_event.compensation" */
export enum CulturalEventCompensationSelectColumn {
  /** column name */
  AddedById = 'addedById',
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CulturalEventId = 'culturalEventId',
  /** column name */
  Currency = 'currency',
  /** column name */
  Id = 'id',
  /** column name */
  Unit = 'unit'
}

/** input type for updating data in table "cultural_event.compensation" */
export type CulturalEventCompensationSetInput = {
  addedById?: InputMaybe<Scalars['uuid']['input']>;
  amount?: InputMaybe<Scalars['smallint']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  culturalEventId?: InputMaybe<Scalars['uuid']['input']>;
  currency?: InputMaybe<CompensationCurrencyEnum>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  unit?: InputMaybe<CompensationUnitEnum>;
};

/** aggregate stddev on columns */
export type CulturalEventCompensationStddevFields = {
  __typename?: 'CulturalEventCompensationStddevFields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddevPop on columns */
export type CulturalEventCompensationStddevPopFields = {
  __typename?: 'CulturalEventCompensationStddevPopFields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddevSamp on columns */
export type CulturalEventCompensationStddevSampFields = {
  __typename?: 'CulturalEventCompensationStddevSampFields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "cultural_event_compensation" */
export type CulturalEventCompensationStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: CulturalEventCompensationStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CulturalEventCompensationStreamCursorValueInput = {
  addedById?: InputMaybe<Scalars['uuid']['input']>;
  amount?: InputMaybe<Scalars['smallint']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  culturalEventId?: InputMaybe<Scalars['uuid']['input']>;
  currency?: InputMaybe<CompensationCurrencyEnum>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  unit?: InputMaybe<CompensationUnitEnum>;
};

/** aggregate sum on columns */
export type CulturalEventCompensationSumFields = {
  __typename?: 'CulturalEventCompensationSumFields';
  amount?: Maybe<Scalars['smallint']['output']>;
};

/** update columns of table "cultural_event.compensation" */
export enum CulturalEventCompensationUpdateColumn {
  /** column name */
  AddedById = 'addedById',
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CulturalEventId = 'culturalEventId',
  /** column name */
  Currency = 'currency',
  /** column name */
  Id = 'id',
  /** column name */
  Unit = 'unit'
}

export type CulturalEventCompensationUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<CulturalEventCompensationIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CulturalEventCompensationSetInput>;
  /** filter the rows which have to be updated */
  where: CulturalEventCompensationBoolExp;
};

/** aggregate varPop on columns */
export type CulturalEventCompensationVarPopFields = {
  __typename?: 'CulturalEventCompensationVarPopFields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** aggregate varSamp on columns */
export type CulturalEventCompensationVarSampFields = {
  __typename?: 'CulturalEventCompensationVarSampFields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type CulturalEventCompensationVarianceFields = {
  __typename?: 'CulturalEventCompensationVarianceFields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** unique or primary key constraints on table "cultural_event.cultural_event" */
export enum CulturalEventConstraint {
  /** unique or primary key constraint on columns "id" */
  CulturalEventPkey = 'cultural_event_pkey'
}

/** input type for inserting data into table "cultural_event.cultural_event" */
export type CulturalEventInsertInput = {
  access?: InputMaybe<AccessAccessArrRelInsertInput>;
  attendees?: InputMaybe<AttendeesArrRelInsertInput>;
  compensation?: InputMaybe<CulturalEventCompensationObjRelInsertInput>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  endsAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  internalComment?: InputMaybe<Scalars['String']['input']>;
  publicDescription?: InputMaybe<Scalars['String']['input']>;
  startsAt?: InputMaybe<Scalars['timestamptz']['input']>;
  status?: InputMaybe<CulturalEventStatusEnum>;
  title?: InputMaybe<Scalars['String']['input']>;
  ziferblatId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type CulturalEventMaxFields = {
  __typename?: 'CulturalEventMaxFields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  endsAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  internalComment?: Maybe<Scalars['String']['output']>;
  publicDescription?: Maybe<Scalars['String']['output']>;
  startsAt?: Maybe<Scalars['timestamptz']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  ziferblatId?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type CulturalEventMinFields = {
  __typename?: 'CulturalEventMinFields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  endsAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  internalComment?: Maybe<Scalars['String']['output']>;
  publicDescription?: Maybe<Scalars['String']['output']>;
  startsAt?: Maybe<Scalars['timestamptz']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  ziferblatId?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "cultural_event.cultural_event" */
export type CulturalEventMutationResponse = {
  __typename?: 'CulturalEventMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<CulturalEvent>;
};

/** input type for inserting object relation for remote table "cultural_event.cultural_event" */
export type CulturalEventObjRelInsertInput = {
  data: CulturalEventInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<CulturalEventOnConflict>;
};

/** on_conflict condition type for table "cultural_event.cultural_event" */
export type CulturalEventOnConflict = {
  constraint: CulturalEventConstraint;
  updateColumns?: Array<CulturalEventUpdateColumn>;
  where?: InputMaybe<CulturalEventBoolExp>;
};

/** Ordering options when selecting data from "cultural_event.cultural_event". */
export type CulturalEventOrderBy = {
  accessAggregate?: InputMaybe<AccessAccessAggregateOrderBy>;
  attendeesAggregate?: InputMaybe<AttendeesAggregateOrderBy>;
  compensation?: InputMaybe<CulturalEventCompensationOrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  endsAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  internalComment?: InputMaybe<OrderBy>;
  publicDescription?: InputMaybe<OrderBy>;
  startsAt?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  ziferblatId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: cultural_event.cultural_event */
export type CulturalEventPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "cultural_event.cultural_event" */
export enum CulturalEventSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EndsAt = 'endsAt',
  /** column name */
  Id = 'id',
  /** column name */
  InternalComment = 'internalComment',
  /** column name */
  PublicDescription = 'publicDescription',
  /** column name */
  StartsAt = 'startsAt',
  /** column name */
  Status = 'status',
  /** column name */
  Title = 'title',
  /** column name */
  ZiferblatId = 'ziferblatId'
}

/** input type for updating data in table "cultural_event.cultural_event" */
export type CulturalEventSetInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  endsAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  internalComment?: InputMaybe<Scalars['String']['input']>;
  publicDescription?: InputMaybe<Scalars['String']['input']>;
  startsAt?: InputMaybe<Scalars['timestamptz']['input']>;
  status?: InputMaybe<CulturalEventStatusEnum>;
  title?: InputMaybe<Scalars['String']['input']>;
  ziferblatId?: InputMaybe<Scalars['uuid']['input']>;
};

/** columns and relationships of "cultural_event.status" */
export type CulturalEventStatus = {
  __typename?: 'CulturalEventStatus';
  description: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

/** aggregated selection of "cultural_event.status" */
export type CulturalEventStatusAggregate = {
  __typename?: 'CulturalEventStatusAggregate';
  aggregate?: Maybe<CulturalEventStatusAggregateFields>;
  nodes: Array<CulturalEventStatus>;
};

/** aggregate fields of "cultural_event.status" */
export type CulturalEventStatusAggregateFields = {
  __typename?: 'CulturalEventStatusAggregateFields';
  count: Scalars['Int']['output'];
  max?: Maybe<CulturalEventStatusMaxFields>;
  min?: Maybe<CulturalEventStatusMinFields>;
};


/** aggregate fields of "cultural_event.status" */
export type CulturalEventStatusAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CulturalEventStatusSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "cultural_event.status". All fields are combined with a logical 'AND'. */
export type CulturalEventStatusBoolExp = {
  _and?: InputMaybe<Array<CulturalEventStatusBoolExp>>;
  _not?: InputMaybe<CulturalEventStatusBoolExp>;
  _or?: InputMaybe<Array<CulturalEventStatusBoolExp>>;
  description?: InputMaybe<StringComparisonExp>;
  value?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "cultural_event.status" */
export enum CulturalEventStatusConstraint {
  /** unique or primary key constraint on columns "value" */
  StatusPkey = 'status_pkey'
}

export enum CulturalEventStatusEnum {
  /** Should be invisible to common users */
  Archived = 'ARCHIVED',
  /** Visibile to common users, but has informational message */
  Canceled = 'CANCELED',
  /** Should be invisible to common users */
  Draft = 'DRAFT',
  /** Visibile to common users */
  Published = 'PUBLISHED'
}

/** Boolean expression to compare columns of type "CulturalEventStatusEnum". All fields are combined with logical 'AND'. */
export type CulturalEventStatusEnumComparisonExp = {
  _eq?: InputMaybe<CulturalEventStatusEnum>;
  _in?: InputMaybe<Array<CulturalEventStatusEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<CulturalEventStatusEnum>;
  _nin?: InputMaybe<Array<CulturalEventStatusEnum>>;
};

/** input type for inserting data into table "cultural_event.status" */
export type CulturalEventStatusInsertInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type CulturalEventStatusMaxFields = {
  __typename?: 'CulturalEventStatusMaxFields';
  description?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type CulturalEventStatusMinFields = {
  __typename?: 'CulturalEventStatusMinFields';
  description?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "cultural_event.status" */
export type CulturalEventStatusMutationResponse = {
  __typename?: 'CulturalEventStatusMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<CulturalEventStatus>;
};

/** on_conflict condition type for table "cultural_event.status" */
export type CulturalEventStatusOnConflict = {
  constraint: CulturalEventStatusConstraint;
  updateColumns?: Array<CulturalEventStatusUpdateColumn>;
  where?: InputMaybe<CulturalEventStatusBoolExp>;
};

/** Ordering options when selecting data from "cultural_event.status". */
export type CulturalEventStatusOrderBy = {
  description?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: cultural_event.status */
export type CulturalEventStatusPkColumnsInput = {
  value: Scalars['String']['input'];
};

/** select columns of table "cultural_event.status" */
export enum CulturalEventStatusSelectColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "cultural_event.status" */
export type CulturalEventStatusSetInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "cultural_event_status" */
export type CulturalEventStatusStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: CulturalEventStatusStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CulturalEventStatusStreamCursorValueInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "cultural_event.status" */
export enum CulturalEventStatusUpdateColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

export type CulturalEventStatusUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CulturalEventStatusSetInput>;
  /** filter the rows which have to be updated */
  where: CulturalEventStatusBoolExp;
};

/** Streaming cursor of the table "CulturalEvent" */
export type CulturalEventStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: CulturalEventStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CulturalEventStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  endsAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  internalComment?: InputMaybe<Scalars['String']['input']>;
  publicDescription?: InputMaybe<Scalars['String']['input']>;
  startsAt?: InputMaybe<Scalars['timestamptz']['input']>;
  status?: InputMaybe<CulturalEventStatusEnum>;
  title?: InputMaybe<Scalars['String']['input']>;
  ziferblatId?: InputMaybe<Scalars['uuid']['input']>;
};

/** columns and relationships of "cultural_event.ticket" */
export type CulturalEventTicket = {
  __typename?: 'CulturalEventTicket';
  createdAt: Scalars['timestamptz']['output'];
  culturalEventId: Scalars['uuid']['output'];
  id: Scalars['uuid']['output'];
};

/** aggregated selection of "cultural_event.ticket" */
export type CulturalEventTicketAggregate = {
  __typename?: 'CulturalEventTicketAggregate';
  aggregate?: Maybe<CulturalEventTicketAggregateFields>;
  nodes: Array<CulturalEventTicket>;
};

/** aggregate fields of "cultural_event.ticket" */
export type CulturalEventTicketAggregateFields = {
  __typename?: 'CulturalEventTicketAggregateFields';
  count: Scalars['Int']['output'];
  max?: Maybe<CulturalEventTicketMaxFields>;
  min?: Maybe<CulturalEventTicketMinFields>;
};


/** aggregate fields of "cultural_event.ticket" */
export type CulturalEventTicketAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CulturalEventTicketSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "cultural_event.ticket". All fields are combined with a logical 'AND'. */
export type CulturalEventTicketBoolExp = {
  _and?: InputMaybe<Array<CulturalEventTicketBoolExp>>;
  _not?: InputMaybe<CulturalEventTicketBoolExp>;
  _or?: InputMaybe<Array<CulturalEventTicketBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  culturalEventId?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "cultural_event.ticket" */
export enum CulturalEventTicketConstraint {
  /** unique or primary key constraint on columns "id" */
  TicketPkey = 'ticket_pkey'
}

/** input type for inserting data into table "cultural_event.ticket" */
export type CulturalEventTicketInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  culturalEventId?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type CulturalEventTicketMaxFields = {
  __typename?: 'CulturalEventTicketMaxFields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  culturalEventId?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type CulturalEventTicketMinFields = {
  __typename?: 'CulturalEventTicketMinFields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  culturalEventId?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "cultural_event.ticket" */
export type CulturalEventTicketMutationResponse = {
  __typename?: 'CulturalEventTicketMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<CulturalEventTicket>;
};

/** on_conflict condition type for table "cultural_event.ticket" */
export type CulturalEventTicketOnConflict = {
  constraint: CulturalEventTicketConstraint;
  updateColumns?: Array<CulturalEventTicketUpdateColumn>;
  where?: InputMaybe<CulturalEventTicketBoolExp>;
};

/** Ordering options when selecting data from "cultural_event.ticket". */
export type CulturalEventTicketOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  culturalEventId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: cultural_event.ticket */
export type CulturalEventTicketPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "cultural_event.ticket" */
export enum CulturalEventTicketSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CulturalEventId = 'culturalEventId',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "cultural_event.ticket" */
export type CulturalEventTicketSetInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  culturalEventId?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "cultural_event_ticket" */
export type CulturalEventTicketStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: CulturalEventTicketStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CulturalEventTicketStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  culturalEventId?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "cultural_event.ticket" */
export enum CulturalEventTicketUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CulturalEventId = 'culturalEventId',
  /** column name */
  Id = 'id'
}

export type CulturalEventTicketUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CulturalEventTicketSetInput>;
  /** filter the rows which have to be updated */
  where: CulturalEventTicketBoolExp;
};

/** columns and relationships of "cultural_event.ticket_visit" */
export type CulturalEventTicketVisit = {
  __typename?: 'CulturalEventTicketVisit';
  createdAt: Scalars['uuid']['output'];
  id: Scalars['uuid']['output'];
  visitId: Scalars['uuid']['output'];
};

/** aggregated selection of "cultural_event.ticket_visit" */
export type CulturalEventTicketVisitAggregate = {
  __typename?: 'CulturalEventTicketVisitAggregate';
  aggregate?: Maybe<CulturalEventTicketVisitAggregateFields>;
  nodes: Array<CulturalEventTicketVisit>;
};

/** aggregate fields of "cultural_event.ticket_visit" */
export type CulturalEventTicketVisitAggregateFields = {
  __typename?: 'CulturalEventTicketVisitAggregateFields';
  count: Scalars['Int']['output'];
  max?: Maybe<CulturalEventTicketVisitMaxFields>;
  min?: Maybe<CulturalEventTicketVisitMinFields>;
};


/** aggregate fields of "cultural_event.ticket_visit" */
export type CulturalEventTicketVisitAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CulturalEventTicketVisitSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "cultural_event.ticket_visit". All fields are combined with a logical 'AND'. */
export type CulturalEventTicketVisitBoolExp = {
  _and?: InputMaybe<Array<CulturalEventTicketVisitBoolExp>>;
  _not?: InputMaybe<CulturalEventTicketVisitBoolExp>;
  _or?: InputMaybe<Array<CulturalEventTicketVisitBoolExp>>;
  createdAt?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  visitId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "cultural_event.ticket_visit" */
export enum CulturalEventTicketVisitConstraint {
  /** unique or primary key constraint on columns "id" */
  TicketVisitPkey = 'ticket_visit_pkey'
}

/** input type for inserting data into table "cultural_event.ticket_visit" */
export type CulturalEventTicketVisitInsertInput = {
  createdAt?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  visitId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type CulturalEventTicketVisitMaxFields = {
  __typename?: 'CulturalEventTicketVisitMaxFields';
  createdAt?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  visitId?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type CulturalEventTicketVisitMinFields = {
  __typename?: 'CulturalEventTicketVisitMinFields';
  createdAt?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  visitId?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "cultural_event.ticket_visit" */
export type CulturalEventTicketVisitMutationResponse = {
  __typename?: 'CulturalEventTicketVisitMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<CulturalEventTicketVisit>;
};

/** on_conflict condition type for table "cultural_event.ticket_visit" */
export type CulturalEventTicketVisitOnConflict = {
  constraint: CulturalEventTicketVisitConstraint;
  updateColumns?: Array<CulturalEventTicketVisitUpdateColumn>;
  where?: InputMaybe<CulturalEventTicketVisitBoolExp>;
};

/** Ordering options when selecting data from "cultural_event.ticket_visit". */
export type CulturalEventTicketVisitOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: cultural_event.ticket_visit */
export type CulturalEventTicketVisitPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "cultural_event.ticket_visit" */
export enum CulturalEventTicketVisitSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  VisitId = 'visitId'
}

/** input type for updating data in table "cultural_event.ticket_visit" */
export type CulturalEventTicketVisitSetInput = {
  createdAt?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  visitId?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "cultural_event_ticket_visit" */
export type CulturalEventTicketVisitStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: CulturalEventTicketVisitStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CulturalEventTicketVisitStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  visitId?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "cultural_event.ticket_visit" */
export enum CulturalEventTicketVisitUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  VisitId = 'visitId'
}

export type CulturalEventTicketVisitUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CulturalEventTicketVisitSetInput>;
  /** filter the rows which have to be updated */
  where: CulturalEventTicketVisitBoolExp;
};

/** update columns of table "cultural_event.cultural_event" */
export enum CulturalEventUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EndsAt = 'endsAt',
  /** column name */
  Id = 'id',
  /** column name */
  InternalComment = 'internalComment',
  /** column name */
  PublicDescription = 'publicDescription',
  /** column name */
  StartsAt = 'startsAt',
  /** column name */
  Status = 'status',
  /** column name */
  Title = 'title',
  /** column name */
  ZiferblatId = 'ziferblatId'
}

export type CulturalEventUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CulturalEventSetInput>;
  /** filter the rows which have to be updated */
  where: CulturalEventBoolExp;
};

/** ordering argument of a cursor */
export enum CursorOrdering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type IntComparisonExp = {
  _eq?: InputMaybe<Scalars['Int']['input']>;
  _gt?: InputMaybe<Scalars['Int']['input']>;
  _gte?: InputMaybe<Scalars['Int']['input']>;
  _in?: InputMaybe<Array<Scalars['Int']['input']>>;
  _isNull?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Int']['input']>;
  _lte?: InputMaybe<Scalars['Int']['input']>;
  _neq?: InputMaybe<Scalars['Int']['input']>;
  _nin?: InputMaybe<Array<Scalars['Int']['input']>>;
};

/** column ordering options */
export enum OrderBy {
  /** in ascending order, nulls last */
  Asc = 'ASC',
  /** in ascending order, nulls first */
  AscNullsFirst = 'ASC_NULLS_FIRST',
  /** in ascending order, nulls last */
  AscNullsLast = 'ASC_NULLS_LAST',
  /** in descending order, nulls first */
  Desc = 'DESC',
  /** in descending order, nulls first */
  DescNullsFirst = 'DESC_NULLS_FIRST',
  /** in descending order, nulls last */
  DescNullsLast = 'DESC_NULLS_LAST'
}

/** Boolean expression to compare columns of type "smallint". All fields are combined with logical 'AND'. */
export type SmallintArrayComparisonExp = {
  /** is the array contained in the given array value */
  _containedIn?: InputMaybe<Array<Scalars['smallint']['input']>>;
  /** does the array contain the given value */
  _contains?: InputMaybe<Array<Scalars['smallint']['input']>>;
  _eq?: InputMaybe<Array<Scalars['smallint']['input']>>;
  _gt?: InputMaybe<Array<Scalars['smallint']['input']>>;
  _gte?: InputMaybe<Array<Scalars['smallint']['input']>>;
  _in?: InputMaybe<Array<Array<Scalars['smallint']['input']>>>;
  _isNull?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Array<Scalars['smallint']['input']>>;
  _lte?: InputMaybe<Array<Scalars['smallint']['input']>>;
  _neq?: InputMaybe<Array<Scalars['smallint']['input']>>;
  _nin?: InputMaybe<Array<Array<Scalars['smallint']['input']>>>;
};

/** Boolean expression to compare columns of type "smallint". All fields are combined with logical 'AND'. */
export type SmallintComparisonExp = {
  _eq?: InputMaybe<Scalars['smallint']['input']>;
  _gt?: InputMaybe<Scalars['smallint']['input']>;
  _gte?: InputMaybe<Scalars['smallint']['input']>;
  _in?: InputMaybe<Array<Scalars['smallint']['input']>>;
  _isNull?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['smallint']['input']>;
  _lte?: InputMaybe<Scalars['smallint']['input']>;
  _neq?: InputMaybe<Scalars['smallint']['input']>;
  _nin?: InputMaybe<Array<Scalars['smallint']['input']>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type StringComparisonExp = {
  _eq?: InputMaybe<Scalars['String']['input']>;
  _gt?: InputMaybe<Scalars['String']['input']>;
  _gte?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']['input']>;
  _in?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']['input']>;
  _isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']['input']>;
  _lt?: InputMaybe<Scalars['String']['input']>;
  _lte?: InputMaybe<Scalars['String']['input']>;
  _neq?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']['input']>;
  _nin?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "tariff.tariff" */
export type TariffTariff = {
  __typename?: 'TariffTariff';
  /** An array relationship */
  access: Array<AccessAccess>;
  /** An aggregate relationship */
  accessAggregate: AccessAccessAggregate;
  activeFrom: Scalars['timestamptz']['output'];
  /** A computed field, executes function "tariff.get_active_to" */
  activeTo?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  addedBy: AuthUser;
  addedById: Scalars['uuid']['output'];
  createdAt: Scalars['timestamptz']['output'];
  hourPrice: Array<Scalars['smallint']['output']>;
  id: Scalars['uuid']['output'];
  stopCheck: Scalars['smallint']['output'];
  /** An object relationship */
  ziferblat: ZiferblatZiferblat;
  ziferblatId: Scalars['uuid']['output'];
};


/** columns and relationships of "tariff.tariff" */
export type TariffTariffAccessArgs = {
  distinctOn?: InputMaybe<Array<AccessAccessSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AccessAccessOrderBy>>;
  where?: InputMaybe<AccessAccessBoolExp>;
};


/** columns and relationships of "tariff.tariff" */
export type TariffTariffAccessAggregateArgs = {
  distinctOn?: InputMaybe<Array<AccessAccessSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AccessAccessOrderBy>>;
  where?: InputMaybe<AccessAccessBoolExp>;
};

/** aggregated selection of "tariff.tariff" */
export type TariffTariffAggregate = {
  __typename?: 'TariffTariffAggregate';
  aggregate?: Maybe<TariffTariffAggregateFields>;
  nodes: Array<TariffTariff>;
};

/** aggregate fields of "tariff.tariff" */
export type TariffTariffAggregateFields = {
  __typename?: 'TariffTariffAggregateFields';
  avg?: Maybe<TariffTariffAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<TariffTariffMaxFields>;
  min?: Maybe<TariffTariffMinFields>;
  stddev?: Maybe<TariffTariffStddevFields>;
  stddevPop?: Maybe<TariffTariffStddevPopFields>;
  stddevSamp?: Maybe<TariffTariffStddevSampFields>;
  sum?: Maybe<TariffTariffSumFields>;
  varPop?: Maybe<TariffTariffVarPopFields>;
  varSamp?: Maybe<TariffTariffVarSampFields>;
  variance?: Maybe<TariffTariffVarianceFields>;
};


/** aggregate fields of "tariff.tariff" */
export type TariffTariffAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<TariffTariffSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type TariffTariffAvgFields = {
  __typename?: 'TariffTariffAvgFields';
  stopCheck?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "tariff.tariff". All fields are combined with a logical 'AND'. */
export type TariffTariffBoolExp = {
  _and?: InputMaybe<Array<TariffTariffBoolExp>>;
  _not?: InputMaybe<TariffTariffBoolExp>;
  _or?: InputMaybe<Array<TariffTariffBoolExp>>;
  access?: InputMaybe<AccessAccessBoolExp>;
  accessAggregate?: InputMaybe<AccessAccessAggregateBoolExp>;
  activeFrom?: InputMaybe<TimestamptzComparisonExp>;
  activeTo?: InputMaybe<TimestamptzComparisonExp>;
  addedBy?: InputMaybe<AuthUserBoolExp>;
  addedById?: InputMaybe<UuidComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  hourPrice?: InputMaybe<SmallintArrayComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  stopCheck?: InputMaybe<SmallintComparisonExp>;
  ziferblat?: InputMaybe<ZiferblatZiferblatBoolExp>;
  ziferblatId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "tariff.tariff" */
export enum TariffTariffConstraint {
  /** unique or primary key constraint on columns "id" */
  TariffPkey = 'tariff_pkey'
}

/** input type for incrementing numeric columns in table "tariff.tariff" */
export type TariffTariffIncInput = {
  stopCheck?: InputMaybe<Scalars['smallint']['input']>;
};

/** input type for inserting data into table "tariff.tariff" */
export type TariffTariffInsertInput = {
  access?: InputMaybe<AccessAccessArrRelInsertInput>;
  activeFrom?: InputMaybe<Scalars['timestamptz']['input']>;
  addedBy?: InputMaybe<AuthUserObjRelInsertInput>;
  addedById?: InputMaybe<Scalars['uuid']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  hourPrice?: InputMaybe<Array<Scalars['smallint']['input']>>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  stopCheck?: InputMaybe<Scalars['smallint']['input']>;
  ziferblat?: InputMaybe<ZiferblatZiferblatObjRelInsertInput>;
  ziferblatId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type TariffTariffMaxFields = {
  __typename?: 'TariffTariffMaxFields';
  activeFrom?: Maybe<Scalars['timestamptz']['output']>;
  /** A computed field, executes function "tariff.get_active_to" */
  activeTo?: Maybe<Scalars['timestamptz']['output']>;
  addedById?: Maybe<Scalars['uuid']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  hourPrice?: Maybe<Array<Scalars['smallint']['output']>>;
  id?: Maybe<Scalars['uuid']['output']>;
  stopCheck?: Maybe<Scalars['smallint']['output']>;
  ziferblatId?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type TariffTariffMinFields = {
  __typename?: 'TariffTariffMinFields';
  activeFrom?: Maybe<Scalars['timestamptz']['output']>;
  /** A computed field, executes function "tariff.get_active_to" */
  activeTo?: Maybe<Scalars['timestamptz']['output']>;
  addedById?: Maybe<Scalars['uuid']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  hourPrice?: Maybe<Array<Scalars['smallint']['output']>>;
  id?: Maybe<Scalars['uuid']['output']>;
  stopCheck?: Maybe<Scalars['smallint']['output']>;
  ziferblatId?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "tariff.tariff" */
export type TariffTariffMutationResponse = {
  __typename?: 'TariffTariffMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<TariffTariff>;
};

/** on_conflict condition type for table "tariff.tariff" */
export type TariffTariffOnConflict = {
  constraint: TariffTariffConstraint;
  updateColumns?: Array<TariffTariffUpdateColumn>;
  where?: InputMaybe<TariffTariffBoolExp>;
};

/** Ordering options when selecting data from "tariff.tariff". */
export type TariffTariffOrderBy = {
  accessAggregate?: InputMaybe<AccessAccessAggregateOrderBy>;
  activeFrom?: InputMaybe<OrderBy>;
  activeTo?: InputMaybe<OrderBy>;
  addedBy?: InputMaybe<AuthUserOrderBy>;
  addedById?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  hourPrice?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  stopCheck?: InputMaybe<OrderBy>;
  ziferblat?: InputMaybe<ZiferblatZiferblatOrderBy>;
  ziferblatId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: tariff.tariff */
export type TariffTariffPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "tariff.tariff" */
export enum TariffTariffSelectColumn {
  /** column name */
  ActiveFrom = 'activeFrom',
  /** column name */
  AddedById = 'addedById',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  HourPrice = 'hourPrice',
  /** column name */
  Id = 'id',
  /** column name */
  StopCheck = 'stopCheck',
  /** column name */
  ZiferblatId = 'ziferblatId'
}

/** input type for updating data in table "tariff.tariff" */
export type TariffTariffSetInput = {
  activeFrom?: InputMaybe<Scalars['timestamptz']['input']>;
  addedById?: InputMaybe<Scalars['uuid']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  hourPrice?: InputMaybe<Array<Scalars['smallint']['input']>>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  stopCheck?: InputMaybe<Scalars['smallint']['input']>;
  ziferblatId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate stddev on columns */
export type TariffTariffStddevFields = {
  __typename?: 'TariffTariffStddevFields';
  stopCheck?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddevPop on columns */
export type TariffTariffStddevPopFields = {
  __typename?: 'TariffTariffStddevPopFields';
  stopCheck?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddevSamp on columns */
export type TariffTariffStddevSampFields = {
  __typename?: 'TariffTariffStddevSampFields';
  stopCheck?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "tariff_tariff" */
export type TariffTariffStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: TariffTariffStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type TariffTariffStreamCursorValueInput = {
  activeFrom?: InputMaybe<Scalars['timestamptz']['input']>;
  addedById?: InputMaybe<Scalars['uuid']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  hourPrice?: InputMaybe<Array<Scalars['smallint']['input']>>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  stopCheck?: InputMaybe<Scalars['smallint']['input']>;
  ziferblatId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate sum on columns */
export type TariffTariffSumFields = {
  __typename?: 'TariffTariffSumFields';
  stopCheck?: Maybe<Scalars['smallint']['output']>;
};

/** update columns of table "tariff.tariff" */
export enum TariffTariffUpdateColumn {
  /** column name */
  ActiveFrom = 'activeFrom',
  /** column name */
  AddedById = 'addedById',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  HourPrice = 'hourPrice',
  /** column name */
  Id = 'id',
  /** column name */
  StopCheck = 'stopCheck',
  /** column name */
  ZiferblatId = 'ziferblatId'
}

export type TariffTariffUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<TariffTariffIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TariffTariffSetInput>;
  /** filter the rows which have to be updated */
  where: TariffTariffBoolExp;
};

/** aggregate varPop on columns */
export type TariffTariffVarPopFields = {
  __typename?: 'TariffTariffVarPopFields';
  stopCheck?: Maybe<Scalars['Float']['output']>;
};

/** aggregate varSamp on columns */
export type TariffTariffVarSampFields = {
  __typename?: 'TariffTariffVarSampFields';
  stopCheck?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type TariffTariffVarianceFields = {
  __typename?: 'TariffTariffVarianceFields';
  stopCheck?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type TimestamptzComparisonExp = {
  _eq?: InputMaybe<Scalars['timestamptz']['input']>;
  _gt?: InputMaybe<Scalars['timestamptz']['input']>;
  _gte?: InputMaybe<Scalars['timestamptz']['input']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
  _isNull?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['timestamptz']['input']>;
  _lte?: InputMaybe<Scalars['timestamptz']['input']>;
  _neq?: InputMaybe<Scalars['timestamptz']['input']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type UuidComparisonExp = {
  _eq?: InputMaybe<Scalars['uuid']['input']>;
  _gt?: InputMaybe<Scalars['uuid']['input']>;
  _gte?: InputMaybe<Scalars['uuid']['input']>;
  _in?: InputMaybe<Array<Scalars['uuid']['input']>>;
  _isNull?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['uuid']['input']>;
  _lte?: InputMaybe<Scalars['uuid']['input']>;
  _neq?: InputMaybe<Scalars['uuid']['input']>;
  _nin?: InputMaybe<Array<Scalars['uuid']['input']>>;
};

/** columns and relationships of "visit" */
export type Visit = {
  __typename?: 'Visit';
  /** An array relationship */
  access: Array<AccessAccess>;
  /** An aggregate relationship */
  accessAggregate: AccessAccessAggregate;
  checkedInAt: Scalars['timestamptz']['output'];
  checkedInById: Scalars['uuid']['output'];
  checkedOutAt: Scalars['timestamptz']['output'];
  checkedOutById: Scalars['uuid']['output'];
  id: Scalars['uuid']['output'];
  name?: Maybe<Scalars['String']['output']>;
  sum: Scalars['Int']['output'];
  /** An object relationship */
  user?: Maybe<AuthUser>;
  userId?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  ziferblat: ZiferblatZiferblat;
  ziferblatId: Scalars['uuid']['output'];
};


/** columns and relationships of "visit" */
export type VisitAccessArgs = {
  distinctOn?: InputMaybe<Array<AccessAccessSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AccessAccessOrderBy>>;
  where?: InputMaybe<AccessAccessBoolExp>;
};


/** columns and relationships of "visit" */
export type VisitAccessAggregateArgs = {
  distinctOn?: InputMaybe<Array<AccessAccessSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AccessAccessOrderBy>>;
  where?: InputMaybe<AccessAccessBoolExp>;
};

/** aggregated selection of "visit" */
export type VisitAggregate = {
  __typename?: 'VisitAggregate';
  aggregate?: Maybe<VisitAggregateFields>;
  nodes: Array<Visit>;
};

/** aggregate fields of "visit" */
export type VisitAggregateFields = {
  __typename?: 'VisitAggregateFields';
  avg?: Maybe<VisitAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<VisitMaxFields>;
  min?: Maybe<VisitMinFields>;
  stddev?: Maybe<VisitStddevFields>;
  stddevPop?: Maybe<VisitStddevPopFields>;
  stddevSamp?: Maybe<VisitStddevSampFields>;
  sum?: Maybe<VisitSumFields>;
  varPop?: Maybe<VisitVarPopFields>;
  varSamp?: Maybe<VisitVarSampFields>;
  variance?: Maybe<VisitVarianceFields>;
};


/** aggregate fields of "visit" */
export type VisitAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<VisitSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type VisitAvgFields = {
  __typename?: 'VisitAvgFields';
  sum?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "visit". All fields are combined with a logical 'AND'. */
export type VisitBoolExp = {
  _and?: InputMaybe<Array<VisitBoolExp>>;
  _not?: InputMaybe<VisitBoolExp>;
  _or?: InputMaybe<Array<VisitBoolExp>>;
  access?: InputMaybe<AccessAccessBoolExp>;
  accessAggregate?: InputMaybe<AccessAccessAggregateBoolExp>;
  checkedInAt?: InputMaybe<TimestamptzComparisonExp>;
  checkedInById?: InputMaybe<UuidComparisonExp>;
  checkedOutAt?: InputMaybe<TimestamptzComparisonExp>;
  checkedOutById?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  sum?: InputMaybe<IntComparisonExp>;
  user?: InputMaybe<AuthUserBoolExp>;
  userId?: InputMaybe<UuidComparisonExp>;
  ziferblat?: InputMaybe<ZiferblatZiferblatBoolExp>;
  ziferblatId?: InputMaybe<UuidComparisonExp>;
};

/** columns and relationships of "visit.checkin" */
export type VisitCheckin = {
  __typename?: 'VisitCheckin';
  /** An array relationship */
  access: Array<AccessAccess>;
  /** An aggregate relationship */
  accessAggregate: AccessAccessAggregate;
  checkedInAt: Scalars['timestamptz']['output'];
  /** An object relationship */
  checkedInBy: AuthUser;
  checkedInById: Scalars['uuid']['output'];
  id: Scalars['uuid']['output'];
  userId?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  ziferblat: ZiferblatZiferblat;
  ziferblatId: Scalars['uuid']['output'];
};


/** columns and relationships of "visit.checkin" */
export type VisitCheckinAccessArgs = {
  distinctOn?: InputMaybe<Array<AccessAccessSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AccessAccessOrderBy>>;
  where?: InputMaybe<AccessAccessBoolExp>;
};


/** columns and relationships of "visit.checkin" */
export type VisitCheckinAccessAggregateArgs = {
  distinctOn?: InputMaybe<Array<AccessAccessSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AccessAccessOrderBy>>;
  where?: InputMaybe<AccessAccessBoolExp>;
};

/** aggregated selection of "visit.checkin" */
export type VisitCheckinAggregate = {
  __typename?: 'VisitCheckinAggregate';
  aggregate?: Maybe<VisitCheckinAggregateFields>;
  nodes: Array<VisitCheckin>;
};

/** aggregate fields of "visit.checkin" */
export type VisitCheckinAggregateFields = {
  __typename?: 'VisitCheckinAggregateFields';
  count: Scalars['Int']['output'];
  max?: Maybe<VisitCheckinMaxFields>;
  min?: Maybe<VisitCheckinMinFields>;
};


/** aggregate fields of "visit.checkin" */
export type VisitCheckinAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<VisitCheckinSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "visit.checkin". All fields are combined with a logical 'AND'. */
export type VisitCheckinBoolExp = {
  _and?: InputMaybe<Array<VisitCheckinBoolExp>>;
  _not?: InputMaybe<VisitCheckinBoolExp>;
  _or?: InputMaybe<Array<VisitCheckinBoolExp>>;
  access?: InputMaybe<AccessAccessBoolExp>;
  accessAggregate?: InputMaybe<AccessAccessAggregateBoolExp>;
  checkedInAt?: InputMaybe<TimestamptzComparisonExp>;
  checkedInBy?: InputMaybe<AuthUserBoolExp>;
  checkedInById?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  userId?: InputMaybe<UuidComparisonExp>;
  ziferblat?: InputMaybe<ZiferblatZiferblatBoolExp>;
  ziferblatId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "visit.checkin" */
export enum VisitCheckinConstraint {
  /** unique or primary key constraint on columns "id" */
  CheckinPkey = 'checkin_pkey'
}

/** input type for inserting data into table "visit.checkin" */
export type VisitCheckinInsertInput = {
  access?: InputMaybe<AccessAccessArrRelInsertInput>;
  checkedInAt?: InputMaybe<Scalars['timestamptz']['input']>;
  checkedInBy?: InputMaybe<AuthUserObjRelInsertInput>;
  checkedInById?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
  ziferblat?: InputMaybe<ZiferblatZiferblatObjRelInsertInput>;
  ziferblatId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type VisitCheckinMaxFields = {
  __typename?: 'VisitCheckinMaxFields';
  checkedInAt?: Maybe<Scalars['timestamptz']['output']>;
  checkedInById?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  userId?: Maybe<Scalars['uuid']['output']>;
  ziferblatId?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type VisitCheckinMinFields = {
  __typename?: 'VisitCheckinMinFields';
  checkedInAt?: Maybe<Scalars['timestamptz']['output']>;
  checkedInById?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  userId?: Maybe<Scalars['uuid']['output']>;
  ziferblatId?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "visit.checkin" */
export type VisitCheckinMutationResponse = {
  __typename?: 'VisitCheckinMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<VisitCheckin>;
};

/** input type for inserting object relation for remote table "visit.checkin" */
export type VisitCheckinObjRelInsertInput = {
  data: VisitCheckinInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<VisitCheckinOnConflict>;
};

/** on_conflict condition type for table "visit.checkin" */
export type VisitCheckinOnConflict = {
  constraint: VisitCheckinConstraint;
  updateColumns?: Array<VisitCheckinUpdateColumn>;
  where?: InputMaybe<VisitCheckinBoolExp>;
};

/** Ordering options when selecting data from "visit.checkin". */
export type VisitCheckinOrderBy = {
  accessAggregate?: InputMaybe<AccessAccessAggregateOrderBy>;
  checkedInAt?: InputMaybe<OrderBy>;
  checkedInBy?: InputMaybe<AuthUserOrderBy>;
  checkedInById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
  ziferblat?: InputMaybe<ZiferblatZiferblatOrderBy>;
  ziferblatId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: visit.checkin */
export type VisitCheckinPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "visit.checkin" */
export enum VisitCheckinSelectColumn {
  /** column name */
  CheckedInAt = 'checkedInAt',
  /** column name */
  CheckedInById = 'checkedInById',
  /** column name */
  Id = 'id',
  /** column name */
  UserId = 'userId',
  /** column name */
  ZiferblatId = 'ziferblatId'
}

/** input type for updating data in table "visit.checkin" */
export type VisitCheckinSetInput = {
  checkedInAt?: InputMaybe<Scalars['timestamptz']['input']>;
  checkedInById?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
  ziferblatId?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "visit_checkin" */
export type VisitCheckinStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: VisitCheckinStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type VisitCheckinStreamCursorValueInput = {
  checkedInAt?: InputMaybe<Scalars['timestamptz']['input']>;
  checkedInById?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
  ziferblatId?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "visit.checkin" */
export enum VisitCheckinUpdateColumn {
  /** column name */
  CheckedInAt = 'checkedInAt',
  /** column name */
  CheckedInById = 'checkedInById',
  /** column name */
  Id = 'id',
  /** column name */
  UserId = 'userId',
  /** column name */
  ZiferblatId = 'ziferblatId'
}

export type VisitCheckinUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<VisitCheckinSetInput>;
  /** filter the rows which have to be updated */
  where: VisitCheckinBoolExp;
};

/** unique or primary key constraints on table "visit" */
export enum VisitConstraint {
  /** unique or primary key constraint on columns "id" */
  VisitPkey = 'visit_pkey'
}

/** input type for incrementing numeric columns in table "visit" */
export type VisitIncInput = {
  sum?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "visit" */
export type VisitInsertInput = {
  access?: InputMaybe<AccessAccessArrRelInsertInput>;
  checkedInAt?: InputMaybe<Scalars['timestamptz']['input']>;
  checkedInById?: InputMaybe<Scalars['uuid']['input']>;
  checkedOutAt?: InputMaybe<Scalars['timestamptz']['input']>;
  checkedOutById?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  sum?: InputMaybe<Scalars['Int']['input']>;
  user?: InputMaybe<AuthUserObjRelInsertInput>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
  ziferblat?: InputMaybe<ZiferblatZiferblatObjRelInsertInput>;
  ziferblatId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type VisitMaxFields = {
  __typename?: 'VisitMaxFields';
  checkedInAt?: Maybe<Scalars['timestamptz']['output']>;
  checkedInById?: Maybe<Scalars['uuid']['output']>;
  checkedOutAt?: Maybe<Scalars['timestamptz']['output']>;
  checkedOutById?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  sum?: Maybe<Scalars['Int']['output']>;
  userId?: Maybe<Scalars['uuid']['output']>;
  ziferblatId?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type VisitMinFields = {
  __typename?: 'VisitMinFields';
  checkedInAt?: Maybe<Scalars['timestamptz']['output']>;
  checkedInById?: Maybe<Scalars['uuid']['output']>;
  checkedOutAt?: Maybe<Scalars['timestamptz']['output']>;
  checkedOutById?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  sum?: Maybe<Scalars['Int']['output']>;
  userId?: Maybe<Scalars['uuid']['output']>;
  ziferblatId?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "visit" */
export type VisitMutationResponse = {
  __typename?: 'VisitMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Visit>;
};

/** columns and relationships of "visit.name" */
export type VisitName = {
  __typename?: 'VisitName';
  /** An object relationship */
  checkin?: Maybe<VisitCheckin>;
  id: Scalars['uuid']['output'];
  inputAt: Scalars['timestamptz']['output'];
  /** An object relationship */
  inputBy: AuthUser;
  inputById: Scalars['uuid']['output'];
  value: Scalars['String']['output'];
  visitId: Scalars['uuid']['output'];
};

/** aggregated selection of "visit.name" */
export type VisitNameAggregate = {
  __typename?: 'VisitNameAggregate';
  aggregate?: Maybe<VisitNameAggregateFields>;
  nodes: Array<VisitName>;
};

/** aggregate fields of "visit.name" */
export type VisitNameAggregateFields = {
  __typename?: 'VisitNameAggregateFields';
  count: Scalars['Int']['output'];
  max?: Maybe<VisitNameMaxFields>;
  min?: Maybe<VisitNameMinFields>;
};


/** aggregate fields of "visit.name" */
export type VisitNameAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<VisitNameSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "visit.name". All fields are combined with a logical 'AND'. */
export type VisitNameBoolExp = {
  _and?: InputMaybe<Array<VisitNameBoolExp>>;
  _not?: InputMaybe<VisitNameBoolExp>;
  _or?: InputMaybe<Array<VisitNameBoolExp>>;
  checkin?: InputMaybe<VisitCheckinBoolExp>;
  id?: InputMaybe<UuidComparisonExp>;
  inputAt?: InputMaybe<TimestamptzComparisonExp>;
  inputBy?: InputMaybe<AuthUserBoolExp>;
  inputById?: InputMaybe<UuidComparisonExp>;
  value?: InputMaybe<StringComparisonExp>;
  visitId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "visit.name" */
export enum VisitNameConstraint {
  /** unique or primary key constraint on columns "id" */
  NamePkey = 'name_pkey'
}

/** input type for inserting data into table "visit.name" */
export type VisitNameInsertInput = {
  checkin?: InputMaybe<VisitCheckinObjRelInsertInput>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  inputAt?: InputMaybe<Scalars['timestamptz']['input']>;
  inputBy?: InputMaybe<AuthUserObjRelInsertInput>;
  inputById?: InputMaybe<Scalars['uuid']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
  visitId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type VisitNameMaxFields = {
  __typename?: 'VisitNameMaxFields';
  id?: Maybe<Scalars['uuid']['output']>;
  inputAt?: Maybe<Scalars['timestamptz']['output']>;
  inputById?: Maybe<Scalars['uuid']['output']>;
  value?: Maybe<Scalars['String']['output']>;
  visitId?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type VisitNameMinFields = {
  __typename?: 'VisitNameMinFields';
  id?: Maybe<Scalars['uuid']['output']>;
  inputAt?: Maybe<Scalars['timestamptz']['output']>;
  inputById?: Maybe<Scalars['uuid']['output']>;
  value?: Maybe<Scalars['String']['output']>;
  visitId?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "visit.name" */
export type VisitNameMutationResponse = {
  __typename?: 'VisitNameMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<VisitName>;
};

/** on_conflict condition type for table "visit.name" */
export type VisitNameOnConflict = {
  constraint: VisitNameConstraint;
  updateColumns?: Array<VisitNameUpdateColumn>;
  where?: InputMaybe<VisitNameBoolExp>;
};

/** Ordering options when selecting data from "visit.name". */
export type VisitNameOrderBy = {
  checkin?: InputMaybe<VisitCheckinOrderBy>;
  id?: InputMaybe<OrderBy>;
  inputAt?: InputMaybe<OrderBy>;
  inputBy?: InputMaybe<AuthUserOrderBy>;
  inputById?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: visit.name */
export type VisitNamePkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "visit.name" */
export enum VisitNameSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  InputAt = 'inputAt',
  /** column name */
  InputById = 'inputById',
  /** column name */
  Value = 'value',
  /** column name */
  VisitId = 'visitId'
}

/** input type for updating data in table "visit.name" */
export type VisitNameSetInput = {
  id?: InputMaybe<Scalars['uuid']['input']>;
  inputAt?: InputMaybe<Scalars['timestamptz']['input']>;
  inputById?: InputMaybe<Scalars['uuid']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
  visitId?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "visit_name" */
export type VisitNameStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: VisitNameStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type VisitNameStreamCursorValueInput = {
  id?: InputMaybe<Scalars['uuid']['input']>;
  inputAt?: InputMaybe<Scalars['timestamptz']['input']>;
  inputById?: InputMaybe<Scalars['uuid']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
  visitId?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "visit.name" */
export enum VisitNameUpdateColumn {
  /** column name */
  Id = 'id',
  /** column name */
  InputAt = 'inputAt',
  /** column name */
  InputById = 'inputById',
  /** column name */
  Value = 'value',
  /** column name */
  VisitId = 'visitId'
}

export type VisitNameUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<VisitNameSetInput>;
  /** filter the rows which have to be updated */
  where: VisitNameBoolExp;
};

/** input type for inserting object relation for remote table "visit" */
export type VisitObjRelInsertInput = {
  data: VisitInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<VisitOnConflict>;
};

/** on_conflict condition type for table "visit" */
export type VisitOnConflict = {
  constraint: VisitConstraint;
  updateColumns?: Array<VisitUpdateColumn>;
  where?: InputMaybe<VisitBoolExp>;
};

/** Ordering options when selecting data from "visit". */
export type VisitOrderBy = {
  accessAggregate?: InputMaybe<AccessAccessAggregateOrderBy>;
  checkedInAt?: InputMaybe<OrderBy>;
  checkedInById?: InputMaybe<OrderBy>;
  checkedOutAt?: InputMaybe<OrderBy>;
  checkedOutById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  sum?: InputMaybe<OrderBy>;
  user?: InputMaybe<AuthUserOrderBy>;
  userId?: InputMaybe<OrderBy>;
  ziferblat?: InputMaybe<ZiferblatZiferblatOrderBy>;
  ziferblatId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: visit */
export type VisitPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "visit" */
export enum VisitSelectColumn {
  /** column name */
  CheckedInAt = 'checkedInAt',
  /** column name */
  CheckedInById = 'checkedInById',
  /** column name */
  CheckedOutAt = 'checkedOutAt',
  /** column name */
  CheckedOutById = 'checkedOutById',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Sum = 'sum',
  /** column name */
  UserId = 'userId',
  /** column name */
  ZiferblatId = 'ziferblatId'
}

/** input type for updating data in table "visit" */
export type VisitSetInput = {
  checkedInAt?: InputMaybe<Scalars['timestamptz']['input']>;
  checkedInById?: InputMaybe<Scalars['uuid']['input']>;
  checkedOutAt?: InputMaybe<Scalars['timestamptz']['input']>;
  checkedOutById?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  sum?: InputMaybe<Scalars['Int']['input']>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
  ziferblatId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate stddev on columns */
export type VisitStddevFields = {
  __typename?: 'VisitStddevFields';
  sum?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddevPop on columns */
export type VisitStddevPopFields = {
  __typename?: 'VisitStddevPopFields';
  sum?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddevSamp on columns */
export type VisitStddevSampFields = {
  __typename?: 'VisitStddevSampFields';
  sum?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "visit" */
export type VisitStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: VisitStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type VisitStreamCursorValueInput = {
  checkedInAt?: InputMaybe<Scalars['timestamptz']['input']>;
  checkedInById?: InputMaybe<Scalars['uuid']['input']>;
  checkedOutAt?: InputMaybe<Scalars['timestamptz']['input']>;
  checkedOutById?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  sum?: InputMaybe<Scalars['Int']['input']>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
  ziferblatId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate sum on columns */
export type VisitSumFields = {
  __typename?: 'VisitSumFields';
  sum?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "visit" */
export enum VisitUpdateColumn {
  /** column name */
  CheckedInAt = 'checkedInAt',
  /** column name */
  CheckedInById = 'checkedInById',
  /** column name */
  CheckedOutAt = 'checkedOutAt',
  /** column name */
  CheckedOutById = 'checkedOutById',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Sum = 'sum',
  /** column name */
  UserId = 'userId',
  /** column name */
  ZiferblatId = 'ziferblatId'
}

export type VisitUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<VisitIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<VisitSetInput>;
  /** filter the rows which have to be updated */
  where: VisitBoolExp;
};

/** aggregate varPop on columns */
export type VisitVarPopFields = {
  __typename?: 'VisitVarPopFields';
  sum?: Maybe<Scalars['Float']['output']>;
};

/** aggregate varSamp on columns */
export type VisitVarSampFields = {
  __typename?: 'VisitVarSampFields';
  sum?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type VisitVarianceFields = {
  __typename?: 'VisitVarianceFields';
  sum?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "visit.visit" */
export type VisitVisit = {
  __typename?: 'VisitVisit';
  checkedInAt: Scalars['timestamptz']['output'];
  checkedInById: Scalars['uuid']['output'];
  checkedOutAt: Scalars['timestamptz']['output'];
  checkedOutById: Scalars['uuid']['output'];
  id: Scalars['uuid']['output'];
  name?: Maybe<Scalars['String']['output']>;
  sum: Scalars['Int']['output'];
  userId?: Maybe<Scalars['uuid']['output']>;
  ziferblatId: Scalars['uuid']['output'];
};

/** aggregated selection of "visit.visit" */
export type VisitVisitAggregate = {
  __typename?: 'VisitVisitAggregate';
  aggregate?: Maybe<VisitVisitAggregateFields>;
  nodes: Array<VisitVisit>;
};

/** aggregate fields of "visit.visit" */
export type VisitVisitAggregateFields = {
  __typename?: 'VisitVisitAggregateFields';
  avg?: Maybe<VisitVisitAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<VisitVisitMaxFields>;
  min?: Maybe<VisitVisitMinFields>;
  stddev?: Maybe<VisitVisitStddevFields>;
  stddevPop?: Maybe<VisitVisitStddevPopFields>;
  stddevSamp?: Maybe<VisitVisitStddevSampFields>;
  sum?: Maybe<VisitVisitSumFields>;
  varPop?: Maybe<VisitVisitVarPopFields>;
  varSamp?: Maybe<VisitVisitVarSampFields>;
  variance?: Maybe<VisitVisitVarianceFields>;
};


/** aggregate fields of "visit.visit" */
export type VisitVisitAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<VisitVisitSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type VisitVisitAvgFields = {
  __typename?: 'VisitVisitAvgFields';
  sum?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "visit.visit". All fields are combined with a logical 'AND'. */
export type VisitVisitBoolExp = {
  _and?: InputMaybe<Array<VisitVisitBoolExp>>;
  _not?: InputMaybe<VisitVisitBoolExp>;
  _or?: InputMaybe<Array<VisitVisitBoolExp>>;
  checkedInAt?: InputMaybe<TimestamptzComparisonExp>;
  checkedInById?: InputMaybe<UuidComparisonExp>;
  checkedOutAt?: InputMaybe<TimestamptzComparisonExp>;
  checkedOutById?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  sum?: InputMaybe<IntComparisonExp>;
  userId?: InputMaybe<UuidComparisonExp>;
  ziferblatId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "visit.visit" */
export enum VisitVisitConstraint {
  /** unique or primary key constraint on columns "id" */
  VisitPkey = 'visit_pkey'
}

/** input type for incrementing numeric columns in table "visit.visit" */
export type VisitVisitIncInput = {
  sum?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "visit.visit" */
export type VisitVisitInsertInput = {
  checkedInAt?: InputMaybe<Scalars['timestamptz']['input']>;
  checkedInById?: InputMaybe<Scalars['uuid']['input']>;
  checkedOutAt?: InputMaybe<Scalars['timestamptz']['input']>;
  checkedOutById?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  sum?: InputMaybe<Scalars['Int']['input']>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
  ziferblatId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type VisitVisitMaxFields = {
  __typename?: 'VisitVisitMaxFields';
  checkedInAt?: Maybe<Scalars['timestamptz']['output']>;
  checkedInById?: Maybe<Scalars['uuid']['output']>;
  checkedOutAt?: Maybe<Scalars['timestamptz']['output']>;
  checkedOutById?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  sum?: Maybe<Scalars['Int']['output']>;
  userId?: Maybe<Scalars['uuid']['output']>;
  ziferblatId?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type VisitVisitMinFields = {
  __typename?: 'VisitVisitMinFields';
  checkedInAt?: Maybe<Scalars['timestamptz']['output']>;
  checkedInById?: Maybe<Scalars['uuid']['output']>;
  checkedOutAt?: Maybe<Scalars['timestamptz']['output']>;
  checkedOutById?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  sum?: Maybe<Scalars['Int']['output']>;
  userId?: Maybe<Scalars['uuid']['output']>;
  ziferblatId?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "visit.visit" */
export type VisitVisitMutationResponse = {
  __typename?: 'VisitVisitMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<VisitVisit>;
};

/** on_conflict condition type for table "visit.visit" */
export type VisitVisitOnConflict = {
  constraint: VisitVisitConstraint;
  updateColumns?: Array<VisitVisitUpdateColumn>;
  where?: InputMaybe<VisitVisitBoolExp>;
};

/** Ordering options when selecting data from "visit.visit". */
export type VisitVisitOrderBy = {
  checkedInAt?: InputMaybe<OrderBy>;
  checkedInById?: InputMaybe<OrderBy>;
  checkedOutAt?: InputMaybe<OrderBy>;
  checkedOutById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  sum?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
  ziferblatId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: visit.visit */
export type VisitVisitPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "visit.visit" */
export enum VisitVisitSelectColumn {
  /** column name */
  CheckedInAt = 'checkedInAt',
  /** column name */
  CheckedInById = 'checkedInById',
  /** column name */
  CheckedOutAt = 'checkedOutAt',
  /** column name */
  CheckedOutById = 'checkedOutById',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Sum = 'sum',
  /** column name */
  UserId = 'userId',
  /** column name */
  ZiferblatId = 'ziferblatId'
}

/** input type for updating data in table "visit.visit" */
export type VisitVisitSetInput = {
  checkedInAt?: InputMaybe<Scalars['timestamptz']['input']>;
  checkedInById?: InputMaybe<Scalars['uuid']['input']>;
  checkedOutAt?: InputMaybe<Scalars['timestamptz']['input']>;
  checkedOutById?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  sum?: InputMaybe<Scalars['Int']['input']>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
  ziferblatId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate stddev on columns */
export type VisitVisitStddevFields = {
  __typename?: 'VisitVisitStddevFields';
  sum?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddevPop on columns */
export type VisitVisitStddevPopFields = {
  __typename?: 'VisitVisitStddevPopFields';
  sum?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddevSamp on columns */
export type VisitVisitStddevSampFields = {
  __typename?: 'VisitVisitStddevSampFields';
  sum?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "visit_visit" */
export type VisitVisitStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: VisitVisitStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type VisitVisitStreamCursorValueInput = {
  checkedInAt?: InputMaybe<Scalars['timestamptz']['input']>;
  checkedInById?: InputMaybe<Scalars['uuid']['input']>;
  checkedOutAt?: InputMaybe<Scalars['timestamptz']['input']>;
  checkedOutById?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  sum?: InputMaybe<Scalars['Int']['input']>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
  ziferblatId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate sum on columns */
export type VisitVisitSumFields = {
  __typename?: 'VisitVisitSumFields';
  sum?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "visit.visit" */
export enum VisitVisitUpdateColumn {
  /** column name */
  CheckedInAt = 'checkedInAt',
  /** column name */
  CheckedInById = 'checkedInById',
  /** column name */
  CheckedOutAt = 'checkedOutAt',
  /** column name */
  CheckedOutById = 'checkedOutById',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Sum = 'sum',
  /** column name */
  UserId = 'userId',
  /** column name */
  ZiferblatId = 'ziferblatId'
}

export type VisitVisitUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<VisitVisitIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<VisitVisitSetInput>;
  /** filter the rows which have to be updated */
  where: VisitVisitBoolExp;
};

/** aggregate varPop on columns */
export type VisitVisitVarPopFields = {
  __typename?: 'VisitVisitVarPopFields';
  sum?: Maybe<Scalars['Float']['output']>;
};

/** aggregate varSamp on columns */
export type VisitVisitVarSampFields = {
  __typename?: 'VisitVisitVarSampFields';
  sum?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type VisitVisitVarianceFields = {
  __typename?: 'VisitVisitVarianceFields';
  sum?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "ziferblat.ziferblat" */
export type ZiferblatZiferblat = {
  __typename?: 'ZiferblatZiferblat';
  activeFrom: Scalars['timestamptz']['output'];
  avatarUrl: Scalars['String']['output'];
  id: Scalars['uuid']['output'];
  name: Scalars['String']['output'];
};

/** aggregated selection of "ziferblat.ziferblat" */
export type ZiferblatZiferblatAggregate = {
  __typename?: 'ZiferblatZiferblatAggregate';
  aggregate?: Maybe<ZiferblatZiferblatAggregateFields>;
  nodes: Array<ZiferblatZiferblat>;
};

/** aggregate fields of "ziferblat.ziferblat" */
export type ZiferblatZiferblatAggregateFields = {
  __typename?: 'ZiferblatZiferblatAggregateFields';
  count: Scalars['Int']['output'];
  max?: Maybe<ZiferblatZiferblatMaxFields>;
  min?: Maybe<ZiferblatZiferblatMinFields>;
};


/** aggregate fields of "ziferblat.ziferblat" */
export type ZiferblatZiferblatAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ZiferblatZiferblatSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "ziferblat.ziferblat". All fields are combined with a logical 'AND'. */
export type ZiferblatZiferblatBoolExp = {
  _and?: InputMaybe<Array<ZiferblatZiferblatBoolExp>>;
  _not?: InputMaybe<ZiferblatZiferblatBoolExp>;
  _or?: InputMaybe<Array<ZiferblatZiferblatBoolExp>>;
  activeFrom?: InputMaybe<TimestamptzComparisonExp>;
  avatarUrl?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "ziferblat.ziferblat" */
export enum ZiferblatZiferblatConstraint {
  /** unique or primary key constraint on columns "id" */
  ZiferblatPkey = 'ziferblat_pkey'
}

/** input type for inserting data into table "ziferblat.ziferblat" */
export type ZiferblatZiferblatInsertInput = {
  activeFrom?: InputMaybe<Scalars['timestamptz']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type ZiferblatZiferblatMaxFields = {
  __typename?: 'ZiferblatZiferblatMaxFields';
  activeFrom?: Maybe<Scalars['timestamptz']['output']>;
  avatarUrl?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type ZiferblatZiferblatMinFields = {
  __typename?: 'ZiferblatZiferblatMinFields';
  activeFrom?: Maybe<Scalars['timestamptz']['output']>;
  avatarUrl?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "ziferblat.ziferblat" */
export type ZiferblatZiferblatMutationResponse = {
  __typename?: 'ZiferblatZiferblatMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ZiferblatZiferblat>;
};

/** input type for inserting object relation for remote table "ziferblat.ziferblat" */
export type ZiferblatZiferblatObjRelInsertInput = {
  data: ZiferblatZiferblatInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<ZiferblatZiferblatOnConflict>;
};

/** on_conflict condition type for table "ziferblat.ziferblat" */
export type ZiferblatZiferblatOnConflict = {
  constraint: ZiferblatZiferblatConstraint;
  updateColumns?: Array<ZiferblatZiferblatUpdateColumn>;
  where?: InputMaybe<ZiferblatZiferblatBoolExp>;
};

/** Ordering options when selecting data from "ziferblat.ziferblat". */
export type ZiferblatZiferblatOrderBy = {
  activeFrom?: InputMaybe<OrderBy>;
  avatarUrl?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: ziferblat.ziferblat */
export type ZiferblatZiferblatPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "ziferblat.ziferblat" */
export enum ZiferblatZiferblatSelectColumn {
  /** column name */
  ActiveFrom = 'activeFrom',
  /** column name */
  AvatarUrl = 'avatarUrl',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "ziferblat.ziferblat" */
export type ZiferblatZiferblatSetInput = {
  activeFrom?: InputMaybe<Scalars['timestamptz']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "ziferblat_ziferblat" */
export type ZiferblatZiferblatStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ZiferblatZiferblatStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ZiferblatZiferblatStreamCursorValueInput = {
  activeFrom?: InputMaybe<Scalars['timestamptz']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "ziferblat.ziferblat" */
export enum ZiferblatZiferblatUpdateColumn {
  /** column name */
  ActiveFrom = 'activeFrom',
  /** column name */
  AvatarUrl = 'avatarUrl',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

export type ZiferblatZiferblatUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ZiferblatZiferblatSetInput>;
  /** filter the rows which have to be updated */
  where: ZiferblatZiferblatBoolExp;
};

export type AccessAccessAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<AccessAccessSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<AccessAccessBoolExp>;
  predicate: IntComparisonExp;
};

export type AccessExpireAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<AccessExpireSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<AccessExpireBoolExp>;
  predicate: IntComparisonExp;
};

export type AccessGrantAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<AccessGrantSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<AccessGrantBoolExp>;
  predicate: IntComparisonExp;
};

/** columns and relationships of "cultural_event.attendees" */
export type Attendees = {
  __typename?: 'attendees';
  /** An object relationship */
  culturalEvent?: Maybe<CulturalEvent>;
  culturalEventId?: Maybe<Scalars['uuid']['output']>;
  endedAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  startedAt?: Maybe<Scalars['timestamptz']['output']>;
  sum?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  visit?: Maybe<Visit>;
  visitId?: Maybe<Scalars['uuid']['output']>;
};

/** aggregated selection of "cultural_event.attendees" */
export type AttendeesAggregate = {
  __typename?: 'attendeesAggregate';
  aggregate?: Maybe<AttendeesAggregateFields>;
  nodes: Array<Attendees>;
};

export type AttendeesAggregateBoolExp = {
  count?: InputMaybe<AttendeesAggregateBoolExpCount>;
};

export type AttendeesAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<AttendeesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<AttendeesBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "cultural_event.attendees" */
export type AttendeesAggregateFields = {
  __typename?: 'attendeesAggregateFields';
  avg?: Maybe<AttendeesAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<AttendeesMaxFields>;
  min?: Maybe<AttendeesMinFields>;
  stddev?: Maybe<AttendeesStddevFields>;
  stddevPop?: Maybe<AttendeesStddevPopFields>;
  stddevSamp?: Maybe<AttendeesStddevSampFields>;
  sum?: Maybe<AttendeesSumFields>;
  varPop?: Maybe<AttendeesVarPopFields>;
  varSamp?: Maybe<AttendeesVarSampFields>;
  variance?: Maybe<AttendeesVarianceFields>;
};


/** aggregate fields of "cultural_event.attendees" */
export type AttendeesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AttendeesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "cultural_event.attendees" */
export type AttendeesAggregateOrderBy = {
  avg?: InputMaybe<AttendeesAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<AttendeesMaxOrderBy>;
  min?: InputMaybe<AttendeesMinOrderBy>;
  stddev?: InputMaybe<AttendeesStddevOrderBy>;
  stddevPop?: InputMaybe<AttendeesStddevPopOrderBy>;
  stddevSamp?: InputMaybe<AttendeesStddevSampOrderBy>;
  sum?: InputMaybe<AttendeesSumOrderBy>;
  varPop?: InputMaybe<AttendeesVarPopOrderBy>;
  varSamp?: InputMaybe<AttendeesVarSampOrderBy>;
  variance?: InputMaybe<AttendeesVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "cultural_event.attendees" */
export type AttendeesArrRelInsertInput = {
  data: Array<AttendeesInsertInput>;
};

/** aggregate avg on columns */
export type AttendeesAvgFields = {
  __typename?: 'attendeesAvgFields';
  sum?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "cultural_event.attendees" */
export type AttendeesAvgOrderBy = {
  sum?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "cultural_event.attendees". All fields are combined with a logical 'AND'. */
export type AttendeesBoolExp = {
  _and?: InputMaybe<Array<AttendeesBoolExp>>;
  _not?: InputMaybe<AttendeesBoolExp>;
  _or?: InputMaybe<Array<AttendeesBoolExp>>;
  culturalEvent?: InputMaybe<CulturalEventBoolExp>;
  culturalEventId?: InputMaybe<UuidComparisonExp>;
  endedAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  startedAt?: InputMaybe<TimestamptzComparisonExp>;
  sum?: InputMaybe<IntComparisonExp>;
  visit?: InputMaybe<VisitBoolExp>;
  visitId?: InputMaybe<UuidComparisonExp>;
};

/** input type for inserting data into table "cultural_event.attendees" */
export type AttendeesInsertInput = {
  culturalEvent?: InputMaybe<CulturalEventObjRelInsertInput>;
  culturalEventId?: InputMaybe<Scalars['uuid']['input']>;
  endedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  startedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  sum?: InputMaybe<Scalars['Int']['input']>;
  visit?: InputMaybe<VisitObjRelInsertInput>;
  visitId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type AttendeesMaxFields = {
  __typename?: 'attendeesMaxFields';
  culturalEventId?: Maybe<Scalars['uuid']['output']>;
  endedAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  startedAt?: Maybe<Scalars['timestamptz']['output']>;
  sum?: Maybe<Scalars['Int']['output']>;
  visitId?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "cultural_event.attendees" */
export type AttendeesMaxOrderBy = {
  culturalEventId?: InputMaybe<OrderBy>;
  endedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  startedAt?: InputMaybe<OrderBy>;
  sum?: InputMaybe<OrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type AttendeesMinFields = {
  __typename?: 'attendeesMinFields';
  culturalEventId?: Maybe<Scalars['uuid']['output']>;
  endedAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  startedAt?: Maybe<Scalars['timestamptz']['output']>;
  sum?: Maybe<Scalars['Int']['output']>;
  visitId?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "cultural_event.attendees" */
export type AttendeesMinOrderBy = {
  culturalEventId?: InputMaybe<OrderBy>;
  endedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  startedAt?: InputMaybe<OrderBy>;
  sum?: InputMaybe<OrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "cultural_event.attendees". */
export type AttendeesOrderBy = {
  culturalEvent?: InputMaybe<CulturalEventOrderBy>;
  culturalEventId?: InputMaybe<OrderBy>;
  endedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  startedAt?: InputMaybe<OrderBy>;
  sum?: InputMaybe<OrderBy>;
  visit?: InputMaybe<VisitOrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** select columns of table "cultural_event.attendees" */
export enum AttendeesSelectColumn {
  /** column name */
  CulturalEventId = 'culturalEventId',
  /** column name */
  EndedAt = 'endedAt',
  /** column name */
  Id = 'id',
  /** column name */
  StartedAt = 'startedAt',
  /** column name */
  Sum = 'sum',
  /** column name */
  VisitId = 'visitId'
}

/** aggregate stddev on columns */
export type AttendeesStddevFields = {
  __typename?: 'attendeesStddevFields';
  sum?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "cultural_event.attendees" */
export type AttendeesStddevOrderBy = {
  sum?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type AttendeesStddevPopFields = {
  __typename?: 'attendeesStddevPopFields';
  sum?: Maybe<Scalars['Float']['output']>;
};

/** order by stddevPop() on columns of table "cultural_event.attendees" */
export type AttendeesStddevPopOrderBy = {
  sum?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type AttendeesStddevSampFields = {
  __typename?: 'attendeesStddevSampFields';
  sum?: Maybe<Scalars['Float']['output']>;
};

/** order by stddevSamp() on columns of table "cultural_event.attendees" */
export type AttendeesStddevSampOrderBy = {
  sum?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "attendees" */
export type AttendeesStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: AttendeesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AttendeesStreamCursorValueInput = {
  culturalEventId?: InputMaybe<Scalars['uuid']['input']>;
  endedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  startedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  sum?: InputMaybe<Scalars['Int']['input']>;
  visitId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate sum on columns */
export type AttendeesSumFields = {
  __typename?: 'attendeesSumFields';
  sum?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "cultural_event.attendees" */
export type AttendeesSumOrderBy = {
  sum?: InputMaybe<OrderBy>;
};

/** aggregate varPop on columns */
export type AttendeesVarPopFields = {
  __typename?: 'attendeesVarPopFields';
  sum?: Maybe<Scalars['Float']['output']>;
};

/** order by varPop() on columns of table "cultural_event.attendees" */
export type AttendeesVarPopOrderBy = {
  sum?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type AttendeesVarSampFields = {
  __typename?: 'attendeesVarSampFields';
  sum?: Maybe<Scalars['Float']['output']>;
};

/** order by varSamp() on columns of table "cultural_event.attendees" */
export type AttendeesVarSampOrderBy = {
  sum?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type AttendeesVarianceFields = {
  __typename?: 'attendeesVarianceFields';
  sum?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "cultural_event.attendees" */
export type AttendeesVarianceOrderBy = {
  sum?: InputMaybe<OrderBy>;
};

export type AuthAliasAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<AuthAliasSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<AuthAliasBoolExp>;
  predicate: IntComparisonExp;
};

export type CulturalEventAttendanceAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<CulturalEventAttendanceSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<CulturalEventAttendanceBoolExp>;
  predicate: IntComparisonExp;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  /** insert a single row into the table: "cultural_event.attendance" */
  addAttendance?: Maybe<CulturalEventAttendance>;
  /** insert a single row into the table: "checkin" */
  addCheckin?: Maybe<Checkin>;
  /** insert data into the table: "checkin" */
  addCheckins?: Maybe<CheckinMutationResponse>;
  /** insert a single row into the table: "cultural_event.cultural_event" */
  addCulturalEvent?: Maybe<CulturalEvent>;
  /** insert data into the table: "cultural_event.cultural_event" */
  addCulturalEvents?: Maybe<CulturalEventMutationResponse>;
  /** insert a single row into the table: "visit" */
  checkOut?: Maybe<Visit>;
  /** delete data from the table: "access.expire" */
  deleteAccessExpire?: Maybe<AccessExpireMutationResponse>;
  /** delete single row from the table: "access.expire" */
  deleteAccessExpireByPk?: Maybe<AccessExpire>;
  /** delete data from the table: "access.grant" */
  deleteAccessGrant?: Maybe<AccessGrantMutationResponse>;
  /** delete single row from the table: "access.grant" */
  deleteAccessGrantByPk?: Maybe<AccessGrant>;
  /** delete data from the table: "access.permission" */
  deleteAccessPermission?: Maybe<AccessPermissionMutationResponse>;
  /** delete single row from the table: "access.permission" */
  deleteAccessPermissionByPk?: Maybe<AccessPermission>;
  /** delete data from the table: "access.role" */
  deleteAccessRole?: Maybe<AccessRoleMutationResponse>;
  /** delete single row from the table: "access.role" */
  deleteAccessRoleByPk?: Maybe<AccessRole>;
  /** delete data from the table: "auth.alias" */
  deleteAuthAlias?: Maybe<AuthAliasMutationResponse>;
  /** delete single row from the table: "auth.alias" */
  deleteAuthAliasByPk?: Maybe<AuthAlias>;
  /** delete data from the table: "auth.logout" */
  deleteAuthLogout?: Maybe<AuthLogoutMutationResponse>;
  /** delete single row from the table: "auth.logout" */
  deleteAuthLogoutByPk?: Maybe<AuthLogout>;
  /** delete data from the table: "auth.session" */
  deleteAuthSession?: Maybe<AuthSessionMutationResponse>;
  /** delete single row from the table: "auth.session" */
  deleteAuthSessionByPk?: Maybe<AuthSession>;
  /** delete data from the table: "auth.telegram_bot" */
  deleteAuthTelegramBot?: Maybe<AuthTelegramBotMutationResponse>;
  /** delete single row from the table: "auth.telegram_bot" */
  deleteAuthTelegramBotByPk?: Maybe<AuthTelegramBot>;
  /** delete data from the table: "auth.telegram_input" */
  deleteAuthTelegramInput?: Maybe<AuthTelegramInputMutationResponse>;
  /** delete single row from the table: "auth.telegram_input" */
  deleteAuthTelegramInputByPk?: Maybe<AuthTelegramInput>;
  /** delete data from the table: "auth.user" */
  deleteAuthUser?: Maybe<AuthUserMutationResponse>;
  /** delete single row from the table: "auth.user" */
  deleteAuthUserByPk?: Maybe<AuthUser>;
  /** delete data from the table: "checkin" */
  deleteCheckin?: Maybe<CheckinMutationResponse>;
  /** delete single row from the table: "checkin" */
  deleteCheckinByPk?: Maybe<Checkin>;
  /** delete data from the table: "cultural_event.compensation_currency" */
  deleteCompensationCurrency?: Maybe<CompensationCurrencyMutationResponse>;
  /** delete single row from the table: "cultural_event.compensation_currency" */
  deleteCompensationCurrencyByPk?: Maybe<CompensationCurrency>;
  /** delete data from the table: "cultural_event.compensation_unit" */
  deleteCompensationUnit?: Maybe<CompensationUnitMutationResponse>;
  /** delete single row from the table: "cultural_event.compensation_unit" */
  deleteCompensationUnitByPk?: Maybe<CompensationUnit>;
  /** delete data from the table: "cultural_event.cultural_event" */
  deleteCulturalEvent?: Maybe<CulturalEventMutationResponse>;
  /** delete single row from the table: "cultural_event.attendance" */
  deleteCulturalEventAttendanceByPk?: Maybe<CulturalEventAttendance>;
  /** delete single row from the table: "cultural_event.cultural_event" */
  deleteCulturalEventByPk?: Maybe<CulturalEvent>;
  /** delete data from the table: "cultural_event.compensation" */
  deleteCulturalEventCompensation?: Maybe<CulturalEventCompensationMutationResponse>;
  /** delete single row from the table: "cultural_event.compensation" */
  deleteCulturalEventCompensationByPk?: Maybe<CulturalEventCompensation>;
  /** delete data from the table: "cultural_event.status" */
  deleteCulturalEventStatus?: Maybe<CulturalEventStatusMutationResponse>;
  /** delete single row from the table: "cultural_event.status" */
  deleteCulturalEventStatusByPk?: Maybe<CulturalEventStatus>;
  /** delete data from the table: "cultural_event.ticket" */
  deleteCulturalEventTicket?: Maybe<CulturalEventTicketMutationResponse>;
  /** delete single row from the table: "cultural_event.ticket" */
  deleteCulturalEventTicketByPk?: Maybe<CulturalEventTicket>;
  /** delete data from the table: "cultural_event.ticket_visit" */
  deleteCulturalEventTicketVisit?: Maybe<CulturalEventTicketVisitMutationResponse>;
  /** delete single row from the table: "cultural_event.ticket_visit" */
  deleteCulturalEventTicketVisitByPk?: Maybe<CulturalEventTicketVisit>;
  /** delete data from the table: "tariff.tariff" */
  deleteTariffTariff?: Maybe<TariffTariffMutationResponse>;
  /** delete single row from the table: "tariff.tariff" */
  deleteTariffTariffByPk?: Maybe<TariffTariff>;
  /** delete data from the table: "visit" */
  deleteVisit?: Maybe<VisitMutationResponse>;
  /** delete single row from the table: "visit" */
  deleteVisitByPk?: Maybe<Visit>;
  /** delete data from the table: "visit.checkin" */
  deleteVisitCheckin?: Maybe<VisitCheckinMutationResponse>;
  /** delete single row from the table: "visit.checkin" */
  deleteVisitCheckinByPk?: Maybe<VisitCheckin>;
  /** delete data from the table: "visit.name" */
  deleteVisitName?: Maybe<VisitNameMutationResponse>;
  /** delete single row from the table: "visit.name" */
  deleteVisitNameByPk?: Maybe<VisitName>;
  /** delete data from the table: "visit.visit" */
  deleteVisitVisit?: Maybe<VisitVisitMutationResponse>;
  /** delete single row from the table: "visit.visit" */
  deleteVisitVisitByPk?: Maybe<VisitVisit>;
  /** delete data from the table: "ziferblat.ziferblat" */
  deleteZiferblatZiferblat?: Maybe<ZiferblatZiferblatMutationResponse>;
  /** delete single row from the table: "ziferblat.ziferblat" */
  deleteZiferblatZiferblatByPk?: Maybe<ZiferblatZiferblat>;
  /** insert data into the table: "access.expire" */
  insertAccessExpire?: Maybe<AccessExpireMutationResponse>;
  /** insert data into the table: "access.grant" */
  insertAccessGrant?: Maybe<AccessGrantMutationResponse>;
  /** insert a single row into the table: "access.grant" */
  insertAccessGrantOne?: Maybe<AccessGrant>;
  /** insert data into the table: "access.permission" */
  insertAccessPermission?: Maybe<AccessPermissionMutationResponse>;
  /** insert a single row into the table: "access.permission" */
  insertAccessPermissionOne?: Maybe<AccessPermission>;
  /** insert data into the table: "access.role" */
  insertAccessRole?: Maybe<AccessRoleMutationResponse>;
  /** insert a single row into the table: "access.role" */
  insertAccessRoleOne?: Maybe<AccessRole>;
  /** insert data into the table: "auth.alias" */
  insertAuthAlias?: Maybe<AuthAliasMutationResponse>;
  /** insert a single row into the table: "auth.alias" */
  insertAuthAliasOne?: Maybe<AuthAlias>;
  /** insert data into the table: "auth.logout" */
  insertAuthLogout?: Maybe<AuthLogoutMutationResponse>;
  /** insert data into the table: "auth.session" */
  insertAuthSession?: Maybe<AuthSessionMutationResponse>;
  /** insert a single row into the table: "auth.session" */
  insertAuthSessionOne?: Maybe<AuthSession>;
  /** insert data into the table: "auth.telegram_bot" */
  insertAuthTelegramBot?: Maybe<AuthTelegramBotMutationResponse>;
  /** insert a single row into the table: "auth.telegram_bot" */
  insertAuthTelegramBotOne?: Maybe<AuthTelegramBot>;
  /** insert data into the table: "auth.telegram_input" */
  insertAuthTelegramInput?: Maybe<AuthTelegramInputMutationResponse>;
  /** insert data into the table: "auth.user" */
  insertAuthUser?: Maybe<AuthUserMutationResponse>;
  /** insert a single row into the table: "auth.user" */
  insertAuthUserOne?: Maybe<AuthUser>;
  /** insert data into the table: "cultural_event.compensation_currency" */
  insertCompensationCurrency?: Maybe<CompensationCurrencyMutationResponse>;
  /** insert a single row into the table: "cultural_event.compensation_currency" */
  insertCompensationCurrencyOne?: Maybe<CompensationCurrency>;
  /** insert data into the table: "cultural_event.compensation_unit" */
  insertCompensationUnit?: Maybe<CompensationUnitMutationResponse>;
  /** insert a single row into the table: "cultural_event.compensation_unit" */
  insertCompensationUnitOne?: Maybe<CompensationUnit>;
  /** insert data into the table: "cultural_event.attendance" */
  insertCulturalEventAttendance?: Maybe<CulturalEventAttendanceMutationResponse>;
  /** insert data into the table: "cultural_event.compensation" */
  insertCulturalEventCompensation?: Maybe<CulturalEventCompensationMutationResponse>;
  /** insert a single row into the table: "cultural_event.compensation" */
  insertCulturalEventCompensationOne?: Maybe<CulturalEventCompensation>;
  /** insert data into the table: "cultural_event.status" */
  insertCulturalEventStatus?: Maybe<CulturalEventStatusMutationResponse>;
  /** insert a single row into the table: "cultural_event.status" */
  insertCulturalEventStatusOne?: Maybe<CulturalEventStatus>;
  /** insert data into the table: "cultural_event.ticket" */
  insertCulturalEventTicket?: Maybe<CulturalEventTicketMutationResponse>;
  /** insert a single row into the table: "cultural_event.ticket" */
  insertCulturalEventTicketOne?: Maybe<CulturalEventTicket>;
  /** insert data into the table: "cultural_event.ticket_visit" */
  insertCulturalEventTicketVisit?: Maybe<CulturalEventTicketVisitMutationResponse>;
  /** insert a single row into the table: "cultural_event.ticket_visit" */
  insertCulturalEventTicketVisitOne?: Maybe<CulturalEventTicketVisit>;
  /** insert data into the table: "tariff.tariff" */
  insertTariffTariff?: Maybe<TariffTariffMutationResponse>;
  /** insert a single row into the table: "tariff.tariff" */
  insertTariffTariffOne?: Maybe<TariffTariff>;
  /** insert data into the table: "visit" */
  insertVisit?: Maybe<VisitMutationResponse>;
  /** insert data into the table: "visit.checkin" */
  insertVisitCheckin?: Maybe<VisitCheckinMutationResponse>;
  /** insert a single row into the table: "visit.checkin" */
  insertVisitCheckinOne?: Maybe<VisitCheckin>;
  /** insert data into the table: "visit.name" */
  insertVisitName?: Maybe<VisitNameMutationResponse>;
  /** insert data into the table: "visit.visit" */
  insertVisitVisit?: Maybe<VisitVisitMutationResponse>;
  /** insert a single row into the table: "visit.visit" */
  insertVisitVisitOne?: Maybe<VisitVisit>;
  /** insert data into the table: "ziferblat.ziferblat" */
  insertZiferblatZiferblat?: Maybe<ZiferblatZiferblatMutationResponse>;
  /** insert a single row into the table: "ziferblat.ziferblat" */
  insertZiferblatZiferblatOne?: Maybe<ZiferblatZiferblat>;
  /** insert a single row into the table: "auth.logout" */
  logout?: Maybe<AuthLogout>;
  /** delete data from the table: "cultural_event.attendance" */
  removeAttendance?: Maybe<CulturalEventAttendanceMutationResponse>;
  /** insert a single row into the table: "access.expire" */
  removeGrant?: Maybe<AccessExpire>;
  /** insert a single row into the table: "auth.telegram_input" */
  telegramLogin?: Maybe<AuthTelegramInput>;
  /** update data of the table: "access.expire" */
  updateAccessExpire?: Maybe<AccessExpireMutationResponse>;
  /** update single row of the table: "access.expire" */
  updateAccessExpireByPk?: Maybe<AccessExpire>;
  /** update multiples rows of table: "access.expire" */
  updateAccessExpireMany?: Maybe<Array<Maybe<AccessExpireMutationResponse>>>;
  /** update data of the table: "access.grant" */
  updateAccessGrant?: Maybe<AccessGrantMutationResponse>;
  /** update single row of the table: "access.grant" */
  updateAccessGrantByPk?: Maybe<AccessGrant>;
  /** update multiples rows of table: "access.grant" */
  updateAccessGrantMany?: Maybe<Array<Maybe<AccessGrantMutationResponse>>>;
  /** update data of the table: "access.permission" */
  updateAccessPermission?: Maybe<AccessPermissionMutationResponse>;
  /** update single row of the table: "access.permission" */
  updateAccessPermissionByPk?: Maybe<AccessPermission>;
  /** update multiples rows of table: "access.permission" */
  updateAccessPermissionMany?: Maybe<Array<Maybe<AccessPermissionMutationResponse>>>;
  /** update data of the table: "access.role" */
  updateAccessRole?: Maybe<AccessRoleMutationResponse>;
  /** update single row of the table: "access.role" */
  updateAccessRoleByPk?: Maybe<AccessRole>;
  /** update multiples rows of table: "access.role" */
  updateAccessRoleMany?: Maybe<Array<Maybe<AccessRoleMutationResponse>>>;
  /** update data of the table: "auth.alias" */
  updateAuthAlias?: Maybe<AuthAliasMutationResponse>;
  /** update single row of the table: "auth.alias" */
  updateAuthAliasByPk?: Maybe<AuthAlias>;
  /** update multiples rows of table: "auth.alias" */
  updateAuthAliasMany?: Maybe<Array<Maybe<AuthAliasMutationResponse>>>;
  /** update data of the table: "auth.logout" */
  updateAuthLogout?: Maybe<AuthLogoutMutationResponse>;
  /** update single row of the table: "auth.logout" */
  updateAuthLogoutByPk?: Maybe<AuthLogout>;
  /** update multiples rows of table: "auth.logout" */
  updateAuthLogoutMany?: Maybe<Array<Maybe<AuthLogoutMutationResponse>>>;
  /** update data of the table: "auth.session" */
  updateAuthSession?: Maybe<AuthSessionMutationResponse>;
  /** update single row of the table: "auth.session" */
  updateAuthSessionByPk?: Maybe<AuthSession>;
  /** update multiples rows of table: "auth.session" */
  updateAuthSessionMany?: Maybe<Array<Maybe<AuthSessionMutationResponse>>>;
  /** update data of the table: "auth.telegram_bot" */
  updateAuthTelegramBot?: Maybe<AuthTelegramBotMutationResponse>;
  /** update single row of the table: "auth.telegram_bot" */
  updateAuthTelegramBotByPk?: Maybe<AuthTelegramBot>;
  /** update multiples rows of table: "auth.telegram_bot" */
  updateAuthTelegramBotMany?: Maybe<Array<Maybe<AuthTelegramBotMutationResponse>>>;
  /** update data of the table: "auth.telegram_input" */
  updateAuthTelegramInput?: Maybe<AuthTelegramInputMutationResponse>;
  /** update single row of the table: "auth.telegram_input" */
  updateAuthTelegramInputByPk?: Maybe<AuthTelegramInput>;
  /** update multiples rows of table: "auth.telegram_input" */
  updateAuthTelegramInputMany?: Maybe<Array<Maybe<AuthTelegramInputMutationResponse>>>;
  /** update data of the table: "auth.user" */
  updateAuthUser?: Maybe<AuthUserMutationResponse>;
  /** update single row of the table: "auth.user" */
  updateAuthUserByPk?: Maybe<AuthUser>;
  /** update multiples rows of table: "auth.user" */
  updateAuthUserMany?: Maybe<Array<Maybe<AuthUserMutationResponse>>>;
  /** update single row of the table: "checkin" */
  updateCheckin?: Maybe<Checkin>;
  /** update multiples rows of table: "checkin" */
  updateCheckinMany?: Maybe<Array<Maybe<CheckinMutationResponse>>>;
  /** update data of the table: "checkin" */
  updateCheckins?: Maybe<CheckinMutationResponse>;
  /** update data of the table: "cultural_event.compensation_currency" */
  updateCompensationCurrency?: Maybe<CompensationCurrencyMutationResponse>;
  /** update single row of the table: "cultural_event.compensation_currency" */
  updateCompensationCurrencyByPk?: Maybe<CompensationCurrency>;
  /** update multiples rows of table: "cultural_event.compensation_currency" */
  updateCompensationCurrencyMany?: Maybe<Array<Maybe<CompensationCurrencyMutationResponse>>>;
  /** update data of the table: "cultural_event.compensation_unit" */
  updateCompensationUnit?: Maybe<CompensationUnitMutationResponse>;
  /** update single row of the table: "cultural_event.compensation_unit" */
  updateCompensationUnitByPk?: Maybe<CompensationUnit>;
  /** update multiples rows of table: "cultural_event.compensation_unit" */
  updateCompensationUnitMany?: Maybe<Array<Maybe<CompensationUnitMutationResponse>>>;
  /** update single row of the table: "cultural_event.cultural_event" */
  updateCulturalEvent?: Maybe<CulturalEvent>;
  /** update data of the table: "cultural_event.attendance" */
  updateCulturalEventAttendance?: Maybe<CulturalEventAttendanceMutationResponse>;
  /** update single row of the table: "cultural_event.attendance" */
  updateCulturalEventAttendanceByPk?: Maybe<CulturalEventAttendance>;
  /** update multiples rows of table: "cultural_event.attendance" */
  updateCulturalEventAttendanceMany?: Maybe<Array<Maybe<CulturalEventAttendanceMutationResponse>>>;
  /** update data of the table: "cultural_event.compensation" */
  updateCulturalEventCompensation?: Maybe<CulturalEventCompensationMutationResponse>;
  /** update single row of the table: "cultural_event.compensation" */
  updateCulturalEventCompensationByPk?: Maybe<CulturalEventCompensation>;
  /** update multiples rows of table: "cultural_event.compensation" */
  updateCulturalEventCompensationMany?: Maybe<Array<Maybe<CulturalEventCompensationMutationResponse>>>;
  /** update data of the table: "cultural_event.status" */
  updateCulturalEventStatus?: Maybe<CulturalEventStatusMutationResponse>;
  /** update single row of the table: "cultural_event.status" */
  updateCulturalEventStatusByPk?: Maybe<CulturalEventStatus>;
  /** update multiples rows of table: "cultural_event.status" */
  updateCulturalEventStatusMany?: Maybe<Array<Maybe<CulturalEventStatusMutationResponse>>>;
  /** update data of the table: "cultural_event.ticket" */
  updateCulturalEventTicket?: Maybe<CulturalEventTicketMutationResponse>;
  /** update single row of the table: "cultural_event.ticket" */
  updateCulturalEventTicketByPk?: Maybe<CulturalEventTicket>;
  /** update multiples rows of table: "cultural_event.ticket" */
  updateCulturalEventTicketMany?: Maybe<Array<Maybe<CulturalEventTicketMutationResponse>>>;
  /** update data of the table: "cultural_event.ticket_visit" */
  updateCulturalEventTicketVisit?: Maybe<CulturalEventTicketVisitMutationResponse>;
  /** update single row of the table: "cultural_event.ticket_visit" */
  updateCulturalEventTicketVisitByPk?: Maybe<CulturalEventTicketVisit>;
  /** update multiples rows of table: "cultural_event.ticket_visit" */
  updateCulturalEventTicketVisitMany?: Maybe<Array<Maybe<CulturalEventTicketVisitMutationResponse>>>;
  /** update data of the table: "cultural_event.cultural_event" */
  updateCulturalEvents?: Maybe<CulturalEventMutationResponse>;
  /** update multiples rows of table: "cultural_event.cultural_event" */
  updateMultipleCulturalEvents?: Maybe<Array<Maybe<CulturalEventMutationResponse>>>;
  /** insert a single row into the table: "visit.name" */
  updateName?: Maybe<VisitName>;
  /** update data of the table: "tariff.tariff" */
  updateTariffTariff?: Maybe<TariffTariffMutationResponse>;
  /** update single row of the table: "tariff.tariff" */
  updateTariffTariffByPk?: Maybe<TariffTariff>;
  /** update multiples rows of table: "tariff.tariff" */
  updateTariffTariffMany?: Maybe<Array<Maybe<TariffTariffMutationResponse>>>;
  /** update data of the table: "visit" */
  updateVisit?: Maybe<VisitMutationResponse>;
  /** update single row of the table: "visit" */
  updateVisitByPk?: Maybe<Visit>;
  /** update data of the table: "visit.checkin" */
  updateVisitCheckin?: Maybe<VisitCheckinMutationResponse>;
  /** update single row of the table: "visit.checkin" */
  updateVisitCheckinByPk?: Maybe<VisitCheckin>;
  /** update multiples rows of table: "visit.checkin" */
  updateVisitCheckinMany?: Maybe<Array<Maybe<VisitCheckinMutationResponse>>>;
  /** update multiples rows of table: "visit" */
  updateVisitMany?: Maybe<Array<Maybe<VisitMutationResponse>>>;
  /** update data of the table: "visit.name" */
  updateVisitName?: Maybe<VisitNameMutationResponse>;
  /** update single row of the table: "visit.name" */
  updateVisitNameByPk?: Maybe<VisitName>;
  /** update multiples rows of table: "visit.name" */
  updateVisitNameMany?: Maybe<Array<Maybe<VisitNameMutationResponse>>>;
  /** update data of the table: "visit.visit" */
  updateVisitVisit?: Maybe<VisitVisitMutationResponse>;
  /** update single row of the table: "visit.visit" */
  updateVisitVisitByPk?: Maybe<VisitVisit>;
  /** update multiples rows of table: "visit.visit" */
  updateVisitVisitMany?: Maybe<Array<Maybe<VisitVisitMutationResponse>>>;
  /** update data of the table: "ziferblat.ziferblat" */
  updateZiferblatZiferblat?: Maybe<ZiferblatZiferblatMutationResponse>;
  /** update single row of the table: "ziferblat.ziferblat" */
  updateZiferblatZiferblatByPk?: Maybe<ZiferblatZiferblat>;
  /** update multiples rows of table: "ziferblat.ziferblat" */
  updateZiferblatZiferblatMany?: Maybe<Array<Maybe<ZiferblatZiferblatMutationResponse>>>;
};


/** mutation root */
export type Mutation_RootAddAttendanceArgs = {
  object: CulturalEventAttendanceInsertInput;
  onConflict?: InputMaybe<CulturalEventAttendanceOnConflict>;
};


/** mutation root */
export type Mutation_RootAddCheckinArgs = {
  object: CheckinInsertInput;
  onConflict?: InputMaybe<CheckinOnConflict>;
};


/** mutation root */
export type Mutation_RootAddCheckinsArgs = {
  objects: Array<CheckinInsertInput>;
  onConflict?: InputMaybe<CheckinOnConflict>;
};


/** mutation root */
export type Mutation_RootAddCulturalEventArgs = {
  object: CulturalEventInsertInput;
  onConflict?: InputMaybe<CulturalEventOnConflict>;
};


/** mutation root */
export type Mutation_RootAddCulturalEventsArgs = {
  objects: Array<CulturalEventInsertInput>;
  onConflict?: InputMaybe<CulturalEventOnConflict>;
};


/** mutation root */
export type Mutation_RootCheckOutArgs = {
  object: VisitInsertInput;
  onConflict?: InputMaybe<VisitOnConflict>;
};


/** mutation root */
export type Mutation_RootDeleteAccessExpireArgs = {
  where: AccessExpireBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteAccessExpireByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDeleteAccessGrantArgs = {
  where: AccessGrantBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteAccessGrantByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDeleteAccessPermissionArgs = {
  where: AccessPermissionBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteAccessPermissionByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDeleteAccessRoleArgs = {
  where: AccessRoleBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteAccessRoleByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDeleteAuthAliasArgs = {
  where: AuthAliasBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteAuthAliasByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDeleteAuthLogoutArgs = {
  where: AuthLogoutBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteAuthLogoutByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDeleteAuthSessionArgs = {
  where: AuthSessionBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteAuthSessionByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDeleteAuthTelegramBotArgs = {
  where: AuthTelegramBotBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteAuthTelegramBotByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDeleteAuthTelegramInputArgs = {
  where: AuthTelegramInputBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteAuthTelegramInputByPkArgs = {
  hash: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDeleteAuthUserArgs = {
  where: AuthUserBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteAuthUserByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDeleteCheckinArgs = {
  where: CheckinBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteCheckinByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDeleteCompensationCurrencyArgs = {
  where: CompensationCurrencyBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteCompensationCurrencyByPkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDeleteCompensationUnitArgs = {
  where: CompensationUnitBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteCompensationUnitByPkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDeleteCulturalEventArgs = {
  where: CulturalEventBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteCulturalEventAttendanceByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDeleteCulturalEventByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDeleteCulturalEventCompensationArgs = {
  where: CulturalEventCompensationBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteCulturalEventCompensationByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDeleteCulturalEventStatusArgs = {
  where: CulturalEventStatusBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteCulturalEventStatusByPkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDeleteCulturalEventTicketArgs = {
  where: CulturalEventTicketBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteCulturalEventTicketByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDeleteCulturalEventTicketVisitArgs = {
  where: CulturalEventTicketVisitBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteCulturalEventTicketVisitByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDeleteTariffTariffArgs = {
  where: TariffTariffBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteTariffTariffByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDeleteVisitArgs = {
  where: VisitBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteVisitByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDeleteVisitCheckinArgs = {
  where: VisitCheckinBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteVisitCheckinByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDeleteVisitNameArgs = {
  where: VisitNameBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteVisitNameByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDeleteVisitVisitArgs = {
  where: VisitVisitBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteVisitVisitByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDeleteZiferblatZiferblatArgs = {
  where: ZiferblatZiferblatBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteZiferblatZiferblatByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootInsertAccessExpireArgs = {
  objects: Array<AccessExpireInsertInput>;
  onConflict?: InputMaybe<AccessExpireOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertAccessGrantArgs = {
  objects: Array<AccessGrantInsertInput>;
  onConflict?: InputMaybe<AccessGrantOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertAccessGrantOneArgs = {
  object: AccessGrantInsertInput;
  onConflict?: InputMaybe<AccessGrantOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertAccessPermissionArgs = {
  objects: Array<AccessPermissionInsertInput>;
  onConflict?: InputMaybe<AccessPermissionOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertAccessPermissionOneArgs = {
  object: AccessPermissionInsertInput;
  onConflict?: InputMaybe<AccessPermissionOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertAccessRoleArgs = {
  objects: Array<AccessRoleInsertInput>;
  onConflict?: InputMaybe<AccessRoleOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertAccessRoleOneArgs = {
  object: AccessRoleInsertInput;
  onConflict?: InputMaybe<AccessRoleOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertAuthAliasArgs = {
  objects: Array<AuthAliasInsertInput>;
  onConflict?: InputMaybe<AuthAliasOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertAuthAliasOneArgs = {
  object: AuthAliasInsertInput;
  onConflict?: InputMaybe<AuthAliasOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertAuthLogoutArgs = {
  objects: Array<AuthLogoutInsertInput>;
  onConflict?: InputMaybe<AuthLogoutOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertAuthSessionArgs = {
  objects: Array<AuthSessionInsertInput>;
  onConflict?: InputMaybe<AuthSessionOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertAuthSessionOneArgs = {
  object: AuthSessionInsertInput;
  onConflict?: InputMaybe<AuthSessionOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertAuthTelegramBotArgs = {
  objects: Array<AuthTelegramBotInsertInput>;
  onConflict?: InputMaybe<AuthTelegramBotOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertAuthTelegramBotOneArgs = {
  object: AuthTelegramBotInsertInput;
  onConflict?: InputMaybe<AuthTelegramBotOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertAuthTelegramInputArgs = {
  objects: Array<AuthTelegramInputInsertInput>;
  onConflict?: InputMaybe<AuthTelegramInputOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertAuthUserArgs = {
  objects: Array<AuthUserInsertInput>;
  onConflict?: InputMaybe<AuthUserOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertAuthUserOneArgs = {
  object: AuthUserInsertInput;
  onConflict?: InputMaybe<AuthUserOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertCompensationCurrencyArgs = {
  objects: Array<CompensationCurrencyInsertInput>;
  onConflict?: InputMaybe<CompensationCurrencyOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertCompensationCurrencyOneArgs = {
  object: CompensationCurrencyInsertInput;
  onConflict?: InputMaybe<CompensationCurrencyOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertCompensationUnitArgs = {
  objects: Array<CompensationUnitInsertInput>;
  onConflict?: InputMaybe<CompensationUnitOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertCompensationUnitOneArgs = {
  object: CompensationUnitInsertInput;
  onConflict?: InputMaybe<CompensationUnitOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertCulturalEventAttendanceArgs = {
  objects: Array<CulturalEventAttendanceInsertInput>;
  onConflict?: InputMaybe<CulturalEventAttendanceOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertCulturalEventCompensationArgs = {
  objects: Array<CulturalEventCompensationInsertInput>;
  onConflict?: InputMaybe<CulturalEventCompensationOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertCulturalEventCompensationOneArgs = {
  object: CulturalEventCompensationInsertInput;
  onConflict?: InputMaybe<CulturalEventCompensationOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertCulturalEventStatusArgs = {
  objects: Array<CulturalEventStatusInsertInput>;
  onConflict?: InputMaybe<CulturalEventStatusOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertCulturalEventStatusOneArgs = {
  object: CulturalEventStatusInsertInput;
  onConflict?: InputMaybe<CulturalEventStatusOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertCulturalEventTicketArgs = {
  objects: Array<CulturalEventTicketInsertInput>;
  onConflict?: InputMaybe<CulturalEventTicketOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertCulturalEventTicketOneArgs = {
  object: CulturalEventTicketInsertInput;
  onConflict?: InputMaybe<CulturalEventTicketOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertCulturalEventTicketVisitArgs = {
  objects: Array<CulturalEventTicketVisitInsertInput>;
  onConflict?: InputMaybe<CulturalEventTicketVisitOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertCulturalEventTicketVisitOneArgs = {
  object: CulturalEventTicketVisitInsertInput;
  onConflict?: InputMaybe<CulturalEventTicketVisitOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertTariffTariffArgs = {
  objects: Array<TariffTariffInsertInput>;
  onConflict?: InputMaybe<TariffTariffOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertTariffTariffOneArgs = {
  object: TariffTariffInsertInput;
  onConflict?: InputMaybe<TariffTariffOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertVisitArgs = {
  objects: Array<VisitInsertInput>;
  onConflict?: InputMaybe<VisitOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertVisitCheckinArgs = {
  objects: Array<VisitCheckinInsertInput>;
  onConflict?: InputMaybe<VisitCheckinOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertVisitCheckinOneArgs = {
  object: VisitCheckinInsertInput;
  onConflict?: InputMaybe<VisitCheckinOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertVisitNameArgs = {
  objects: Array<VisitNameInsertInput>;
  onConflict?: InputMaybe<VisitNameOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertVisitVisitArgs = {
  objects: Array<VisitVisitInsertInput>;
  onConflict?: InputMaybe<VisitVisitOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertVisitVisitOneArgs = {
  object: VisitVisitInsertInput;
  onConflict?: InputMaybe<VisitVisitOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertZiferblatZiferblatArgs = {
  objects: Array<ZiferblatZiferblatInsertInput>;
  onConflict?: InputMaybe<ZiferblatZiferblatOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertZiferblatZiferblatOneArgs = {
  object: ZiferblatZiferblatInsertInput;
  onConflict?: InputMaybe<ZiferblatZiferblatOnConflict>;
};


/** mutation root */
export type Mutation_RootLogoutArgs = {
  object: AuthLogoutInsertInput;
  onConflict?: InputMaybe<AuthLogoutOnConflict>;
};


/** mutation root */
export type Mutation_RootRemoveAttendanceArgs = {
  where: CulturalEventAttendanceBoolExp;
};


/** mutation root */
export type Mutation_RootRemoveGrantArgs = {
  object: AccessExpireInsertInput;
  onConflict?: InputMaybe<AccessExpireOnConflict>;
};


/** mutation root */
export type Mutation_RootTelegramLoginArgs = {
  object: AuthTelegramInputInsertInput;
  onConflict?: InputMaybe<AuthTelegramInputOnConflict>;
};


/** mutation root */
export type Mutation_RootUpdateAccessExpireArgs = {
  _set?: InputMaybe<AccessExpireSetInput>;
  where: AccessExpireBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateAccessExpireByPkArgs = {
  _set?: InputMaybe<AccessExpireSetInput>;
  pkColumns: AccessExpirePkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateAccessExpireManyArgs = {
  updates: Array<AccessExpireUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateAccessGrantArgs = {
  _set?: InputMaybe<AccessGrantSetInput>;
  where: AccessGrantBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateAccessGrantByPkArgs = {
  _set?: InputMaybe<AccessGrantSetInput>;
  pkColumns: AccessGrantPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateAccessGrantManyArgs = {
  updates: Array<AccessGrantUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateAccessPermissionArgs = {
  _set?: InputMaybe<AccessPermissionSetInput>;
  where: AccessPermissionBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateAccessPermissionByPkArgs = {
  _set?: InputMaybe<AccessPermissionSetInput>;
  pkColumns: AccessPermissionPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateAccessPermissionManyArgs = {
  updates: Array<AccessPermissionUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateAccessRoleArgs = {
  _set?: InputMaybe<AccessRoleSetInput>;
  where: AccessRoleBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateAccessRoleByPkArgs = {
  _set?: InputMaybe<AccessRoleSetInput>;
  pkColumns: AccessRolePkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateAccessRoleManyArgs = {
  updates: Array<AccessRoleUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateAuthAliasArgs = {
  _set?: InputMaybe<AuthAliasSetInput>;
  where: AuthAliasBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateAuthAliasByPkArgs = {
  _set?: InputMaybe<AuthAliasSetInput>;
  pkColumns: AuthAliasPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateAuthAliasManyArgs = {
  updates: Array<AuthAliasUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateAuthLogoutArgs = {
  _set?: InputMaybe<AuthLogoutSetInput>;
  where: AuthLogoutBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateAuthLogoutByPkArgs = {
  _set?: InputMaybe<AuthLogoutSetInput>;
  pkColumns: AuthLogoutPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateAuthLogoutManyArgs = {
  updates: Array<AuthLogoutUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateAuthSessionArgs = {
  _set?: InputMaybe<AuthSessionSetInput>;
  where: AuthSessionBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateAuthSessionByPkArgs = {
  _set?: InputMaybe<AuthSessionSetInput>;
  pkColumns: AuthSessionPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateAuthSessionManyArgs = {
  updates: Array<AuthSessionUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateAuthTelegramBotArgs = {
  _inc?: InputMaybe<AuthTelegramBotIncInput>;
  _set?: InputMaybe<AuthTelegramBotSetInput>;
  where: AuthTelegramBotBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateAuthTelegramBotByPkArgs = {
  _inc?: InputMaybe<AuthTelegramBotIncInput>;
  _set?: InputMaybe<AuthTelegramBotSetInput>;
  pkColumns: AuthTelegramBotPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateAuthTelegramBotManyArgs = {
  updates: Array<AuthTelegramBotUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateAuthTelegramInputArgs = {
  _inc?: InputMaybe<AuthTelegramInputIncInput>;
  _set?: InputMaybe<AuthTelegramInputSetInput>;
  where: AuthTelegramInputBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateAuthTelegramInputByPkArgs = {
  _inc?: InputMaybe<AuthTelegramInputIncInput>;
  _set?: InputMaybe<AuthTelegramInputSetInput>;
  pkColumns: AuthTelegramInputPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateAuthTelegramInputManyArgs = {
  updates: Array<AuthTelegramInputUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateAuthUserArgs = {
  _set?: InputMaybe<AuthUserSetInput>;
  where: AuthUserBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateAuthUserByPkArgs = {
  _set?: InputMaybe<AuthUserSetInput>;
  pkColumns: AuthUserPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateAuthUserManyArgs = {
  updates: Array<AuthUserUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateCheckinArgs = {
  _set?: InputMaybe<CheckinSetInput>;
  pkColumns: CheckinPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateCheckinManyArgs = {
  updates: Array<CheckinUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateCheckinsArgs = {
  _set?: InputMaybe<CheckinSetInput>;
  where: CheckinBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateCompensationCurrencyArgs = {
  _set?: InputMaybe<CompensationCurrencySetInput>;
  where: CompensationCurrencyBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateCompensationCurrencyByPkArgs = {
  _set?: InputMaybe<CompensationCurrencySetInput>;
  pkColumns: CompensationCurrencyPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateCompensationCurrencyManyArgs = {
  updates: Array<CompensationCurrencyUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateCompensationUnitArgs = {
  _set?: InputMaybe<CompensationUnitSetInput>;
  where: CompensationUnitBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateCompensationUnitByPkArgs = {
  _set?: InputMaybe<CompensationUnitSetInput>;
  pkColumns: CompensationUnitPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateCompensationUnitManyArgs = {
  updates: Array<CompensationUnitUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateCulturalEventArgs = {
  _set?: InputMaybe<CulturalEventSetInput>;
  pkColumns: CulturalEventPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateCulturalEventAttendanceArgs = {
  _set?: InputMaybe<CulturalEventAttendanceSetInput>;
  where: CulturalEventAttendanceBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateCulturalEventAttendanceByPkArgs = {
  _set?: InputMaybe<CulturalEventAttendanceSetInput>;
  pkColumns: CulturalEventAttendancePkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateCulturalEventAttendanceManyArgs = {
  updates: Array<CulturalEventAttendanceUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateCulturalEventCompensationArgs = {
  _inc?: InputMaybe<CulturalEventCompensationIncInput>;
  _set?: InputMaybe<CulturalEventCompensationSetInput>;
  where: CulturalEventCompensationBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateCulturalEventCompensationByPkArgs = {
  _inc?: InputMaybe<CulturalEventCompensationIncInput>;
  _set?: InputMaybe<CulturalEventCompensationSetInput>;
  pkColumns: CulturalEventCompensationPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateCulturalEventCompensationManyArgs = {
  updates: Array<CulturalEventCompensationUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateCulturalEventStatusArgs = {
  _set?: InputMaybe<CulturalEventStatusSetInput>;
  where: CulturalEventStatusBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateCulturalEventStatusByPkArgs = {
  _set?: InputMaybe<CulturalEventStatusSetInput>;
  pkColumns: CulturalEventStatusPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateCulturalEventStatusManyArgs = {
  updates: Array<CulturalEventStatusUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateCulturalEventTicketArgs = {
  _set?: InputMaybe<CulturalEventTicketSetInput>;
  where: CulturalEventTicketBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateCulturalEventTicketByPkArgs = {
  _set?: InputMaybe<CulturalEventTicketSetInput>;
  pkColumns: CulturalEventTicketPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateCulturalEventTicketManyArgs = {
  updates: Array<CulturalEventTicketUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateCulturalEventTicketVisitArgs = {
  _set?: InputMaybe<CulturalEventTicketVisitSetInput>;
  where: CulturalEventTicketVisitBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateCulturalEventTicketVisitByPkArgs = {
  _set?: InputMaybe<CulturalEventTicketVisitSetInput>;
  pkColumns: CulturalEventTicketVisitPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateCulturalEventTicketVisitManyArgs = {
  updates: Array<CulturalEventTicketVisitUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateCulturalEventsArgs = {
  _set?: InputMaybe<CulturalEventSetInput>;
  where: CulturalEventBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateMultipleCulturalEventsArgs = {
  updates: Array<CulturalEventUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateNameArgs = {
  object: VisitNameInsertInput;
  onConflict?: InputMaybe<VisitNameOnConflict>;
};


/** mutation root */
export type Mutation_RootUpdateTariffTariffArgs = {
  _inc?: InputMaybe<TariffTariffIncInput>;
  _set?: InputMaybe<TariffTariffSetInput>;
  where: TariffTariffBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateTariffTariffByPkArgs = {
  _inc?: InputMaybe<TariffTariffIncInput>;
  _set?: InputMaybe<TariffTariffSetInput>;
  pkColumns: TariffTariffPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateTariffTariffManyArgs = {
  updates: Array<TariffTariffUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateVisitArgs = {
  _inc?: InputMaybe<VisitIncInput>;
  _set?: InputMaybe<VisitSetInput>;
  where: VisitBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateVisitByPkArgs = {
  _inc?: InputMaybe<VisitIncInput>;
  _set?: InputMaybe<VisitSetInput>;
  pkColumns: VisitPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateVisitCheckinArgs = {
  _set?: InputMaybe<VisitCheckinSetInput>;
  where: VisitCheckinBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateVisitCheckinByPkArgs = {
  _set?: InputMaybe<VisitCheckinSetInput>;
  pkColumns: VisitCheckinPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateVisitCheckinManyArgs = {
  updates: Array<VisitCheckinUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateVisitManyArgs = {
  updates: Array<VisitUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateVisitNameArgs = {
  _set?: InputMaybe<VisitNameSetInput>;
  where: VisitNameBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateVisitNameByPkArgs = {
  _set?: InputMaybe<VisitNameSetInput>;
  pkColumns: VisitNamePkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateVisitNameManyArgs = {
  updates: Array<VisitNameUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateVisitVisitArgs = {
  _inc?: InputMaybe<VisitVisitIncInput>;
  _set?: InputMaybe<VisitVisitSetInput>;
  where: VisitVisitBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateVisitVisitByPkArgs = {
  _inc?: InputMaybe<VisitVisitIncInput>;
  _set?: InputMaybe<VisitVisitSetInput>;
  pkColumns: VisitVisitPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateVisitVisitManyArgs = {
  updates: Array<VisitVisitUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateZiferblatZiferblatArgs = {
  _set?: InputMaybe<ZiferblatZiferblatSetInput>;
  where: ZiferblatZiferblatBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateZiferblatZiferblatByPkArgs = {
  _set?: InputMaybe<ZiferblatZiferblatSetInput>;
  pkColumns: ZiferblatZiferblatPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateZiferblatZiferblatManyArgs = {
  updates: Array<ZiferblatZiferblatUpdates>;
};

export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "cultural_event.compensation_currency" */
  CompensationCurrency: Array<CompensationCurrency>;
  /** fetch aggregated fields from the table: "cultural_event.compensation_currency" */
  CompensationCurrencyAggregate: CompensationCurrencyAggregate;
  /** fetch data from the table: "cultural_event.compensation_currency" using primary key columns */
  CompensationCurrencyByPk?: Maybe<CompensationCurrency>;
  /** fetch data from the table: "cultural_event.compensation_unit" */
  CompensationUnit: Array<CompensationUnit>;
  /** fetch aggregated fields from the table: "cultural_event.compensation_unit" */
  CompensationUnitAggregate: CompensationUnitAggregate;
  /** fetch data from the table: "cultural_event.compensation_unit" using primary key columns */
  CompensationUnitByPk?: Maybe<CompensationUnit>;
  /** fetch data from the table: "access.access" */
  accessAccess: Array<AccessAccess>;
  /** fetch aggregated fields from the table: "access.access" */
  accessAccessAggregate: AccessAccessAggregate;
  /** fetch data from the table: "access.expire" */
  accessExpire: Array<AccessExpire>;
  /** fetch aggregated fields from the table: "access.expire" */
  accessExpireAggregate: AccessExpireAggregate;
  /** fetch data from the table: "access.expire" using primary key columns */
  accessExpireByPk?: Maybe<AccessExpire>;
  /** fetch data from the table: "access.grant" */
  accessGrant: Array<AccessGrant>;
  /** fetch aggregated fields from the table: "access.grant" */
  accessGrantAggregate: AccessGrantAggregate;
  /** fetch data from the table: "access.grant" using primary key columns */
  accessGrantByPk?: Maybe<AccessGrant>;
  /** fetch data from the table: "access.permission" */
  accessPermission: Array<AccessPermission>;
  /** fetch aggregated fields from the table: "access.permission" */
  accessPermissionAggregate: AccessPermissionAggregate;
  /** fetch data from the table: "access.permission" using primary key columns */
  accessPermissionByPk?: Maybe<AccessPermission>;
  /** fetch data from the table: "access.role" */
  accessRole: Array<AccessRole>;
  /** fetch aggregated fields from the table: "access.role" */
  accessRoleAggregate: AccessRoleAggregate;
  /** fetch data from the table: "access.role" using primary key columns */
  accessRoleByPk?: Maybe<AccessRole>;
  /** fetch aggregated fields from the table: "checkin" */
  aggregateCheckins: CheckinAggregate;
  /** fetch aggregated fields from the table: "cultural_event.cultural_event" */
  aggregateCulturalEvents: CulturalEventAggregate;
  /** fetch aggregated fields from the table: "visit" */
  aggregateVisits: VisitAggregate;
  /** An array relationship */
  attendees: Array<Attendees>;
  /** An aggregate relationship */
  attendeesAggregate: AttendeesAggregate;
  /** fetch data from the table: "auth.alias" */
  authAlias: Array<AuthAlias>;
  /** fetch aggregated fields from the table: "auth.alias" */
  authAliasAggregate: AuthAliasAggregate;
  /** fetch data from the table: "auth.alias" using primary key columns */
  authAliasByPk?: Maybe<AuthAlias>;
  /** fetch data from the table: "auth.logout" */
  authLogout: Array<AuthLogout>;
  /** fetch aggregated fields from the table: "auth.logout" */
  authLogoutAggregate: AuthLogoutAggregate;
  /** fetch data from the table: "auth.logout" using primary key columns */
  authLogoutByPk?: Maybe<AuthLogout>;
  /** execute function "auth.me" which returns "auth.session" */
  authMe?: Maybe<AuthSession>;
  /** execute function "auth.me" and query aggregates on result of table type "auth.session" */
  authMeAggregate: AuthSessionAggregate;
  /** fetch data from the table: "auth.session" */
  authSession: Array<AuthSession>;
  /** fetch aggregated fields from the table: "auth.session" */
  authSessionAggregate: AuthSessionAggregate;
  /** fetch data from the table: "auth.session" using primary key columns */
  authSessionByPk?: Maybe<AuthSession>;
  /** fetch data from the table: "auth.telegram_bot" */
  authTelegramBot: Array<AuthTelegramBot>;
  /** fetch aggregated fields from the table: "auth.telegram_bot" */
  authTelegramBotAggregate: AuthTelegramBotAggregate;
  /** fetch data from the table: "auth.telegram_bot" using primary key columns */
  authTelegramBotByPk?: Maybe<AuthTelegramBot>;
  /** fetch data from the table: "auth.telegram_input" */
  authTelegramInput: Array<AuthTelegramInput>;
  /** fetch aggregated fields from the table: "auth.telegram_input" */
  authTelegramInputAggregate: AuthTelegramInputAggregate;
  /** fetch data from the table: "auth.telegram_input" using primary key columns */
  authTelegramInputByPk?: Maybe<AuthTelegramInput>;
  /** fetch aggregated fields from the table: "auth.user" */
  authUserAggregate: AuthUserAggregate;
  /** fetch data from the table: "checkin" using primary key columns */
  checkin?: Maybe<Checkin>;
  /** fetch data from the table: "checkin" */
  checkins: Array<Checkin>;
  /** fetch data from the table: "cultural_event.cultural_event" using primary key columns */
  culturalEvent?: Maybe<CulturalEvent>;
  /** fetch data from the table: "cultural_event.attendance" */
  culturalEventAttendance: Array<CulturalEventAttendance>;
  /** fetch aggregated fields from the table: "cultural_event.attendance" */
  culturalEventAttendanceAggregate: CulturalEventAttendanceAggregate;
  /** fetch data from the table: "cultural_event.attendance" using primary key columns */
  culturalEventAttendanceByPk?: Maybe<CulturalEventAttendance>;
  /** fetch data from the table: "cultural_event.compensation" */
  culturalEventCompensation: Array<CulturalEventCompensation>;
  /** fetch aggregated fields from the table: "cultural_event.compensation" */
  culturalEventCompensationAggregate: CulturalEventCompensationAggregate;
  /** fetch data from the table: "cultural_event.compensation" using primary key columns */
  culturalEventCompensationByPk?: Maybe<CulturalEventCompensation>;
  /** fetch data from the table: "cultural_event.status" */
  culturalEventStatus: Array<CulturalEventStatus>;
  /** fetch aggregated fields from the table: "cultural_event.status" */
  culturalEventStatusAggregate: CulturalEventStatusAggregate;
  /** fetch data from the table: "cultural_event.status" using primary key columns */
  culturalEventStatusByPk?: Maybe<CulturalEventStatus>;
  /** fetch data from the table: "cultural_event.ticket" */
  culturalEventTicket: Array<CulturalEventTicket>;
  /** fetch aggregated fields from the table: "cultural_event.ticket" */
  culturalEventTicketAggregate: CulturalEventTicketAggregate;
  /** fetch data from the table: "cultural_event.ticket" using primary key columns */
  culturalEventTicketByPk?: Maybe<CulturalEventTicket>;
  /** fetch data from the table: "cultural_event.ticket_visit" */
  culturalEventTicketVisit: Array<CulturalEventTicketVisit>;
  /** fetch aggregated fields from the table: "cultural_event.ticket_visit" */
  culturalEventTicketVisitAggregate: CulturalEventTicketVisitAggregate;
  /** fetch data from the table: "cultural_event.ticket_visit" using primary key columns */
  culturalEventTicketVisitByPk?: Maybe<CulturalEventTicketVisit>;
  /** fetch data from the table: "cultural_event.cultural_event" */
  culturalEvents: Array<CulturalEvent>;
  /** fetch data from the table: "tariff.tariff" using primary key columns */
  tariff?: Maybe<TariffTariff>;
  /** fetch aggregated fields from the table: "tariff.tariff" */
  tariffTariffAggregate: TariffTariffAggregate;
  /** fetch data from the table: "tariff.tariff" */
  tariffs: Array<TariffTariff>;
  /** fetch data from the table: "auth.user" using primary key columns */
  user?: Maybe<AuthUser>;
  /** fetch data from the table: "auth.user" */
  users: Array<AuthUser>;
  /** fetch data from the table: "visit" using primary key columns */
  visit?: Maybe<Visit>;
  /** fetch data from the table: "visit.checkin" */
  visitCheckin: Array<VisitCheckin>;
  /** fetch aggregated fields from the table: "visit.checkin" */
  visitCheckinAggregate: VisitCheckinAggregate;
  /** fetch data from the table: "visit.checkin" using primary key columns */
  visitCheckinByPk?: Maybe<VisitCheckin>;
  /** fetch data from the table: "visit.name" */
  visitName: Array<VisitName>;
  /** fetch aggregated fields from the table: "visit.name" */
  visitNameAggregate: VisitNameAggregate;
  /** fetch data from the table: "visit.name" using primary key columns */
  visitNameByPk?: Maybe<VisitName>;
  /** fetch data from the table: "visit.visit" */
  visitVisit: Array<VisitVisit>;
  /** fetch aggregated fields from the table: "visit.visit" */
  visitVisitAggregate: VisitVisitAggregate;
  /** fetch data from the table: "visit.visit" using primary key columns */
  visitVisitByPk?: Maybe<VisitVisit>;
  /** fetch data from the table: "visit" */
  visits: Array<Visit>;
  /** fetch data from the table: "ziferblat.ziferblat" using primary key columns */
  ziferblat?: Maybe<ZiferblatZiferblat>;
  /** fetch data from the table: "ziferblat.ziferblat" */
  ziferblats: Array<ZiferblatZiferblat>;
  /** fetch aggregated fields from the table: "ziferblat.ziferblat" */
  ziferblatsAggregate: ZiferblatZiferblatAggregate;
};


export type Query_RootCompensationCurrencyArgs = {
  distinctOn?: InputMaybe<Array<CompensationCurrencySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CompensationCurrencyOrderBy>>;
  where?: InputMaybe<CompensationCurrencyBoolExp>;
};


export type Query_RootCompensationCurrencyAggregateArgs = {
  distinctOn?: InputMaybe<Array<CompensationCurrencySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CompensationCurrencyOrderBy>>;
  where?: InputMaybe<CompensationCurrencyBoolExp>;
};


export type Query_RootCompensationCurrencyByPkArgs = {
  value: Scalars['String']['input'];
};


export type Query_RootCompensationUnitArgs = {
  distinctOn?: InputMaybe<Array<CompensationUnitSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CompensationUnitOrderBy>>;
  where?: InputMaybe<CompensationUnitBoolExp>;
};


export type Query_RootCompensationUnitAggregateArgs = {
  distinctOn?: InputMaybe<Array<CompensationUnitSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CompensationUnitOrderBy>>;
  where?: InputMaybe<CompensationUnitBoolExp>;
};


export type Query_RootCompensationUnitByPkArgs = {
  value: Scalars['String']['input'];
};


export type Query_RootAccessAccessArgs = {
  distinctOn?: InputMaybe<Array<AccessAccessSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AccessAccessOrderBy>>;
  where?: InputMaybe<AccessAccessBoolExp>;
};


export type Query_RootAccessAccessAggregateArgs = {
  distinctOn?: InputMaybe<Array<AccessAccessSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AccessAccessOrderBy>>;
  where?: InputMaybe<AccessAccessBoolExp>;
};


export type Query_RootAccessExpireArgs = {
  distinctOn?: InputMaybe<Array<AccessExpireSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AccessExpireOrderBy>>;
  where?: InputMaybe<AccessExpireBoolExp>;
};


export type Query_RootAccessExpireAggregateArgs = {
  distinctOn?: InputMaybe<Array<AccessExpireSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AccessExpireOrderBy>>;
  where?: InputMaybe<AccessExpireBoolExp>;
};


export type Query_RootAccessExpireByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootAccessGrantArgs = {
  distinctOn?: InputMaybe<Array<AccessGrantSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AccessGrantOrderBy>>;
  where?: InputMaybe<AccessGrantBoolExp>;
};


export type Query_RootAccessGrantAggregateArgs = {
  distinctOn?: InputMaybe<Array<AccessGrantSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AccessGrantOrderBy>>;
  where?: InputMaybe<AccessGrantBoolExp>;
};


export type Query_RootAccessGrantByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootAccessPermissionArgs = {
  distinctOn?: InputMaybe<Array<AccessPermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AccessPermissionOrderBy>>;
  where?: InputMaybe<AccessPermissionBoolExp>;
};


export type Query_RootAccessPermissionAggregateArgs = {
  distinctOn?: InputMaybe<Array<AccessPermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AccessPermissionOrderBy>>;
  where?: InputMaybe<AccessPermissionBoolExp>;
};


export type Query_RootAccessPermissionByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootAccessRoleArgs = {
  distinctOn?: InputMaybe<Array<AccessRoleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AccessRoleOrderBy>>;
  where?: InputMaybe<AccessRoleBoolExp>;
};


export type Query_RootAccessRoleAggregateArgs = {
  distinctOn?: InputMaybe<Array<AccessRoleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AccessRoleOrderBy>>;
  where?: InputMaybe<AccessRoleBoolExp>;
};


export type Query_RootAccessRoleByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootAggregateCheckinsArgs = {
  distinctOn?: InputMaybe<Array<CheckinSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CheckinOrderBy>>;
  where?: InputMaybe<CheckinBoolExp>;
};


export type Query_RootAggregateCulturalEventsArgs = {
  distinctOn?: InputMaybe<Array<CulturalEventSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CulturalEventOrderBy>>;
  where?: InputMaybe<CulturalEventBoolExp>;
};


export type Query_RootAggregateVisitsArgs = {
  distinctOn?: InputMaybe<Array<VisitSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<VisitOrderBy>>;
  where?: InputMaybe<VisitBoolExp>;
};


export type Query_RootAttendeesArgs = {
  distinctOn?: InputMaybe<Array<AttendeesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AttendeesOrderBy>>;
  where?: InputMaybe<AttendeesBoolExp>;
};


export type Query_RootAttendeesAggregateArgs = {
  distinctOn?: InputMaybe<Array<AttendeesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AttendeesOrderBy>>;
  where?: InputMaybe<AttendeesBoolExp>;
};


export type Query_RootAuthAliasArgs = {
  distinctOn?: InputMaybe<Array<AuthAliasSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AuthAliasOrderBy>>;
  where?: InputMaybe<AuthAliasBoolExp>;
};


export type Query_RootAuthAliasAggregateArgs = {
  distinctOn?: InputMaybe<Array<AuthAliasSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AuthAliasOrderBy>>;
  where?: InputMaybe<AuthAliasBoolExp>;
};


export type Query_RootAuthAliasByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootAuthLogoutArgs = {
  distinctOn?: InputMaybe<Array<AuthLogoutSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AuthLogoutOrderBy>>;
  where?: InputMaybe<AuthLogoutBoolExp>;
};


export type Query_RootAuthLogoutAggregateArgs = {
  distinctOn?: InputMaybe<Array<AuthLogoutSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AuthLogoutOrderBy>>;
  where?: InputMaybe<AuthLogoutBoolExp>;
};


export type Query_RootAuthLogoutByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootAuthMeArgs = {
  distinctOn?: InputMaybe<Array<AuthSessionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AuthSessionOrderBy>>;
  where?: InputMaybe<AuthSessionBoolExp>;
};


export type Query_RootAuthMeAggregateArgs = {
  distinctOn?: InputMaybe<Array<AuthSessionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AuthSessionOrderBy>>;
  where?: InputMaybe<AuthSessionBoolExp>;
};


export type Query_RootAuthSessionArgs = {
  distinctOn?: InputMaybe<Array<AuthSessionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AuthSessionOrderBy>>;
  where?: InputMaybe<AuthSessionBoolExp>;
};


export type Query_RootAuthSessionAggregateArgs = {
  distinctOn?: InputMaybe<Array<AuthSessionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AuthSessionOrderBy>>;
  where?: InputMaybe<AuthSessionBoolExp>;
};


export type Query_RootAuthSessionByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootAuthTelegramBotArgs = {
  distinctOn?: InputMaybe<Array<AuthTelegramBotSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AuthTelegramBotOrderBy>>;
  where?: InputMaybe<AuthTelegramBotBoolExp>;
};


export type Query_RootAuthTelegramBotAggregateArgs = {
  distinctOn?: InputMaybe<Array<AuthTelegramBotSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AuthTelegramBotOrderBy>>;
  where?: InputMaybe<AuthTelegramBotBoolExp>;
};


export type Query_RootAuthTelegramBotByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootAuthTelegramInputArgs = {
  distinctOn?: InputMaybe<Array<AuthTelegramInputSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AuthTelegramInputOrderBy>>;
  where?: InputMaybe<AuthTelegramInputBoolExp>;
};


export type Query_RootAuthTelegramInputAggregateArgs = {
  distinctOn?: InputMaybe<Array<AuthTelegramInputSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AuthTelegramInputOrderBy>>;
  where?: InputMaybe<AuthTelegramInputBoolExp>;
};


export type Query_RootAuthTelegramInputByPkArgs = {
  hash: Scalars['String']['input'];
};


export type Query_RootAuthUserAggregateArgs = {
  distinctOn?: InputMaybe<Array<AuthUserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AuthUserOrderBy>>;
  where?: InputMaybe<AuthUserBoolExp>;
};


export type Query_RootCheckinArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootCheckinsArgs = {
  distinctOn?: InputMaybe<Array<CheckinSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CheckinOrderBy>>;
  where?: InputMaybe<CheckinBoolExp>;
};


export type Query_RootCulturalEventArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootCulturalEventAttendanceArgs = {
  distinctOn?: InputMaybe<Array<CulturalEventAttendanceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CulturalEventAttendanceOrderBy>>;
  where?: InputMaybe<CulturalEventAttendanceBoolExp>;
};


export type Query_RootCulturalEventAttendanceAggregateArgs = {
  distinctOn?: InputMaybe<Array<CulturalEventAttendanceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CulturalEventAttendanceOrderBy>>;
  where?: InputMaybe<CulturalEventAttendanceBoolExp>;
};


export type Query_RootCulturalEventAttendanceByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootCulturalEventCompensationArgs = {
  distinctOn?: InputMaybe<Array<CulturalEventCompensationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CulturalEventCompensationOrderBy>>;
  where?: InputMaybe<CulturalEventCompensationBoolExp>;
};


export type Query_RootCulturalEventCompensationAggregateArgs = {
  distinctOn?: InputMaybe<Array<CulturalEventCompensationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CulturalEventCompensationOrderBy>>;
  where?: InputMaybe<CulturalEventCompensationBoolExp>;
};


export type Query_RootCulturalEventCompensationByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootCulturalEventStatusArgs = {
  distinctOn?: InputMaybe<Array<CulturalEventStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CulturalEventStatusOrderBy>>;
  where?: InputMaybe<CulturalEventStatusBoolExp>;
};


export type Query_RootCulturalEventStatusAggregateArgs = {
  distinctOn?: InputMaybe<Array<CulturalEventStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CulturalEventStatusOrderBy>>;
  where?: InputMaybe<CulturalEventStatusBoolExp>;
};


export type Query_RootCulturalEventStatusByPkArgs = {
  value: Scalars['String']['input'];
};


export type Query_RootCulturalEventTicketArgs = {
  distinctOn?: InputMaybe<Array<CulturalEventTicketSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CulturalEventTicketOrderBy>>;
  where?: InputMaybe<CulturalEventTicketBoolExp>;
};


export type Query_RootCulturalEventTicketAggregateArgs = {
  distinctOn?: InputMaybe<Array<CulturalEventTicketSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CulturalEventTicketOrderBy>>;
  where?: InputMaybe<CulturalEventTicketBoolExp>;
};


export type Query_RootCulturalEventTicketByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootCulturalEventTicketVisitArgs = {
  distinctOn?: InputMaybe<Array<CulturalEventTicketVisitSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CulturalEventTicketVisitOrderBy>>;
  where?: InputMaybe<CulturalEventTicketVisitBoolExp>;
};


export type Query_RootCulturalEventTicketVisitAggregateArgs = {
  distinctOn?: InputMaybe<Array<CulturalEventTicketVisitSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CulturalEventTicketVisitOrderBy>>;
  where?: InputMaybe<CulturalEventTicketVisitBoolExp>;
};


export type Query_RootCulturalEventTicketVisitByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootCulturalEventsArgs = {
  distinctOn?: InputMaybe<Array<CulturalEventSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CulturalEventOrderBy>>;
  where?: InputMaybe<CulturalEventBoolExp>;
};


export type Query_RootTariffArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootTariffTariffAggregateArgs = {
  distinctOn?: InputMaybe<Array<TariffTariffSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TariffTariffOrderBy>>;
  where?: InputMaybe<TariffTariffBoolExp>;
};


export type Query_RootTariffsArgs = {
  distinctOn?: InputMaybe<Array<TariffTariffSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TariffTariffOrderBy>>;
  where?: InputMaybe<TariffTariffBoolExp>;
};


export type Query_RootUserArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootUsersArgs = {
  distinctOn?: InputMaybe<Array<AuthUserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AuthUserOrderBy>>;
  where?: InputMaybe<AuthUserBoolExp>;
};


export type Query_RootVisitArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootVisitCheckinArgs = {
  distinctOn?: InputMaybe<Array<VisitCheckinSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<VisitCheckinOrderBy>>;
  where?: InputMaybe<VisitCheckinBoolExp>;
};


export type Query_RootVisitCheckinAggregateArgs = {
  distinctOn?: InputMaybe<Array<VisitCheckinSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<VisitCheckinOrderBy>>;
  where?: InputMaybe<VisitCheckinBoolExp>;
};


export type Query_RootVisitCheckinByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootVisitNameArgs = {
  distinctOn?: InputMaybe<Array<VisitNameSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<VisitNameOrderBy>>;
  where?: InputMaybe<VisitNameBoolExp>;
};


export type Query_RootVisitNameAggregateArgs = {
  distinctOn?: InputMaybe<Array<VisitNameSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<VisitNameOrderBy>>;
  where?: InputMaybe<VisitNameBoolExp>;
};


export type Query_RootVisitNameByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootVisitVisitArgs = {
  distinctOn?: InputMaybe<Array<VisitVisitSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<VisitVisitOrderBy>>;
  where?: InputMaybe<VisitVisitBoolExp>;
};


export type Query_RootVisitVisitAggregateArgs = {
  distinctOn?: InputMaybe<Array<VisitVisitSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<VisitVisitOrderBy>>;
  where?: InputMaybe<VisitVisitBoolExp>;
};


export type Query_RootVisitVisitByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootVisitsArgs = {
  distinctOn?: InputMaybe<Array<VisitSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<VisitOrderBy>>;
  where?: InputMaybe<VisitBoolExp>;
};


export type Query_RootZiferblatArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootZiferblatsArgs = {
  distinctOn?: InputMaybe<Array<ZiferblatZiferblatSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ZiferblatZiferblatOrderBy>>;
  where?: InputMaybe<ZiferblatZiferblatBoolExp>;
};


export type Query_RootZiferblatsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ZiferblatZiferblatSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ZiferblatZiferblatOrderBy>>;
  where?: InputMaybe<ZiferblatZiferblatBoolExp>;
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "cultural_event.compensation_currency" */
  CompensationCurrency: Array<CompensationCurrency>;
  /** fetch aggregated fields from the table: "cultural_event.compensation_currency" */
  CompensationCurrencyAggregate: CompensationCurrencyAggregate;
  /** fetch data from the table: "cultural_event.compensation_currency" using primary key columns */
  CompensationCurrencyByPk?: Maybe<CompensationCurrency>;
  /** fetch data from the table in a streaming manner: "cultural_event.compensation_currency" */
  CompensationCurrencyStream: Array<CompensationCurrency>;
  /** fetch data from the table: "cultural_event.compensation_unit" */
  CompensationUnit: Array<CompensationUnit>;
  /** fetch aggregated fields from the table: "cultural_event.compensation_unit" */
  CompensationUnitAggregate: CompensationUnitAggregate;
  /** fetch data from the table: "cultural_event.compensation_unit" using primary key columns */
  CompensationUnitByPk?: Maybe<CompensationUnit>;
  /** fetch data from the table in a streaming manner: "cultural_event.compensation_unit" */
  CompensationUnitStream: Array<CompensationUnit>;
  /** fetch data from the table: "access.access" */
  accessAccess: Array<AccessAccess>;
  /** fetch aggregated fields from the table: "access.access" */
  accessAccessAggregate: AccessAccessAggregate;
  /** fetch data from the table in a streaming manner: "access.access" */
  accessAccessStream: Array<AccessAccess>;
  /** fetch data from the table: "access.expire" */
  accessExpire: Array<AccessExpire>;
  /** fetch aggregated fields from the table: "access.expire" */
  accessExpireAggregate: AccessExpireAggregate;
  /** fetch data from the table: "access.expire" using primary key columns */
  accessExpireByPk?: Maybe<AccessExpire>;
  /** fetch data from the table in a streaming manner: "access.expire" */
  accessExpireStream: Array<AccessExpire>;
  /** fetch data from the table: "access.grant" */
  accessGrant: Array<AccessGrant>;
  /** fetch aggregated fields from the table: "access.grant" */
  accessGrantAggregate: AccessGrantAggregate;
  /** fetch data from the table: "access.grant" using primary key columns */
  accessGrantByPk?: Maybe<AccessGrant>;
  /** fetch data from the table in a streaming manner: "access.grant" */
  accessGrantStream: Array<AccessGrant>;
  /** fetch data from the table: "access.permission" */
  accessPermission: Array<AccessPermission>;
  /** fetch aggregated fields from the table: "access.permission" */
  accessPermissionAggregate: AccessPermissionAggregate;
  /** fetch data from the table: "access.permission" using primary key columns */
  accessPermissionByPk?: Maybe<AccessPermission>;
  /** fetch data from the table in a streaming manner: "access.permission" */
  accessPermissionStream: Array<AccessPermission>;
  /** fetch data from the table: "access.role" */
  accessRole: Array<AccessRole>;
  /** fetch aggregated fields from the table: "access.role" */
  accessRoleAggregate: AccessRoleAggregate;
  /** fetch data from the table: "access.role" using primary key columns */
  accessRoleByPk?: Maybe<AccessRole>;
  /** fetch data from the table in a streaming manner: "access.role" */
  accessRoleStream: Array<AccessRole>;
  /** fetch aggregated fields from the table: "checkin" */
  aggregateCheckins: CheckinAggregate;
  /** fetch aggregated fields from the table: "cultural_event.cultural_event" */
  aggregateCulturalEvents: CulturalEventAggregate;
  /** fetch aggregated fields from the table: "visit" */
  aggregateVisits: VisitAggregate;
  /** An array relationship */
  attendees: Array<Attendees>;
  /** An aggregate relationship */
  attendeesAggregate: AttendeesAggregate;
  /** fetch data from the table in a streaming manner: "cultural_event.attendees" */
  attendeesStream: Array<Attendees>;
  /** fetch data from the table: "auth.alias" */
  authAlias: Array<AuthAlias>;
  /** fetch aggregated fields from the table: "auth.alias" */
  authAliasAggregate: AuthAliasAggregate;
  /** fetch data from the table: "auth.alias" using primary key columns */
  authAliasByPk?: Maybe<AuthAlias>;
  /** fetch data from the table in a streaming manner: "auth.alias" */
  authAliasStream: Array<AuthAlias>;
  /** fetch data from the table: "auth.logout" */
  authLogout: Array<AuthLogout>;
  /** fetch aggregated fields from the table: "auth.logout" */
  authLogoutAggregate: AuthLogoutAggregate;
  /** fetch data from the table: "auth.logout" using primary key columns */
  authLogoutByPk?: Maybe<AuthLogout>;
  /** fetch data from the table in a streaming manner: "auth.logout" */
  authLogoutStream: Array<AuthLogout>;
  /** execute function "auth.me" which returns "auth.session" */
  authMe?: Maybe<AuthSession>;
  /** execute function "auth.me" and query aggregates on result of table type "auth.session" */
  authMeAggregate: AuthSessionAggregate;
  /** fetch data from the table: "auth.session" */
  authSession: Array<AuthSession>;
  /** fetch aggregated fields from the table: "auth.session" */
  authSessionAggregate: AuthSessionAggregate;
  /** fetch data from the table: "auth.session" using primary key columns */
  authSessionByPk?: Maybe<AuthSession>;
  /** fetch data from the table in a streaming manner: "auth.session" */
  authSessionStream: Array<AuthSession>;
  /** fetch data from the table: "auth.telegram_bot" */
  authTelegramBot: Array<AuthTelegramBot>;
  /** fetch aggregated fields from the table: "auth.telegram_bot" */
  authTelegramBotAggregate: AuthTelegramBotAggregate;
  /** fetch data from the table: "auth.telegram_bot" using primary key columns */
  authTelegramBotByPk?: Maybe<AuthTelegramBot>;
  /** fetch data from the table in a streaming manner: "auth.telegram_bot" */
  authTelegramBotStream: Array<AuthTelegramBot>;
  /** fetch data from the table: "auth.telegram_input" */
  authTelegramInput: Array<AuthTelegramInput>;
  /** fetch aggregated fields from the table: "auth.telegram_input" */
  authTelegramInputAggregate: AuthTelegramInputAggregate;
  /** fetch data from the table: "auth.telegram_input" using primary key columns */
  authTelegramInputByPk?: Maybe<AuthTelegramInput>;
  /** fetch data from the table in a streaming manner: "auth.telegram_input" */
  authTelegramInputStream: Array<AuthTelegramInput>;
  /** fetch aggregated fields from the table: "auth.user" */
  authUserAggregate: AuthUserAggregate;
  /** fetch data from the table in a streaming manner: "auth.user" */
  authUserStream: Array<AuthUser>;
  /** fetch data from the table: "checkin" using primary key columns */
  checkin?: Maybe<Checkin>;
  /** fetch data from the table: "checkin" */
  checkins: Array<Checkin>;
  /** fetch data from the table in a streaming manner: "checkin" */
  checkinsStream: Array<Checkin>;
  /** fetch data from the table: "cultural_event.cultural_event" using primary key columns */
  culturalEvent?: Maybe<CulturalEvent>;
  /** fetch data from the table: "cultural_event.attendance" */
  culturalEventAttendance: Array<CulturalEventAttendance>;
  /** fetch aggregated fields from the table: "cultural_event.attendance" */
  culturalEventAttendanceAggregate: CulturalEventAttendanceAggregate;
  /** fetch data from the table: "cultural_event.attendance" using primary key columns */
  culturalEventAttendanceByPk?: Maybe<CulturalEventAttendance>;
  /** fetch data from the table in a streaming manner: "cultural_event.attendance" */
  culturalEventAttendanceStream: Array<CulturalEventAttendance>;
  /** fetch data from the table: "cultural_event.compensation" */
  culturalEventCompensation: Array<CulturalEventCompensation>;
  /** fetch aggregated fields from the table: "cultural_event.compensation" */
  culturalEventCompensationAggregate: CulturalEventCompensationAggregate;
  /** fetch data from the table: "cultural_event.compensation" using primary key columns */
  culturalEventCompensationByPk?: Maybe<CulturalEventCompensation>;
  /** fetch data from the table in a streaming manner: "cultural_event.compensation" */
  culturalEventCompensationStream: Array<CulturalEventCompensation>;
  /** fetch data from the table: "cultural_event.status" */
  culturalEventStatus: Array<CulturalEventStatus>;
  /** fetch aggregated fields from the table: "cultural_event.status" */
  culturalEventStatusAggregate: CulturalEventStatusAggregate;
  /** fetch data from the table: "cultural_event.status" using primary key columns */
  culturalEventStatusByPk?: Maybe<CulturalEventStatus>;
  /** fetch data from the table in a streaming manner: "cultural_event.status" */
  culturalEventStatusStream: Array<CulturalEventStatus>;
  /** fetch data from the table: "cultural_event.ticket" */
  culturalEventTicket: Array<CulturalEventTicket>;
  /** fetch aggregated fields from the table: "cultural_event.ticket" */
  culturalEventTicketAggregate: CulturalEventTicketAggregate;
  /** fetch data from the table: "cultural_event.ticket" using primary key columns */
  culturalEventTicketByPk?: Maybe<CulturalEventTicket>;
  /** fetch data from the table in a streaming manner: "cultural_event.ticket" */
  culturalEventTicketStream: Array<CulturalEventTicket>;
  /** fetch data from the table: "cultural_event.ticket_visit" */
  culturalEventTicketVisit: Array<CulturalEventTicketVisit>;
  /** fetch aggregated fields from the table: "cultural_event.ticket_visit" */
  culturalEventTicketVisitAggregate: CulturalEventTicketVisitAggregate;
  /** fetch data from the table: "cultural_event.ticket_visit" using primary key columns */
  culturalEventTicketVisitByPk?: Maybe<CulturalEventTicketVisit>;
  /** fetch data from the table in a streaming manner: "cultural_event.ticket_visit" */
  culturalEventTicketVisitStream: Array<CulturalEventTicketVisit>;
  /** fetch data from the table: "cultural_event.cultural_event" */
  culturalEvents: Array<CulturalEvent>;
  /** fetch data from the table in a streaming manner: "cultural_event.cultural_event" */
  culturalEventsStream: Array<CulturalEvent>;
  /** fetch data from the table: "tariff.tariff" using primary key columns */
  tariff?: Maybe<TariffTariff>;
  /** fetch aggregated fields from the table: "tariff.tariff" */
  tariffTariffAggregate: TariffTariffAggregate;
  /** fetch data from the table in a streaming manner: "tariff.tariff" */
  tariffTariffStream: Array<TariffTariff>;
  /** fetch data from the table: "tariff.tariff" */
  tariffs: Array<TariffTariff>;
  /** fetch data from the table: "auth.user" using primary key columns */
  user?: Maybe<AuthUser>;
  /** fetch data from the table: "auth.user" */
  users: Array<AuthUser>;
  /** fetch data from the table: "visit" using primary key columns */
  visit?: Maybe<Visit>;
  /** fetch data from the table: "visit.checkin" */
  visitCheckin: Array<VisitCheckin>;
  /** fetch aggregated fields from the table: "visit.checkin" */
  visitCheckinAggregate: VisitCheckinAggregate;
  /** fetch data from the table: "visit.checkin" using primary key columns */
  visitCheckinByPk?: Maybe<VisitCheckin>;
  /** fetch data from the table in a streaming manner: "visit.checkin" */
  visitCheckinStream: Array<VisitCheckin>;
  /** fetch data from the table: "visit.name" */
  visitName: Array<VisitName>;
  /** fetch aggregated fields from the table: "visit.name" */
  visitNameAggregate: VisitNameAggregate;
  /** fetch data from the table: "visit.name" using primary key columns */
  visitNameByPk?: Maybe<VisitName>;
  /** fetch data from the table in a streaming manner: "visit.name" */
  visitNameStream: Array<VisitName>;
  /** fetch data from the table: "visit.visit" */
  visitVisit: Array<VisitVisit>;
  /** fetch aggregated fields from the table: "visit.visit" */
  visitVisitAggregate: VisitVisitAggregate;
  /** fetch data from the table: "visit.visit" using primary key columns */
  visitVisitByPk?: Maybe<VisitVisit>;
  /** fetch data from the table in a streaming manner: "visit.visit" */
  visitVisitStream: Array<VisitVisit>;
  /** fetch data from the table: "visit" */
  visits: Array<Visit>;
  /** fetch data from the table in a streaming manner: "visit" */
  visitsStream: Array<Visit>;
  /** fetch data from the table: "ziferblat.ziferblat" using primary key columns */
  ziferblat?: Maybe<ZiferblatZiferblat>;
  /** fetch data from the table in a streaming manner: "ziferblat.ziferblat" */
  ziferblatStream: Array<ZiferblatZiferblat>;
  /** fetch data from the table: "ziferblat.ziferblat" */
  ziferblats: Array<ZiferblatZiferblat>;
  /** fetch aggregated fields from the table: "ziferblat.ziferblat" */
  ziferblatsAggregate: ZiferblatZiferblatAggregate;
};


export type Subscription_RootCompensationCurrencyArgs = {
  distinctOn?: InputMaybe<Array<CompensationCurrencySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CompensationCurrencyOrderBy>>;
  where?: InputMaybe<CompensationCurrencyBoolExp>;
};


export type Subscription_RootCompensationCurrencyAggregateArgs = {
  distinctOn?: InputMaybe<Array<CompensationCurrencySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CompensationCurrencyOrderBy>>;
  where?: InputMaybe<CompensationCurrencyBoolExp>;
};


export type Subscription_RootCompensationCurrencyByPkArgs = {
  value: Scalars['String']['input'];
};


export type Subscription_RootCompensationCurrencyStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<CompensationCurrencyStreamCursorInput>>;
  where?: InputMaybe<CompensationCurrencyBoolExp>;
};


export type Subscription_RootCompensationUnitArgs = {
  distinctOn?: InputMaybe<Array<CompensationUnitSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CompensationUnitOrderBy>>;
  where?: InputMaybe<CompensationUnitBoolExp>;
};


export type Subscription_RootCompensationUnitAggregateArgs = {
  distinctOn?: InputMaybe<Array<CompensationUnitSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CompensationUnitOrderBy>>;
  where?: InputMaybe<CompensationUnitBoolExp>;
};


export type Subscription_RootCompensationUnitByPkArgs = {
  value: Scalars['String']['input'];
};


export type Subscription_RootCompensationUnitStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<CompensationUnitStreamCursorInput>>;
  where?: InputMaybe<CompensationUnitBoolExp>;
};


export type Subscription_RootAccessAccessArgs = {
  distinctOn?: InputMaybe<Array<AccessAccessSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AccessAccessOrderBy>>;
  where?: InputMaybe<AccessAccessBoolExp>;
};


export type Subscription_RootAccessAccessAggregateArgs = {
  distinctOn?: InputMaybe<Array<AccessAccessSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AccessAccessOrderBy>>;
  where?: InputMaybe<AccessAccessBoolExp>;
};


export type Subscription_RootAccessAccessStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AccessAccessStreamCursorInput>>;
  where?: InputMaybe<AccessAccessBoolExp>;
};


export type Subscription_RootAccessExpireArgs = {
  distinctOn?: InputMaybe<Array<AccessExpireSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AccessExpireOrderBy>>;
  where?: InputMaybe<AccessExpireBoolExp>;
};


export type Subscription_RootAccessExpireAggregateArgs = {
  distinctOn?: InputMaybe<Array<AccessExpireSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AccessExpireOrderBy>>;
  where?: InputMaybe<AccessExpireBoolExp>;
};


export type Subscription_RootAccessExpireByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootAccessExpireStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AccessExpireStreamCursorInput>>;
  where?: InputMaybe<AccessExpireBoolExp>;
};


export type Subscription_RootAccessGrantArgs = {
  distinctOn?: InputMaybe<Array<AccessGrantSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AccessGrantOrderBy>>;
  where?: InputMaybe<AccessGrantBoolExp>;
};


export type Subscription_RootAccessGrantAggregateArgs = {
  distinctOn?: InputMaybe<Array<AccessGrantSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AccessGrantOrderBy>>;
  where?: InputMaybe<AccessGrantBoolExp>;
};


export type Subscription_RootAccessGrantByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootAccessGrantStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AccessGrantStreamCursorInput>>;
  where?: InputMaybe<AccessGrantBoolExp>;
};


export type Subscription_RootAccessPermissionArgs = {
  distinctOn?: InputMaybe<Array<AccessPermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AccessPermissionOrderBy>>;
  where?: InputMaybe<AccessPermissionBoolExp>;
};


export type Subscription_RootAccessPermissionAggregateArgs = {
  distinctOn?: InputMaybe<Array<AccessPermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AccessPermissionOrderBy>>;
  where?: InputMaybe<AccessPermissionBoolExp>;
};


export type Subscription_RootAccessPermissionByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootAccessPermissionStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AccessPermissionStreamCursorInput>>;
  where?: InputMaybe<AccessPermissionBoolExp>;
};


export type Subscription_RootAccessRoleArgs = {
  distinctOn?: InputMaybe<Array<AccessRoleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AccessRoleOrderBy>>;
  where?: InputMaybe<AccessRoleBoolExp>;
};


export type Subscription_RootAccessRoleAggregateArgs = {
  distinctOn?: InputMaybe<Array<AccessRoleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AccessRoleOrderBy>>;
  where?: InputMaybe<AccessRoleBoolExp>;
};


export type Subscription_RootAccessRoleByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootAccessRoleStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AccessRoleStreamCursorInput>>;
  where?: InputMaybe<AccessRoleBoolExp>;
};


export type Subscription_RootAggregateCheckinsArgs = {
  distinctOn?: InputMaybe<Array<CheckinSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CheckinOrderBy>>;
  where?: InputMaybe<CheckinBoolExp>;
};


export type Subscription_RootAggregateCulturalEventsArgs = {
  distinctOn?: InputMaybe<Array<CulturalEventSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CulturalEventOrderBy>>;
  where?: InputMaybe<CulturalEventBoolExp>;
};


export type Subscription_RootAggregateVisitsArgs = {
  distinctOn?: InputMaybe<Array<VisitSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<VisitOrderBy>>;
  where?: InputMaybe<VisitBoolExp>;
};


export type Subscription_RootAttendeesArgs = {
  distinctOn?: InputMaybe<Array<AttendeesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AttendeesOrderBy>>;
  where?: InputMaybe<AttendeesBoolExp>;
};


export type Subscription_RootAttendeesAggregateArgs = {
  distinctOn?: InputMaybe<Array<AttendeesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AttendeesOrderBy>>;
  where?: InputMaybe<AttendeesBoolExp>;
};


export type Subscription_RootAttendeesStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AttendeesStreamCursorInput>>;
  where?: InputMaybe<AttendeesBoolExp>;
};


export type Subscription_RootAuthAliasArgs = {
  distinctOn?: InputMaybe<Array<AuthAliasSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AuthAliasOrderBy>>;
  where?: InputMaybe<AuthAliasBoolExp>;
};


export type Subscription_RootAuthAliasAggregateArgs = {
  distinctOn?: InputMaybe<Array<AuthAliasSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AuthAliasOrderBy>>;
  where?: InputMaybe<AuthAliasBoolExp>;
};


export type Subscription_RootAuthAliasByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootAuthAliasStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AuthAliasStreamCursorInput>>;
  where?: InputMaybe<AuthAliasBoolExp>;
};


export type Subscription_RootAuthLogoutArgs = {
  distinctOn?: InputMaybe<Array<AuthLogoutSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AuthLogoutOrderBy>>;
  where?: InputMaybe<AuthLogoutBoolExp>;
};


export type Subscription_RootAuthLogoutAggregateArgs = {
  distinctOn?: InputMaybe<Array<AuthLogoutSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AuthLogoutOrderBy>>;
  where?: InputMaybe<AuthLogoutBoolExp>;
};


export type Subscription_RootAuthLogoutByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootAuthLogoutStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AuthLogoutStreamCursorInput>>;
  where?: InputMaybe<AuthLogoutBoolExp>;
};


export type Subscription_RootAuthMeArgs = {
  distinctOn?: InputMaybe<Array<AuthSessionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AuthSessionOrderBy>>;
  where?: InputMaybe<AuthSessionBoolExp>;
};


export type Subscription_RootAuthMeAggregateArgs = {
  distinctOn?: InputMaybe<Array<AuthSessionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AuthSessionOrderBy>>;
  where?: InputMaybe<AuthSessionBoolExp>;
};


export type Subscription_RootAuthSessionArgs = {
  distinctOn?: InputMaybe<Array<AuthSessionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AuthSessionOrderBy>>;
  where?: InputMaybe<AuthSessionBoolExp>;
};


export type Subscription_RootAuthSessionAggregateArgs = {
  distinctOn?: InputMaybe<Array<AuthSessionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AuthSessionOrderBy>>;
  where?: InputMaybe<AuthSessionBoolExp>;
};


export type Subscription_RootAuthSessionByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootAuthSessionStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AuthSessionStreamCursorInput>>;
  where?: InputMaybe<AuthSessionBoolExp>;
};


export type Subscription_RootAuthTelegramBotArgs = {
  distinctOn?: InputMaybe<Array<AuthTelegramBotSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AuthTelegramBotOrderBy>>;
  where?: InputMaybe<AuthTelegramBotBoolExp>;
};


export type Subscription_RootAuthTelegramBotAggregateArgs = {
  distinctOn?: InputMaybe<Array<AuthTelegramBotSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AuthTelegramBotOrderBy>>;
  where?: InputMaybe<AuthTelegramBotBoolExp>;
};


export type Subscription_RootAuthTelegramBotByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootAuthTelegramBotStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AuthTelegramBotStreamCursorInput>>;
  where?: InputMaybe<AuthTelegramBotBoolExp>;
};


export type Subscription_RootAuthTelegramInputArgs = {
  distinctOn?: InputMaybe<Array<AuthTelegramInputSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AuthTelegramInputOrderBy>>;
  where?: InputMaybe<AuthTelegramInputBoolExp>;
};


export type Subscription_RootAuthTelegramInputAggregateArgs = {
  distinctOn?: InputMaybe<Array<AuthTelegramInputSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AuthTelegramInputOrderBy>>;
  where?: InputMaybe<AuthTelegramInputBoolExp>;
};


export type Subscription_RootAuthTelegramInputByPkArgs = {
  hash: Scalars['String']['input'];
};


export type Subscription_RootAuthTelegramInputStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AuthTelegramInputStreamCursorInput>>;
  where?: InputMaybe<AuthTelegramInputBoolExp>;
};


export type Subscription_RootAuthUserAggregateArgs = {
  distinctOn?: InputMaybe<Array<AuthUserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AuthUserOrderBy>>;
  where?: InputMaybe<AuthUserBoolExp>;
};


export type Subscription_RootAuthUserStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AuthUserStreamCursorInput>>;
  where?: InputMaybe<AuthUserBoolExp>;
};


export type Subscription_RootCheckinArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootCheckinsArgs = {
  distinctOn?: InputMaybe<Array<CheckinSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CheckinOrderBy>>;
  where?: InputMaybe<CheckinBoolExp>;
};


export type Subscription_RootCheckinsStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<CheckinStreamCursorInput>>;
  where?: InputMaybe<CheckinBoolExp>;
};


export type Subscription_RootCulturalEventArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootCulturalEventAttendanceArgs = {
  distinctOn?: InputMaybe<Array<CulturalEventAttendanceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CulturalEventAttendanceOrderBy>>;
  where?: InputMaybe<CulturalEventAttendanceBoolExp>;
};


export type Subscription_RootCulturalEventAttendanceAggregateArgs = {
  distinctOn?: InputMaybe<Array<CulturalEventAttendanceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CulturalEventAttendanceOrderBy>>;
  where?: InputMaybe<CulturalEventAttendanceBoolExp>;
};


export type Subscription_RootCulturalEventAttendanceByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootCulturalEventAttendanceStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<CulturalEventAttendanceStreamCursorInput>>;
  where?: InputMaybe<CulturalEventAttendanceBoolExp>;
};


export type Subscription_RootCulturalEventCompensationArgs = {
  distinctOn?: InputMaybe<Array<CulturalEventCompensationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CulturalEventCompensationOrderBy>>;
  where?: InputMaybe<CulturalEventCompensationBoolExp>;
};


export type Subscription_RootCulturalEventCompensationAggregateArgs = {
  distinctOn?: InputMaybe<Array<CulturalEventCompensationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CulturalEventCompensationOrderBy>>;
  where?: InputMaybe<CulturalEventCompensationBoolExp>;
};


export type Subscription_RootCulturalEventCompensationByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootCulturalEventCompensationStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<CulturalEventCompensationStreamCursorInput>>;
  where?: InputMaybe<CulturalEventCompensationBoolExp>;
};


export type Subscription_RootCulturalEventStatusArgs = {
  distinctOn?: InputMaybe<Array<CulturalEventStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CulturalEventStatusOrderBy>>;
  where?: InputMaybe<CulturalEventStatusBoolExp>;
};


export type Subscription_RootCulturalEventStatusAggregateArgs = {
  distinctOn?: InputMaybe<Array<CulturalEventStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CulturalEventStatusOrderBy>>;
  where?: InputMaybe<CulturalEventStatusBoolExp>;
};


export type Subscription_RootCulturalEventStatusByPkArgs = {
  value: Scalars['String']['input'];
};


export type Subscription_RootCulturalEventStatusStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<CulturalEventStatusStreamCursorInput>>;
  where?: InputMaybe<CulturalEventStatusBoolExp>;
};


export type Subscription_RootCulturalEventTicketArgs = {
  distinctOn?: InputMaybe<Array<CulturalEventTicketSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CulturalEventTicketOrderBy>>;
  where?: InputMaybe<CulturalEventTicketBoolExp>;
};


export type Subscription_RootCulturalEventTicketAggregateArgs = {
  distinctOn?: InputMaybe<Array<CulturalEventTicketSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CulturalEventTicketOrderBy>>;
  where?: InputMaybe<CulturalEventTicketBoolExp>;
};


export type Subscription_RootCulturalEventTicketByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootCulturalEventTicketStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<CulturalEventTicketStreamCursorInput>>;
  where?: InputMaybe<CulturalEventTicketBoolExp>;
};


export type Subscription_RootCulturalEventTicketVisitArgs = {
  distinctOn?: InputMaybe<Array<CulturalEventTicketVisitSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CulturalEventTicketVisitOrderBy>>;
  where?: InputMaybe<CulturalEventTicketVisitBoolExp>;
};


export type Subscription_RootCulturalEventTicketVisitAggregateArgs = {
  distinctOn?: InputMaybe<Array<CulturalEventTicketVisitSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CulturalEventTicketVisitOrderBy>>;
  where?: InputMaybe<CulturalEventTicketVisitBoolExp>;
};


export type Subscription_RootCulturalEventTicketVisitByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootCulturalEventTicketVisitStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<CulturalEventTicketVisitStreamCursorInput>>;
  where?: InputMaybe<CulturalEventTicketVisitBoolExp>;
};


export type Subscription_RootCulturalEventsArgs = {
  distinctOn?: InputMaybe<Array<CulturalEventSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CulturalEventOrderBy>>;
  where?: InputMaybe<CulturalEventBoolExp>;
};


export type Subscription_RootCulturalEventsStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<CulturalEventStreamCursorInput>>;
  where?: InputMaybe<CulturalEventBoolExp>;
};


export type Subscription_RootTariffArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootTariffTariffAggregateArgs = {
  distinctOn?: InputMaybe<Array<TariffTariffSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TariffTariffOrderBy>>;
  where?: InputMaybe<TariffTariffBoolExp>;
};


export type Subscription_RootTariffTariffStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<TariffTariffStreamCursorInput>>;
  where?: InputMaybe<TariffTariffBoolExp>;
};


export type Subscription_RootTariffsArgs = {
  distinctOn?: InputMaybe<Array<TariffTariffSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TariffTariffOrderBy>>;
  where?: InputMaybe<TariffTariffBoolExp>;
};


export type Subscription_RootUserArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootUsersArgs = {
  distinctOn?: InputMaybe<Array<AuthUserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AuthUserOrderBy>>;
  where?: InputMaybe<AuthUserBoolExp>;
};


export type Subscription_RootVisitArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootVisitCheckinArgs = {
  distinctOn?: InputMaybe<Array<VisitCheckinSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<VisitCheckinOrderBy>>;
  where?: InputMaybe<VisitCheckinBoolExp>;
};


export type Subscription_RootVisitCheckinAggregateArgs = {
  distinctOn?: InputMaybe<Array<VisitCheckinSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<VisitCheckinOrderBy>>;
  where?: InputMaybe<VisitCheckinBoolExp>;
};


export type Subscription_RootVisitCheckinByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootVisitCheckinStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<VisitCheckinStreamCursorInput>>;
  where?: InputMaybe<VisitCheckinBoolExp>;
};


export type Subscription_RootVisitNameArgs = {
  distinctOn?: InputMaybe<Array<VisitNameSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<VisitNameOrderBy>>;
  where?: InputMaybe<VisitNameBoolExp>;
};


export type Subscription_RootVisitNameAggregateArgs = {
  distinctOn?: InputMaybe<Array<VisitNameSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<VisitNameOrderBy>>;
  where?: InputMaybe<VisitNameBoolExp>;
};


export type Subscription_RootVisitNameByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootVisitNameStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<VisitNameStreamCursorInput>>;
  where?: InputMaybe<VisitNameBoolExp>;
};


export type Subscription_RootVisitVisitArgs = {
  distinctOn?: InputMaybe<Array<VisitVisitSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<VisitVisitOrderBy>>;
  where?: InputMaybe<VisitVisitBoolExp>;
};


export type Subscription_RootVisitVisitAggregateArgs = {
  distinctOn?: InputMaybe<Array<VisitVisitSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<VisitVisitOrderBy>>;
  where?: InputMaybe<VisitVisitBoolExp>;
};


export type Subscription_RootVisitVisitByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootVisitVisitStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<VisitVisitStreamCursorInput>>;
  where?: InputMaybe<VisitVisitBoolExp>;
};


export type Subscription_RootVisitsArgs = {
  distinctOn?: InputMaybe<Array<VisitSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<VisitOrderBy>>;
  where?: InputMaybe<VisitBoolExp>;
};


export type Subscription_RootVisitsStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<VisitStreamCursorInput>>;
  where?: InputMaybe<VisitBoolExp>;
};


export type Subscription_RootZiferblatArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootZiferblatStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ZiferblatZiferblatStreamCursorInput>>;
  where?: InputMaybe<ZiferblatZiferblatBoolExp>;
};


export type Subscription_RootZiferblatsArgs = {
  distinctOn?: InputMaybe<Array<ZiferblatZiferblatSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ZiferblatZiferblatOrderBy>>;
  where?: InputMaybe<ZiferblatZiferblatBoolExp>;
};


export type Subscription_RootZiferblatsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ZiferblatZiferblatSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ZiferblatZiferblatOrderBy>>;
  where?: InputMaybe<ZiferblatZiferblatBoolExp>;
};

export type AddRoleMutationVariables = Exact<{
  roleId: Scalars['uuid']['input'];
  userId: Scalars['uuid']['input'];
  ziferblatId: Scalars['uuid']['input'];
}>;


export type AddRoleMutation = { __typename?: 'mutation_root', addRole?: { __typename?: 'AccessGrant', id: string, subject: { __typename?: 'AuthUser', id: string, avatarUrl?: string | null, alias: Array<{ __typename?: 'AuthAlias', id: string, type: string, value: string }>, roles: Array<{ __typename?: 'AccessGrant', id: string, role: { __typename?: 'AccessRole', id: string, name: string }, ziferblat?: { __typename?: 'ZiferblatZiferblat', id: string, name: string, avatarUrl: string } | null }> } } | null };

export type AddTariffMutationVariables = Exact<{
  activeFrom: Scalars['timestamptz']['input'];
  stopCheck: Scalars['smallint']['input'];
  hourPrice: Array<Scalars['smallint']['input']> | Scalars['smallint']['input'];
  ziferblatId: Scalars['uuid']['input'];
}>;


export type AddTariffMutation = { __typename?: 'mutation_root', addTariff?: { __typename?: 'TariffTariff', id: string, hourPrice: Array<number>, stopCheck: number, activeFrom: Date, activeTo?: Date | null } | null };

export type AttendanceQueryVariables = Exact<{
  id: Scalars['uuid']['input'];
}>;


export type AttendanceQuery = { __typename?: 'query_root', attendees: Array<{ __typename?: 'attendees', id?: string | null, startedAt?: Date | null, endedAt?: Date | null, sum?: number | null, visit?: { __typename?: 'Visit', id: string, name?: string | null } | null }> };

export type ChangeVisitNameMutationVariables = Exact<{
  id: Scalars['uuid']['input'];
  name: Scalars['String']['input'];
}>;


export type ChangeVisitNameMutation = { __typename?: 'mutation_root', updateCheckin?: { __typename?: 'Checkin', id: string, checkedInAt: Date, name?: string | null, sum?: number | null, attendance: Array<{ __typename?: 'CulturalEventAttendance', culturalEvent: { __typename?: 'CulturalEvent', id: string, title: string } }>, user?: { __typename?: 'AuthUser', id: string } | null } | null };

export type CheckInMutationVariables = Exact<{
  name: Scalars['String']['input'];
  ziferblatId: Scalars['uuid']['input'];
  attendance: Array<CulturalEventAttendanceInsertInput> | CulturalEventAttendanceInsertInput;
  checkedInAt?: InputMaybe<Scalars['timestamptz']['input']>;
}>;


export type CheckInMutation = { __typename?: 'mutation_root', addCheckin?: { __typename?: 'Checkin', id: string, checkedInAt: Date, name?: string | null, sum?: number | null, attendance: Array<{ __typename?: 'CulturalEventAttendance', culturalEvent: { __typename?: 'CulturalEvent', id: string, title: string } }>, user?: { __typename?: 'AuthUser', id: string } | null } | null };

export type CheckOutMutationVariables = Exact<{
  id: Scalars['uuid']['input'];
  sum: Scalars['Int']['input'];
}>;


export type CheckOutMutation = { __typename?: 'mutation_root', checkOut?: { __typename?: 'Visit', id: string } | null };

export type CheckinFragment = { __typename?: 'Checkin', id: string, checkedInAt: Date, name?: string | null, sum?: number | null, attendance: Array<{ __typename?: 'CulturalEventAttendance', culturalEvent: { __typename?: 'CulturalEvent', id: string, title: string } }>, user?: { __typename?: 'AuthUser', id: string } | null };

export type CheckinsQueryVariables = Exact<{
  ziferblatId: Scalars['uuid']['input'];
  startsAt: Scalars['timestamptz']['input'];
  endsAt: Scalars['timestamptz']['input'];
}>;


export type CheckinsQuery = { __typename?: 'query_root', checkins: Array<{ __typename?: 'Checkin', id: string, checkedInAt: Date, name?: string | null, sum?: number | null, attendance: Array<{ __typename?: 'CulturalEventAttendance', culturalEvent: { __typename?: 'CulturalEvent', id: string, title: string } }>, user?: { __typename?: 'AuthUser', id: string } | null }>, upcomingCulturalEvents: Array<{ __typename?: 'CulturalEvent', id: string, title: string, startsAt: Date, endsAt: Date, publicDescription: string, internalComment: string, status: CulturalEventStatusEnum, ziferblatId: string, compensation?: { __typename?: 'CulturalEventCompensation', id: string, currency: CompensationCurrencyEnum, amount: number, unit: CompensationUnitEnum } | null }> };

export type CulturalEventFragment = { __typename?: 'CulturalEvent', id: string, title: string, startsAt: Date, endsAt: Date, publicDescription: string, internalComment: string, status: CulturalEventStatusEnum, ziferblatId: string, compensation?: { __typename?: 'CulturalEventCompensation', id: string, currency: CompensationCurrencyEnum, amount: number, unit: CompensationUnitEnum } | null };

export type CulturalEventQueryVariables = Exact<{
  id: Scalars['uuid']['input'];
}>;


export type CulturalEventQuery = { __typename?: 'query_root', culturalEvent?: { __typename?: 'CulturalEvent', id: string, title: string, startsAt: Date, endsAt: Date, publicDescription: string, internalComment: string, status: CulturalEventStatusEnum, ziferblatId: string, compensation?: { __typename?: 'CulturalEventCompensation', id: string, currency: CompensationCurrencyEnum, amount: number, unit: CompensationUnitEnum } | null } | null };

export type CulturalEventsQueryVariables = Exact<{
  where?: InputMaybe<CulturalEventBoolExp>;
}>;


export type CulturalEventsQuery = { __typename?: 'query_root', culturalEvents: Array<{ __typename?: 'CulturalEvent', id: string, title: string, startsAt: Date }> };

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = { __typename?: 'mutation_root', logout?: { __typename?: 'AuthLogout', id: string } | null };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'query_root', telegramBot: Array<{ __typename?: 'AuthTelegramBot', name: string, id: number }>, me?: { __typename?: 'AuthSession', id: string, token: string, user?: { __typename?: 'AuthUser', id: string, avatarUrl?: string | null, alias: Array<{ __typename?: 'AuthAlias', id: string, type: string, value: string }>, roles: Array<{ __typename?: 'AccessGrant', id: string, role: { __typename?: 'AccessRole', id: string, name: string }, ziferblat?: { __typename?: 'ZiferblatZiferblat', id: string, name: string, avatarUrl: string } | null }> } | null } | null };

export type RemoveRoleMutationVariables = Exact<{
  id: Scalars['uuid']['input'];
}>;


export type RemoveRoleMutation = { __typename?: 'mutation_root', removeGrant?: { __typename?: 'AccessExpire', grant?: { __typename?: 'AccessGrant', subject: { __typename?: 'AuthUser', id: string, avatarUrl?: string | null, alias: Array<{ __typename?: 'AuthAlias', id: string, type: string, value: string }>, roles: Array<{ __typename?: 'AccessGrant', id: string, role: { __typename?: 'AccessRole', id: string, name: string }, ziferblat?: { __typename?: 'ZiferblatZiferblat', id: string, name: string, avatarUrl: string } | null }> } } | null } | null };

export type RolesQueryVariables = Exact<{ [key: string]: never; }>;


export type RolesQuery = { __typename?: 'query_root', accessRole: Array<{ __typename?: 'AccessRole', id: string, name: string }> };

export type SearchAliasQueryVariables = Exact<{
  term: Scalars['String']['input'];
}>;


export type SearchAliasQuery = { __typename?: 'query_root', authAlias: Array<{ __typename?: 'AuthAlias', id: string, user?: { __typename?: 'AuthUser', id: string, alias: Array<{ __typename?: 'AuthAlias', id: string, type: string, value: string }> } | null }> };

export type SessionFragment = { __typename?: 'AuthSession', id: string, token: string, user?: { __typename?: 'AuthUser', id: string, avatarUrl?: string | null, alias: Array<{ __typename?: 'AuthAlias', id: string, type: string, value: string }>, roles: Array<{ __typename?: 'AccessGrant', id: string, role: { __typename?: 'AccessRole', id: string, name: string }, ziferblat?: { __typename?: 'ZiferblatZiferblat', id: string, name: string, avatarUrl: string } | null }> } | null };

export type StaffQueryVariables = Exact<{
  ziferblatId: Scalars['uuid']['input'];
}>;


export type StaffQuery = { __typename?: 'query_root', users: Array<{ __typename?: 'AuthUser', id: string, avatarUrl?: string | null, alias: Array<{ __typename?: 'AuthAlias', id: string, type: string, value: string }>, roles: Array<{ __typename?: 'AccessGrant', id: string, role: { __typename?: 'AccessRole', id: string, name: string }, ziferblat?: { __typename?: 'ZiferblatZiferblat', id: string, name: string, avatarUrl: string } | null }> }> };

export type TariffFragment = { __typename?: 'TariffTariff', id: string, hourPrice: Array<number>, stopCheck: number, activeFrom: Date, activeTo?: Date | null };

export type TariffsQueryVariables = Exact<{
  ziferblatId: Scalars['uuid']['input'];
}>;


export type TariffsQuery = { __typename?: 'query_root', tariffs: Array<{ __typename?: 'TariffTariff', id: string, hourPrice: Array<number>, stopCheck: number, activeFrom: Date, activeTo?: Date | null }> };

export type TelegramLoginMutationVariables = Exact<{
  input: AuthTelegramInputInsertInput;
}>;


export type TelegramLoginMutation = { __typename?: 'mutation_root', telegramLogin?: { __typename?: 'AuthTelegramInput', id: number } | null };

export type AddAttendanceMutationVariables = Exact<{
  checkinId: Scalars['uuid']['input'];
  culturalEventId: Scalars['uuid']['input'];
}>;


export type AddAttendanceMutation = { __typename?: 'mutation_root', toggleAttendance?: { __typename?: 'CulturalEventAttendance', checkin?: { __typename?: 'Checkin', id: string, checkedInAt: Date, name?: string | null, sum?: number | null, attendance: Array<{ __typename?: 'CulturalEventAttendance', culturalEvent: { __typename?: 'CulturalEvent', id: string, title: string } }>, user?: { __typename?: 'AuthUser', id: string } | null } | null } | null };

export type RemoveAttendanceMutationVariables = Exact<{
  checkinId: Scalars['uuid']['input'];
  culturalEventId: Scalars['uuid']['input'];
}>;


export type RemoveAttendanceMutation = { __typename?: 'mutation_root', toggleAttendance?: { __typename?: 'CulturalEventAttendanceMutationResponse', returning: Array<{ __typename?: 'CulturalEventAttendance', checkin?: { __typename?: 'Checkin', id: string, checkedInAt: Date, name?: string | null, sum?: number | null, attendance: Array<{ __typename?: 'CulturalEventAttendance', culturalEvent: { __typename?: 'CulturalEvent', id: string, title: string } }>, user?: { __typename?: 'AuthUser', id: string } | null } | null }> } | null };

export type UpsertCulturalEventMutationVariables = Exact<{
  input: CulturalEventInsertInput;
  compensationIds?: InputMaybe<Array<Scalars['uuid']['input']> | Scalars['uuid']['input']>;
}>;


export type UpsertCulturalEventMutation = { __typename?: 'mutation_root', deleteCulturalEventCompensation?: { __typename?: 'CulturalEventCompensationMutationResponse', affectedRows: number } | null, upsertCulturalEvent?: { __typename?: 'CulturalEvent', id: string, title: string, startsAt: Date, endsAt: Date, publicDescription: string, internalComment: string, status: CulturalEventStatusEnum, ziferblatId: string, compensation?: { __typename?: 'CulturalEventCompensation', id: string, currency: CompensationCurrencyEnum, amount: number, unit: CompensationUnitEnum } | null } | null };

export type UserFragment = { __typename?: 'AuthUser', id: string, avatarUrl?: string | null, alias: Array<{ __typename?: 'AuthAlias', id: string, type: string, value: string }>, roles: Array<{ __typename?: 'AccessGrant', id: string, role: { __typename?: 'AccessRole', id: string, name: string }, ziferblat?: { __typename?: 'ZiferblatZiferblat', id: string, name: string, avatarUrl: string } | null }> };

export const CheckinFragmentDoc = gql`
    fragment Checkin on Checkin {
  id
  checkedInAt
  name
  sum
  attendance {
    culturalEvent {
      id
      title
    }
  }
  user {
    id
  }
}
    `;
export const CulturalEventFragmentDoc = gql`
    fragment CulturalEvent on CulturalEvent {
  id
  title
  startsAt
  endsAt
  publicDescription
  internalComment
  status
  ziferblatId
  compensation {
    id
    currency
    amount
    unit
  }
}
    `;
export const UserFragmentDoc = gql`
    fragment User on AuthUser {
  id
  avatarUrl
  alias {
    id
    type
    value
  }
  roles: grants(
    where: {_or: [{_not: {expires: {}}}, {expires: {expiresAt: {_gt: "now()"}}}]}
  ) {
    id
    role {
      id
      name
    }
    ziferblat {
      id
      name
      avatarUrl
    }
  }
}
    `;
export const SessionFragmentDoc = gql`
    fragment Session on AuthSession {
  id
  token
  user {
    ...User
  }
}
    ${UserFragmentDoc}`;
export const TariffFragmentDoc = gql`
    fragment Tariff on TariffTariff {
  id
  hourPrice
  stopCheck
  activeFrom
  activeTo
}
    `;
export const AddRoleDocument = gql`
    mutation AddRole($roleId: uuid!, $userId: uuid!, $ziferblatId: uuid!) {
  addRole: insertAccessGrantOne(
    object: {roleId: $roleId, subjectId: $userId, objectId: $ziferblatId}
  ) {
    id
    subject {
      ...User
    }
  }
}
    ${UserFragmentDoc}`;
export type AddRoleMutationFn = Apollo.MutationFunction<AddRoleMutation, AddRoleMutationVariables>;

/**
 * __useAddRoleMutation__
 *
 * To run a mutation, you first call `useAddRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRoleMutation, { data, loading, error }] = useAddRoleMutation({
 *   variables: {
 *      roleId: // value for 'roleId'
 *      userId: // value for 'userId'
 *      ziferblatId: // value for 'ziferblatId'
 *   },
 * });
 */
export function useAddRoleMutation(baseOptions?: Apollo.MutationHookOptions<AddRoleMutation, AddRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddRoleMutation, AddRoleMutationVariables>(AddRoleDocument, options);
      }
export type AddRoleMutationHookResult = ReturnType<typeof useAddRoleMutation>;
export type AddRoleMutationResult = Apollo.MutationResult<AddRoleMutation>;
export type AddRoleMutationOptions = Apollo.BaseMutationOptions<AddRoleMutation, AddRoleMutationVariables>;
export const AddTariffDocument = gql`
    mutation AddTariff($activeFrom: timestamptz!, $stopCheck: smallint!, $hourPrice: [smallint!]!, $ziferblatId: uuid!) {
  addTariff: insertTariffTariffOne(
    object: {activeFrom: $activeFrom, stopCheck: $stopCheck, hourPrice: $hourPrice, ziferblatId: $ziferblatId}
  ) {
    ...Tariff
  }
}
    ${TariffFragmentDoc}`;
export type AddTariffMutationFn = Apollo.MutationFunction<AddTariffMutation, AddTariffMutationVariables>;

/**
 * __useAddTariffMutation__
 *
 * To run a mutation, you first call `useAddTariffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTariffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTariffMutation, { data, loading, error }] = useAddTariffMutation({
 *   variables: {
 *      activeFrom: // value for 'activeFrom'
 *      stopCheck: // value for 'stopCheck'
 *      hourPrice: // value for 'hourPrice'
 *      ziferblatId: // value for 'ziferblatId'
 *   },
 * });
 */
export function useAddTariffMutation(baseOptions?: Apollo.MutationHookOptions<AddTariffMutation, AddTariffMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddTariffMutation, AddTariffMutationVariables>(AddTariffDocument, options);
      }
export type AddTariffMutationHookResult = ReturnType<typeof useAddTariffMutation>;
export type AddTariffMutationResult = Apollo.MutationResult<AddTariffMutation>;
export type AddTariffMutationOptions = Apollo.BaseMutationOptions<AddTariffMutation, AddTariffMutationVariables>;
export const AttendanceDocument = gql`
    query Attendance($id: uuid!) {
  attendees(where: {culturalEventId: {_eq: $id}}) {
    id
    startedAt
    endedAt
    sum
    visit {
      id
      name
    }
  }
}
    `;

/**
 * __useAttendanceQuery__
 *
 * To run a query within a React component, call `useAttendanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useAttendanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAttendanceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAttendanceQuery(baseOptions: Apollo.QueryHookOptions<AttendanceQuery, AttendanceQueryVariables> & ({ variables: AttendanceQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AttendanceQuery, AttendanceQueryVariables>(AttendanceDocument, options);
      }
export function useAttendanceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AttendanceQuery, AttendanceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AttendanceQuery, AttendanceQueryVariables>(AttendanceDocument, options);
        }
export function useAttendanceSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AttendanceQuery, AttendanceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AttendanceQuery, AttendanceQueryVariables>(AttendanceDocument, options);
        }
export type AttendanceQueryHookResult = ReturnType<typeof useAttendanceQuery>;
export type AttendanceLazyQueryHookResult = ReturnType<typeof useAttendanceLazyQuery>;
export type AttendanceSuspenseQueryHookResult = ReturnType<typeof useAttendanceSuspenseQuery>;
export type AttendanceQueryResult = Apollo.QueryResult<AttendanceQuery, AttendanceQueryVariables>;
export const ChangeVisitNameDocument = gql`
    mutation ChangeVisitName($id: uuid!, $name: String!) {
  updateCheckin(pkColumns: {id: $id}, _set: {name: $name}) {
    ...Checkin
  }
}
    ${CheckinFragmentDoc}`;
export type ChangeVisitNameMutationFn = Apollo.MutationFunction<ChangeVisitNameMutation, ChangeVisitNameMutationVariables>;

/**
 * __useChangeVisitNameMutation__
 *
 * To run a mutation, you first call `useChangeVisitNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeVisitNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeVisitNameMutation, { data, loading, error }] = useChangeVisitNameMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useChangeVisitNameMutation(baseOptions?: Apollo.MutationHookOptions<ChangeVisitNameMutation, ChangeVisitNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeVisitNameMutation, ChangeVisitNameMutationVariables>(ChangeVisitNameDocument, options);
      }
export type ChangeVisitNameMutationHookResult = ReturnType<typeof useChangeVisitNameMutation>;
export type ChangeVisitNameMutationResult = Apollo.MutationResult<ChangeVisitNameMutation>;
export type ChangeVisitNameMutationOptions = Apollo.BaseMutationOptions<ChangeVisitNameMutation, ChangeVisitNameMutationVariables>;
export const CheckInDocument = gql`
    mutation CheckIn($name: String!, $ziferblatId: uuid!, $attendance: [CulturalEventAttendanceInsertInput!]!, $checkedInAt: timestamptz) {
  addCheckin(
    object: {name: $name, ziferblatId: $ziferblatId, checkedInAt: $checkedInAt, attendance: {data: $attendance}}
  ) {
    ...Checkin
  }
}
    ${CheckinFragmentDoc}`;
export type CheckInMutationFn = Apollo.MutationFunction<CheckInMutation, CheckInMutationVariables>;

/**
 * __useCheckInMutation__
 *
 * To run a mutation, you first call `useCheckInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkInMutation, { data, loading, error }] = useCheckInMutation({
 *   variables: {
 *      name: // value for 'name'
 *      ziferblatId: // value for 'ziferblatId'
 *      attendance: // value for 'attendance'
 *      checkedInAt: // value for 'checkedInAt'
 *   },
 * });
 */
export function useCheckInMutation(baseOptions?: Apollo.MutationHookOptions<CheckInMutation, CheckInMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CheckInMutation, CheckInMutationVariables>(CheckInDocument, options);
      }
export type CheckInMutationHookResult = ReturnType<typeof useCheckInMutation>;
export type CheckInMutationResult = Apollo.MutationResult<CheckInMutation>;
export type CheckInMutationOptions = Apollo.BaseMutationOptions<CheckInMutation, CheckInMutationVariables>;
export const CheckOutDocument = gql`
    mutation CheckOut($id: uuid!, $sum: Int!) {
  checkOut(object: {id: $id, sum: $sum}) {
    id
  }
}
    `;
export type CheckOutMutationFn = Apollo.MutationFunction<CheckOutMutation, CheckOutMutationVariables>;

/**
 * __useCheckOutMutation__
 *
 * To run a mutation, you first call `useCheckOutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckOutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkOutMutation, { data, loading, error }] = useCheckOutMutation({
 *   variables: {
 *      id: // value for 'id'
 *      sum: // value for 'sum'
 *   },
 * });
 */
export function useCheckOutMutation(baseOptions?: Apollo.MutationHookOptions<CheckOutMutation, CheckOutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CheckOutMutation, CheckOutMutationVariables>(CheckOutDocument, options);
      }
export type CheckOutMutationHookResult = ReturnType<typeof useCheckOutMutation>;
export type CheckOutMutationResult = Apollo.MutationResult<CheckOutMutation>;
export type CheckOutMutationOptions = Apollo.BaseMutationOptions<CheckOutMutation, CheckOutMutationVariables>;
export const CheckinsDocument = gql`
    query Checkins($ziferblatId: uuid!, $startsAt: timestamptz!, $endsAt: timestamptz!) {
  checkins(where: {ziferblatId: {_eq: $ziferblatId}}) {
    ...Checkin
  }
  upcomingCulturalEvents: culturalEvents(
    where: {ziferblatId: {_eq: $ziferblatId}, startsAt: {_gte: $startsAt}, endsAt: {_lte: $endsAt}, status: {_eq: PUBLISHED}}
  ) {
    ...CulturalEvent
  }
}
    ${CheckinFragmentDoc}
${CulturalEventFragmentDoc}`;

/**
 * __useCheckinsQuery__
 *
 * To run a query within a React component, call `useCheckinsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckinsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckinsQuery({
 *   variables: {
 *      ziferblatId: // value for 'ziferblatId'
 *      startsAt: // value for 'startsAt'
 *      endsAt: // value for 'endsAt'
 *   },
 * });
 */
export function useCheckinsQuery(baseOptions: Apollo.QueryHookOptions<CheckinsQuery, CheckinsQueryVariables> & ({ variables: CheckinsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckinsQuery, CheckinsQueryVariables>(CheckinsDocument, options);
      }
export function useCheckinsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckinsQuery, CheckinsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckinsQuery, CheckinsQueryVariables>(CheckinsDocument, options);
        }
export function useCheckinsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CheckinsQuery, CheckinsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CheckinsQuery, CheckinsQueryVariables>(CheckinsDocument, options);
        }
export type CheckinsQueryHookResult = ReturnType<typeof useCheckinsQuery>;
export type CheckinsLazyQueryHookResult = ReturnType<typeof useCheckinsLazyQuery>;
export type CheckinsSuspenseQueryHookResult = ReturnType<typeof useCheckinsSuspenseQuery>;
export type CheckinsQueryResult = Apollo.QueryResult<CheckinsQuery, CheckinsQueryVariables>;
export const CulturalEventDocument = gql`
    query CulturalEvent($id: uuid!) {
  culturalEvent(id: $id) {
    ...CulturalEvent
  }
}
    ${CulturalEventFragmentDoc}`;

/**
 * __useCulturalEventQuery__
 *
 * To run a query within a React component, call `useCulturalEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useCulturalEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCulturalEventQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCulturalEventQuery(baseOptions: Apollo.QueryHookOptions<CulturalEventQuery, CulturalEventQueryVariables> & ({ variables: CulturalEventQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CulturalEventQuery, CulturalEventQueryVariables>(CulturalEventDocument, options);
      }
export function useCulturalEventLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CulturalEventQuery, CulturalEventQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CulturalEventQuery, CulturalEventQueryVariables>(CulturalEventDocument, options);
        }
export function useCulturalEventSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CulturalEventQuery, CulturalEventQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CulturalEventQuery, CulturalEventQueryVariables>(CulturalEventDocument, options);
        }
export type CulturalEventQueryHookResult = ReturnType<typeof useCulturalEventQuery>;
export type CulturalEventLazyQueryHookResult = ReturnType<typeof useCulturalEventLazyQuery>;
export type CulturalEventSuspenseQueryHookResult = ReturnType<typeof useCulturalEventSuspenseQuery>;
export type CulturalEventQueryResult = Apollo.QueryResult<CulturalEventQuery, CulturalEventQueryVariables>;
export const CulturalEventsDocument = gql`
    query CulturalEvents($where: CulturalEventBoolExp) {
  culturalEvents(where: $where, orderBy: {startsAt: DESC}) {
    id
    title
    startsAt
  }
}
    `;

/**
 * __useCulturalEventsQuery__
 *
 * To run a query within a React component, call `useCulturalEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCulturalEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCulturalEventsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useCulturalEventsQuery(baseOptions?: Apollo.QueryHookOptions<CulturalEventsQuery, CulturalEventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CulturalEventsQuery, CulturalEventsQueryVariables>(CulturalEventsDocument, options);
      }
export function useCulturalEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CulturalEventsQuery, CulturalEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CulturalEventsQuery, CulturalEventsQueryVariables>(CulturalEventsDocument, options);
        }
export function useCulturalEventsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CulturalEventsQuery, CulturalEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CulturalEventsQuery, CulturalEventsQueryVariables>(CulturalEventsDocument, options);
        }
export type CulturalEventsQueryHookResult = ReturnType<typeof useCulturalEventsQuery>;
export type CulturalEventsLazyQueryHookResult = ReturnType<typeof useCulturalEventsLazyQuery>;
export type CulturalEventsSuspenseQueryHookResult = ReturnType<typeof useCulturalEventsSuspenseQuery>;
export type CulturalEventsQueryResult = Apollo.QueryResult<CulturalEventsQuery, CulturalEventsQueryVariables>;
export const LogoutDocument = gql`
    mutation Logout {
  logout(object: {}) {
    id
  }
}
    `;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const MeDocument = gql`
    query Me {
  telegramBot: authTelegramBot {
    id: botId
    name
  }
  me: authMe {
    ...Session
  }
}
    ${SessionFragmentDoc}`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export function useMeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeSuspenseQueryHookResult = ReturnType<typeof useMeSuspenseQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const RemoveRoleDocument = gql`
    mutation RemoveRole($id: uuid!) {
  removeGrant(object: {grantId: $id}) {
    grant {
      subject {
        ...User
      }
    }
  }
}
    ${UserFragmentDoc}`;
export type RemoveRoleMutationFn = Apollo.MutationFunction<RemoveRoleMutation, RemoveRoleMutationVariables>;

/**
 * __useRemoveRoleMutation__
 *
 * To run a mutation, you first call `useRemoveRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeRoleMutation, { data, loading, error }] = useRemoveRoleMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveRoleMutation(baseOptions?: Apollo.MutationHookOptions<RemoveRoleMutation, RemoveRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveRoleMutation, RemoveRoleMutationVariables>(RemoveRoleDocument, options);
      }
export type RemoveRoleMutationHookResult = ReturnType<typeof useRemoveRoleMutation>;
export type RemoveRoleMutationResult = Apollo.MutationResult<RemoveRoleMutation>;
export type RemoveRoleMutationOptions = Apollo.BaseMutationOptions<RemoveRoleMutation, RemoveRoleMutationVariables>;
export const RolesDocument = gql`
    query Roles {
  accessRole {
    id
    name
  }
}
    `;

/**
 * __useRolesQuery__
 *
 * To run a query within a React component, call `useRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useRolesQuery(baseOptions?: Apollo.QueryHookOptions<RolesQuery, RolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RolesQuery, RolesQueryVariables>(RolesDocument, options);
      }
export function useRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RolesQuery, RolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RolesQuery, RolesQueryVariables>(RolesDocument, options);
        }
export function useRolesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<RolesQuery, RolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<RolesQuery, RolesQueryVariables>(RolesDocument, options);
        }
export type RolesQueryHookResult = ReturnType<typeof useRolesQuery>;
export type RolesLazyQueryHookResult = ReturnType<typeof useRolesLazyQuery>;
export type RolesSuspenseQueryHookResult = ReturnType<typeof useRolesSuspenseQuery>;
export type RolesQueryResult = Apollo.QueryResult<RolesQuery, RolesQueryVariables>;
export const SearchAliasDocument = gql`
    query SearchAlias($term: String!) {
  authAlias(limit: 5, distinctOn: userId, where: {value: {_ilike: $term}}) {
    id
    user {
      id
      alias {
        id
        type
        value
      }
    }
  }
}
    `;

/**
 * __useSearchAliasQuery__
 *
 * To run a query within a React component, call `useSearchAliasQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchAliasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchAliasQuery({
 *   variables: {
 *      term: // value for 'term'
 *   },
 * });
 */
export function useSearchAliasQuery(baseOptions: Apollo.QueryHookOptions<SearchAliasQuery, SearchAliasQueryVariables> & ({ variables: SearchAliasQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchAliasQuery, SearchAliasQueryVariables>(SearchAliasDocument, options);
      }
export function useSearchAliasLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchAliasQuery, SearchAliasQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchAliasQuery, SearchAliasQueryVariables>(SearchAliasDocument, options);
        }
export function useSearchAliasSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SearchAliasQuery, SearchAliasQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SearchAliasQuery, SearchAliasQueryVariables>(SearchAliasDocument, options);
        }
export type SearchAliasQueryHookResult = ReturnType<typeof useSearchAliasQuery>;
export type SearchAliasLazyQueryHookResult = ReturnType<typeof useSearchAliasLazyQuery>;
export type SearchAliasSuspenseQueryHookResult = ReturnType<typeof useSearchAliasSuspenseQuery>;
export type SearchAliasQueryResult = Apollo.QueryResult<SearchAliasQuery, SearchAliasQueryVariables>;
export const StaffDocument = gql`
    query Staff($ziferblatId: uuid!) {
  users(
    where: {grants: {objectId: {_eq: $ziferblatId}, _or: [{_not: {expires: {}}}, {expires: {expiresAt: {_gt: "now()"}}}]}}
  ) {
    ...User
  }
}
    ${UserFragmentDoc}`;

/**
 * __useStaffQuery__
 *
 * To run a query within a React component, call `useStaffQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaffQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaffQuery({
 *   variables: {
 *      ziferblatId: // value for 'ziferblatId'
 *   },
 * });
 */
export function useStaffQuery(baseOptions: Apollo.QueryHookOptions<StaffQuery, StaffQueryVariables> & ({ variables: StaffQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StaffQuery, StaffQueryVariables>(StaffDocument, options);
      }
export function useStaffLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StaffQuery, StaffQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StaffQuery, StaffQueryVariables>(StaffDocument, options);
        }
export function useStaffSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<StaffQuery, StaffQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<StaffQuery, StaffQueryVariables>(StaffDocument, options);
        }
export type StaffQueryHookResult = ReturnType<typeof useStaffQuery>;
export type StaffLazyQueryHookResult = ReturnType<typeof useStaffLazyQuery>;
export type StaffSuspenseQueryHookResult = ReturnType<typeof useStaffSuspenseQuery>;
export type StaffQueryResult = Apollo.QueryResult<StaffQuery, StaffQueryVariables>;
export const TariffsDocument = gql`
    query Tariffs($ziferblatId: uuid!) {
  tariffs(where: {ziferblatId: {_eq: $ziferblatId}}, orderBy: {activeFrom: DESC}) {
    ...Tariff
  }
}
    ${TariffFragmentDoc}`;

/**
 * __useTariffsQuery__
 *
 * To run a query within a React component, call `useTariffsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTariffsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTariffsQuery({
 *   variables: {
 *      ziferblatId: // value for 'ziferblatId'
 *   },
 * });
 */
export function useTariffsQuery(baseOptions: Apollo.QueryHookOptions<TariffsQuery, TariffsQueryVariables> & ({ variables: TariffsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TariffsQuery, TariffsQueryVariables>(TariffsDocument, options);
      }
export function useTariffsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TariffsQuery, TariffsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TariffsQuery, TariffsQueryVariables>(TariffsDocument, options);
        }
export function useTariffsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TariffsQuery, TariffsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TariffsQuery, TariffsQueryVariables>(TariffsDocument, options);
        }
export type TariffsQueryHookResult = ReturnType<typeof useTariffsQuery>;
export type TariffsLazyQueryHookResult = ReturnType<typeof useTariffsLazyQuery>;
export type TariffsSuspenseQueryHookResult = ReturnType<typeof useTariffsSuspenseQuery>;
export type TariffsQueryResult = Apollo.QueryResult<TariffsQuery, TariffsQueryVariables>;
export const TelegramLoginDocument = gql`
    mutation TelegramLogin($input: AuthTelegramInputInsertInput!) {
  telegramLogin(object: $input) {
    id
  }
}
    `;
export type TelegramLoginMutationFn = Apollo.MutationFunction<TelegramLoginMutation, TelegramLoginMutationVariables>;

/**
 * __useTelegramLoginMutation__
 *
 * To run a mutation, you first call `useTelegramLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTelegramLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [telegramLoginMutation, { data, loading, error }] = useTelegramLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTelegramLoginMutation(baseOptions?: Apollo.MutationHookOptions<TelegramLoginMutation, TelegramLoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TelegramLoginMutation, TelegramLoginMutationVariables>(TelegramLoginDocument, options);
      }
export type TelegramLoginMutationHookResult = ReturnType<typeof useTelegramLoginMutation>;
export type TelegramLoginMutationResult = Apollo.MutationResult<TelegramLoginMutation>;
export type TelegramLoginMutationOptions = Apollo.BaseMutationOptions<TelegramLoginMutation, TelegramLoginMutationVariables>;
export const AddAttendanceDocument = gql`
    mutation AddAttendance($checkinId: uuid!, $culturalEventId: uuid!) {
  toggleAttendance: addAttendance(
    object: {visitId: $checkinId, culturalEventId: $culturalEventId}
  ) {
    checkin {
      ...Checkin
    }
  }
}
    ${CheckinFragmentDoc}`;
export type AddAttendanceMutationFn = Apollo.MutationFunction<AddAttendanceMutation, AddAttendanceMutationVariables>;

/**
 * __useAddAttendanceMutation__
 *
 * To run a mutation, you first call `useAddAttendanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAttendanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAttendanceMutation, { data, loading, error }] = useAddAttendanceMutation({
 *   variables: {
 *      checkinId: // value for 'checkinId'
 *      culturalEventId: // value for 'culturalEventId'
 *   },
 * });
 */
export function useAddAttendanceMutation(baseOptions?: Apollo.MutationHookOptions<AddAttendanceMutation, AddAttendanceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddAttendanceMutation, AddAttendanceMutationVariables>(AddAttendanceDocument, options);
      }
export type AddAttendanceMutationHookResult = ReturnType<typeof useAddAttendanceMutation>;
export type AddAttendanceMutationResult = Apollo.MutationResult<AddAttendanceMutation>;
export type AddAttendanceMutationOptions = Apollo.BaseMutationOptions<AddAttendanceMutation, AddAttendanceMutationVariables>;
export const RemoveAttendanceDocument = gql`
    mutation removeAttendance($checkinId: uuid!, $culturalEventId: uuid!) {
  toggleAttendance: removeAttendance(
    where: {visitId: {_eq: $checkinId}, culturalEventId: {_eq: $culturalEventId}}
  ) {
    returning {
      checkin {
        ...Checkin
      }
    }
  }
}
    ${CheckinFragmentDoc}`;
export type RemoveAttendanceMutationFn = Apollo.MutationFunction<RemoveAttendanceMutation, RemoveAttendanceMutationVariables>;

/**
 * __useRemoveAttendanceMutation__
 *
 * To run a mutation, you first call `useRemoveAttendanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAttendanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAttendanceMutation, { data, loading, error }] = useRemoveAttendanceMutation({
 *   variables: {
 *      checkinId: // value for 'checkinId'
 *      culturalEventId: // value for 'culturalEventId'
 *   },
 * });
 */
export function useRemoveAttendanceMutation(baseOptions?: Apollo.MutationHookOptions<RemoveAttendanceMutation, RemoveAttendanceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveAttendanceMutation, RemoveAttendanceMutationVariables>(RemoveAttendanceDocument, options);
      }
export type RemoveAttendanceMutationHookResult = ReturnType<typeof useRemoveAttendanceMutation>;
export type RemoveAttendanceMutationResult = Apollo.MutationResult<RemoveAttendanceMutation>;
export type RemoveAttendanceMutationOptions = Apollo.BaseMutationOptions<RemoveAttendanceMutation, RemoveAttendanceMutationVariables>;
export const UpsertCulturalEventDocument = gql`
    mutation UpsertCulturalEvent($input: CulturalEventInsertInput!, $compensationIds: [uuid!] = []) {
  deleteCulturalEventCompensation(where: {id: {_in: $compensationIds}}) {
    affectedRows
  }
  upsertCulturalEvent: addCulturalEvent(
    onConflict: {constraint: cultural_event_pkey, updateColumns: [endsAt, startsAt, publicDescription, internalComment, status, title]}
    object: $input
  ) {
    ...CulturalEvent
  }
}
    ${CulturalEventFragmentDoc}`;
export type UpsertCulturalEventMutationFn = Apollo.MutationFunction<UpsertCulturalEventMutation, UpsertCulturalEventMutationVariables>;

/**
 * __useUpsertCulturalEventMutation__
 *
 * To run a mutation, you first call `useUpsertCulturalEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertCulturalEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertCulturalEventMutation, { data, loading, error }] = useUpsertCulturalEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *      compensationIds: // value for 'compensationIds'
 *   },
 * });
 */
export function useUpsertCulturalEventMutation(baseOptions?: Apollo.MutationHookOptions<UpsertCulturalEventMutation, UpsertCulturalEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertCulturalEventMutation, UpsertCulturalEventMutationVariables>(UpsertCulturalEventDocument, options);
      }
export type UpsertCulturalEventMutationHookResult = ReturnType<typeof useUpsertCulturalEventMutation>;
export type UpsertCulturalEventMutationResult = Apollo.MutationResult<UpsertCulturalEventMutation>;
export type UpsertCulturalEventMutationOptions = Apollo.BaseMutationOptions<UpsertCulturalEventMutation, UpsertCulturalEventMutationVariables>;