import { useLayoutContext } from '@gravity-ui/uikit'
import { useCulturalEventQuery } from 'queries'
import { createElement as $, Fragment } from 'react'
import { Navigate, useParams } from 'react-router-dom'
import CulturalEventWithData from './CulturalEventWithData'
import Desktop from './Desktop'
import Mobile from './Mobile'
import Attendance from './Attendance'

const CulturalEvent = () => {
	const { activeMediaQuery } = useLayoutContext()
	const component = activeMediaQuery === 's' ? Mobile : Desktop
	const params = useParams<'id'>()
	const id = params.id!
  const { data, loading } = useCulturalEventQuery({ variables: { id } })
	const culturalEvent = data?.culturalEvent
	if (loading) return null
	if (!culturalEvent) return $(Navigate, { to: '/events' })
	// TODO add checking user role for view only 
	return $(Fragment, null,
		$(CulturalEventWithData, culturalEvent, $(component, { loading })),
		$(Attendance, { id, compensation: culturalEvent.compensation }))
}

export type CulturalEventProps = {
  loading: boolean
}

export default CulturalEvent