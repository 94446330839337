import { Avatar, AvatarProps, Button } from '@gravity-ui/uikit'
import { createElement as $, FC } from 'react'
import './style.scss'
import { RoleType } from 'pages/Settings/StaffListItem'
import { FormattedMessage } from 'react-intl'
import { MessageIds } from 'components/IntlProvider'

const ZiferList: FC<ZiferListProps> = ({
	roles,
	updatedRole,
	handleZiferClick
}) => 
  roles?.map(({ id, role, ziferblat }) =>
		$(Button, {
      className: 'iteratee_button',
      key: id,
      selected: id === updatedRole,
      size: 'l',
      view: 'flat',
      width: 'max',
      onClick: () => handleZiferClick(id),
		},  
      $('div', { className: 'iteratee_button-wrapper' },
        $<AvatarProps>(Avatar, { imgUrl: ziferblat?.avatarUrl || '/favicon.svg' }),
        $(FormattedMessage, { id: `role.${role.name}` as MessageIds}),
        ' ',
        `${ ziferblat?.name.replace('Циферблат ', '') }`
  )))

type ZiferListProps = {
  roles: RoleType[]
  updatedRole: string
  handleZiferClick: (arg0: string) => void
}

export default ZiferList