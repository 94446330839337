import { Flex, Select, TextInput } from '@gravity-ui/uikit'
import { useField } from 'formik'
import { CompensationCurrencyEnum as CompensationCurrency, CulturalEventCompensation as CompensationType, CompensationUnitEnum as CompensationUnit } from 'queries'
import { createElement as $, FC, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { Form } from '.'

const Compensation: FC = () => {
  const intl = useIntl()
  const field = useField<Form['compensation']>({ name: 'compensation' })
  const value = field[0].value
  const setValue = field[2].setValue
  const options = keys.map((value) => ({
    content: intl.formatMessage({ id: `culturalEvent.compensation.variant.${value}` }),
    value
  }))
  if (!value)
    return $(NoCompensation, { value, options, setValue })
  return $(CompensationInput, { value, options, setValue })
}

const NoCompensation: FC<CompensationProps> = ({
  options,
  ...props
}) =>
  $(Select, {
    options,
    onUpdate: getOnSelectUpdate(props),
    value: ['None'],
    width: 'max',
  })

const CompensationInput: FC<CompensationProps & { value: NonNullable<CompensationProps['value']>}> = ({
  options,
  ...props
}) => {
  const { setValue, value } = props

  const [intermediateValue, setIntermediateValue] = useState(value.amount.toString())
  const amount = useMemo(() => {
    let amount = parseInt(intermediateValue)
    if (amount) {
      if (value.unit === CompensationUnit.Percentage && amount > 100)
        amount = 100
      return amount
    }
    return ''
  }, [intermediateValue, value?.unit])

  useEffect(() => {
    if (amount)
      setValue({
        ...value,
        amount,
      })
  }, [amount, setValue, value])

  // FIXME any
  const onBlur = (event: any) =>
    !amount && !event.currentTarget.contains(event.relatedTarget) &&
      setValue(undefined)

  return $(Flex, { onBlur },
    $(TextInput, {
      autoFocus: true,
      pin: 'round-clear',
      value: amount.toString(),
      onUpdate: setIntermediateValue,
      }),
    $(Select, {
      options,
      onUpdate: getOnSelectUpdate(props),
      value: [`${value.unit}_${value.currency}`],
      pin: 'clear-round',
    }))
}

const getOnSelectUpdate = ({ setValue, value }: Omit<CompensationProps, 'options'>) => (option: string[]) => {
  const [unit, currency] = option[0].split('_') as [CompensationUnit, CompensationCurrency]
  if (option[0] === 'None') return setValue(undefined)
  const amount = value?.amount || 10
  setValue({ amount, unit, currency }) 
}

const cartesianProduct = (arr1: string[], arr2: string[]) => {
  const product: string[] = []
  for (let value1 of arr1)
    for (let value2 of arr2)
      product.push(`${value1}_${value2}`)
  return product
}

const keys = cartesianProduct(Object.values(CompensationUnit), Object.values(CompensationCurrency)).concat('None') as CompensationOptions[]

type CompensationOptions = `${CompensationUnit}_${CompensationCurrency}` | 'None'

type CompensationProps = {
  value?: CompensationValue,
  options: {
    content: string
    value: CompensationOptions
  }[]
  setValue: (value: undefined | CompensationValue) => void
}

type CompensationValue = Pick<CompensationType, 'unit' | 'currency' | 'amount'>

export default Compensation