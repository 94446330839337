import { Label } from '@gravity-ui/uikit'
import useOutsideClick from 'hooks/useOutsideClick'
import { useRemoveRoleMutation } from 'queries'
import { createElement as $, FC, RefObject, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { RoleType, Roles } from './StaffListItem'
import './style.scss'

const RoleItem: FC<RoleType> = ({ id, role }) => {
  const labelRef = useRef(null)
  const [deleteRef, setDeleteRef] = useState<any>(null)
  const [mutate, { loading }] = useRemoveRoleMutation({ variables: { id }})
  const intl = useIntl()
	const message = intl.formatMessage({ id: `role.${role.name}` as Roles })

  const handleLabelClick = (labelRef: RefObject<HTMLElement>) => {
    if (labelRef === deleteRef) 
      setDeleteRef(null)
    else setDeleteRef(labelRef)
  }

  useOutsideClick(() => {
    setDeleteRef(null)
  }, labelRef)

  return $('div', {
    ref: labelRef,
    key: id,
    onClick: () => handleLabelClick(labelRef),
  }, 
    $(Label, {
      className: 'role-label',
      size: 'm',
      type: deleteRef === labelRef ? 'close' : 'default',
      theme: deleteRef === labelRef ? 'danger' : 'normal',
      disabled: loading,
      onCloseClick: () => mutate(),
      // onCloseClick: console.log,
      children: message
  }))
}

const RoleItemIteratee: FC<RoleType> = (props) => 
  $('div', { key: props.id }, 
    $(RoleItem, props))

export default RoleItemIteratee
