import { dateTime } from '@gravity-ui/date-utils/build/dateTime'
import { FormikProvider, useFormik } from 'formik'
import { CulturalEventCompensationConstraint, CulturalEventCompensationUpdateColumn, CulturalEventFragment, CulturalEventStatusEnum as CulturalEventStatus, useUpsertCulturalEventMutation } from 'queries'
import { createElement as $, FC, PropsWithChildren } from 'react'
import { Form } from './CulturalEventForm'

const CulturalEventWithData: FC<PropsWithChildren<CulturalEventFragment>> = (props) => {
  const [mutate] = useUpsertCulturalEventMutation()
  const formik = useFormik<Form>({
    initialValues: {
      title: props.title || '',
      internalComment: props.internalComment || '',
      publicDescription: props.publicDescription || '',
      status: props.status || CulturalEventStatus.Draft,
      startsAt: dateTime({input: props.startsAt}),
      endsAt: dateTime({input: props.endsAt}),
      ziferblatId: props.ziferblatId,
      ...props.compensation && {
        compensation: {
          amount: props.compensation.amount,
          unit: props.compensation.unit,
          currency: props.compensation.currency
        }
      }
    },
    onSubmit: ({ compensation, ...values }) => {
      mutate({
        variables: {
          ...!compensation && props.compensation && {
            compensationIds: [props.compensation.id]
          },
          input: {
            id: props.id,
            ...values,
            endsAt: values.endsAt?.toDate(),
            startsAt: values.startsAt?.toDate(),
            status: values.status,
            ...compensation && {
              compensation: {
                onConflict: {
                  constraint: CulturalEventCompensationConstraint.CompensationPkey,
                  updateColumns: [
                    CulturalEventCompensationUpdateColumn.Amount,
                    CulturalEventCompensationUpdateColumn.Currency,
                    CulturalEventCompensationUpdateColumn.Unit,
                  ]
                },
                data: {
                  id: props.compensation?.id,
                  ...compensation
                }
              }
            }
          }
        }
      })
    },
    validateOnBlur: true,
    enableReinitialize: true
  })

  return $(FormikProvider, { value: formik }, props.children)
}

export default CulturalEventWithData