import Empty from 'components/Empty'
import loadingElements from 'components/LoadingElements/LoadingElements'
import MobileList from 'components/MobileList'
import { SearchContext } from 'components/MobileMenu/SearchContext'
import MobileMenuContainer from 'components/MobileMenuContainer'
import { CulturalEventsQuery } from 'queries'
import { createElement as $, FC, useContext } from 'react'
import { CulturalEventsProps } from '..'
import AddCulturalEvent from '../CulturalEvent/Mobile/AddCulturalEvent'
import CulturalEventListItemIteratee from './CulturalEventListItem'

const CulturalEvents: FC<CulturalEventsProps> = (props) =>
  $(MobileMenuContainer, {
    fab: $(AddCulturalEvent),
    label: 'header.button.search.events'
    },
    $(MobileList, null, 
      $(Content, props)))

const Content: FC<CulturalEventsProps> = (props) => {

  if (!props.data) return $(Empty)
  if (props.loading && !props.data) return loadingElements

  return $(PreparedList, props.data)
}

const PreparedList: FC<CulturalEventsQuery> = ({ culturalEvents }) => {
  const searchRegex = useContext(SearchContext)
  const result = searchRegex
    ? culturalEvents.filter(event => searchRegex.exec(getName(event)))
    : culturalEvents
  return result.map(CulturalEventListItemIteratee)
}

const getName = (event: CulturalEventsQuery['culturalEvents'][0]) => {
  return event?.__typename === 'CulturalEvent'
    ? event?.title || 'Без названия' // FIXME Intl
    : '' // FIXME
}

export default CulturalEvents