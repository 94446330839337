import { ArrowRightFromSquare } from "@gravity-ui/icons"
import { Avatar, AvatarProps, Button, Card, Icon, Portal, Tooltip, User } from "@gravity-ui/uikit"
import useOutsideClick from "hooks/useOutsideClick"
import useSortedRoles from "hooks/useSortedRoles"
import { StaffType } from "pages/Settings/StaffListItem"
import { MeQuery, useLogoutMutation } from "queries"
import { createElement as $, Dispatch, FC, SetStateAction, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import useLocalStorageState from "use-local-storage-state"
import './style.scss'
import ZiferList from "components/ZIferIteratee/ZiferIteratee"

const PopupButton = (data: MeQuery) => {
  const roles = data.me?.user?.roles || []
  const [ currentRole, setCurrentRole ] = useLocalStorageState<StaffType['roles'][0]>('currentRole')
  const currentRoleId = (currentRole && currentRole.id) || roles[0]?.id
  const currentLogo = currentRole?.ziferblat?.avatarUrl || roles[0].ziferblat?.avatarUrl
  const [updatedRole, setUpdatedRole] = useState(currentRoleId)
  const buttonRef = useRef(null)
  const cardRef = useRef(null)
  const [open, setOpen] = useState(false)
 
  useOutsideClick(() => {
    setOpen(false)
  }, cardRef)

  const children = $('div', null, 
    $('div', {
      className: 'navigation-rail_userpic',
      ref: buttonRef,
      onClick: () => setOpen((prev) => !prev)
      }, 
      $<AvatarProps>(Avatar, {
        imgUrl: currentLogo,
        fallbackImgUrl: '/favicon.svg',
        size: 'm'
        })),
      $(Portal, null, 
        open && $('div', { style: CardStyles}, 
          $(Card, {
            ref: cardRef,
            type: 'action',
            view: 'raised',
            children: $(ButtonContent, { 
              data,
              setOpen,
              updatedRole, 
              setUpdatedRole,
              setCurrentRole
            })
          }))))

  return $(Tooltip, { 
    className: 'navigation-rail_tooltip',
    content: 'User profile',  // TODO: Intl
    placement: 'right', 
    children 
  })
}

const ButtonContent: FC<ButtonContentProps> = 
  ({ data, setOpen, updatedRole, setUpdatedRole, setCurrentRole }) => {
  const sortedRoles = useSortedRoles(data, updatedRole)
  const [mutate, { loading }] = useLogoutMutation()
  const navigate = useNavigate()
  const handleZiferClick = (id: string) => {
    const matchingZifer = data.me?.user?.roles.find((obj) => obj.id === id)
    setUpdatedRole(id)
    setCurrentRole(matchingZifer)
    setOpen(false)
  }
  const handleInfoClick = () => {
    setOpen(false)
    navigate('/profile')
  }

  return $('div', { className: 'navigation-rail_popup' },
    $('div', { className: 'navigation-rail_popup_user' },
      $(User, {
        size: 'm',
        avatar: $<AvatarProps>(Avatar, { imgUrl: data.me?.user?.avatarUrl || '/favicon.svg' }),
        name: data.me?.user?.alias[2].value, 
        description: `@${data.me?.user?.alias[1].value}`
      }),
      $('div', { 
        className: 'navigation-rail_popup_user_clickable', 
        onClick: handleInfoClick}),
      $('div', { className: 'navigation-rail_popup_user_logout' },
        $(Button, {
          view: 'flat',
          size: 's',
          loading: loading,
          onClick: () => mutate()
            .then(() => navigate(''))
            .then(() => window.location.reload())
        }, $(Icon, { data: ArrowRightFromSquare })))),
        $(ZiferList, { 
          roles: sortedRoles,
          updatedRole,
          handleZiferClick 
        }))
}

const CardStyles = {
  position: 'fixed',
  left: '.75rem',
  bottom: '.75rem'
}

type ButtonContentProps = {
  data: MeQuery
  setOpen: Dispatch<SetStateAction<boolean>>
  updatedRole: string
  setUpdatedRole: (arg: string) => void
  setCurrentRole: Dispatch<any>
}

export default PopupButton