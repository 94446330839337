import { ApolloProvider } from '@apollo/client'
import '@gravity-ui/uikit/styles/fonts.css'
import '@gravity-ui/uikit/styles/styles.css'
import useCreateClient, { TOKEN_KEY } from 'apolloClient'
import IntlProvider from 'components/IntlProvider'
import ThemedApp from 'components/ThemedApp'
import Unauthorized from 'components/Unauthorized'
import ZiferblatContext from 'components/ZiferblatContext'
import Login from 'pages/Login'
import Main from 'pages/Main'
import { useMeQuery } from 'queries'
import { createElement as $, FC, PropsWithChildren } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

const App: FC<PropsWithChildren> = () => {
  const url = `wss://${process.env['REACT_APP_API']}`
  const client = useCreateClient(url)

  return $(ThemedApp, null,
    $(BrowserRouter, null,
      $(IntlProvider, null,
        $(ApolloProvider, {
          client,
          children: $(LoginCheck)
        }))))
}

const LoginCheck = () => {
  const { data, loading } = useMeQuery()

  if (loading) return null
  const session = data?.me

  if (!session) return 'данные не получены' // FIXME

  localStorage.setItem(TOKEN_KEY, session.token)

  if (!session.user) // FIXME
    return $(Routes, null,
      $(Route, { path: '*', element: $(Login, data?.telegramBot[0]) }))

  const { roles } = session.user
  const role = roles[0]
  // console.log(role)
  // setCurrentRole(role)

  if (!role)
    return $(Unauthorized)

  return $(ZiferblatContext.Provider, { 
    value: { 
      name: role.role.name, 
      ziferblatId: role.ziferblat?.id!
    }},
    $(AuthorizedRoutes))
}

const AuthorizedRoutes: FC = () =>
  $(Routes, null,
    $(Route, { path: '*', element: $(Main) }))

// const ThemedApp: FC<PropsWithChildren> = ({ children }) => {
//   const light = useTheme(chroma(78, 216, 233), false)
//   const dark = useTheme(chroma(78, 216, 233), true)

  // const styles = {
  //   'body': {
  //     margin: 0
  //   },
  //   '*': {
  //     boxSizing: 'border-box'
  //   },
  //   '.yc-root': {
  //     '--g-sheet-content-paddings': 0,
  //     '&_theme_dark': {
  //       ...dark,
  //     },
  //     '&_theme_light': {
  //       ...light
  //     }
  //   }
  // }
  // return $(ThemeProvider, {
  //   // theme: prefersDarkMode ? 'dark' : 'light'
  //   },
  //   // $(Global, { styles }),
  //   $(LayoutProvider, null,
  //     $(MobileProvider, { mobile: false },
  //       children)))
// }

export default App
