import { ArrowLeft, ArrowRightFromSquare } from "@gravity-ui/icons"
import { Avatar, AvatarProps, Button, Card, Flex, Icon, Text, spacing } from "@gravity-ui/uikit"
import { createElement as $, FC } from "react"
import { FormattedMessage } from "react-intl"
import { AliasIteratee, ProfileProps } from "."

const Mobile: FC<ProfileProps> = (props) => {
  const { data, aliases, loading, back, name, surname, onClick } = props
  
  return $('div', { className: 'profile-info' },
    $(Flex, { grow: 1, direction: 'column', justifyContent: 'center' }, 
      $(Card, { className: spacing({ p:4 }), children: '' },
      $(Flex, { direction: 'column', alignItems: 'flex-start', gap: 4, },
        $(Flex, { gap: 2, alignItems: 'center' }, 
          $<AvatarProps>(Avatar, { 
            size: 'xl',
            imgUrl: data?.me?.user?.avatarUrl || '/favicon.svg' 
          }),
          $(Text, { style: { fontSize: 24 }}, `${name} ${surname}`)),
        $(Flex, { direction: 'column', alignItems: 'flex-start' }, aliases.map(AliasIteratee))))),
    $(Flex, { gap: 4, justifyContent: 'space-between', className: spacing({ mt: 4 }) },
      $(Button, { onClick: () => back({ fallback: '/' }) }, 
        $(Icon, { data: ArrowLeft }), 
        $(FormattedMessage, { id: 'header.button.back' })),
      $(Button, { onClick, disabled: loading },
        $(FormattedMessage, { id: `logout${loading ? '.loading' : ''}` }),
        $(Icon, { data: ArrowRightFromSquare }))))
}

export default Mobile