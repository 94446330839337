import { Sheet } from '@gravity-ui/uikit'
import AddStaff from 'pages/Settings/AddStaff'
import { StaffQuery } from 'queries'
import { createElement as $, FC } from 'react'
import { Route, Routes, useMatch, useNavigate } from 'react-router-dom'

const Edit: FC<StaffQuery> = (data) => {
  const match = useMatch('/staff/:page')
  const navigate = useNavigate()
  return $(Sheet, {
    className: 'settings_add-staff_sheet',
    visible: !!match?.params.page,
    onClose: () => navigate('/staff')
    },
    $(Routes, null,
      $(Route, {
        path: addPath,
        element: $(AddStaff, data)
        })))
}

export const addPath = 'add'

export default Edit