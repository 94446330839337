import { Button, Card, Flex, spacing } from '@gravity-ui/uikit'
import { useFormikContext } from 'formik'
import { createElement as $, FC } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { CulturalEventProps } from '.'
import CulturalEventForm, { Form } from './CulturalEventForm'
import StatusSelect from './StatusSelect'

const CulturalEvent: FC<CulturalEventProps> = () =>
  $(Flex, { width: '500px' }, 
    $(Card, { className: spacing({ p: 4 }), view: 'raised', children: '' },
      $(CulturalEventForm),
      $(Flex, { justifyContent: 'space-between'}, 
        $(SaveButton),
        $(StatusSelect))))

const SaveButton: FC = () => {
  const intl = useIntl()
  const formik = useFormikContext<Form>()
  const navigate = useNavigate()
  const isUnchanged = JSON.stringify(formik.values) === JSON.stringify(formik.initialValues)

  return $(Button, {
    loading: formik.isSubmitting,
    disabled: isUnchanged,
    onClick: () => 
      formik.submitForm()
      .then(() => navigate('/events')),
    view: 'outlined-action',
    },
    intl.formatMessage({ id: 'culturalEvent.form.save' }))
}

export default CulturalEvent